import React, { useState, useEffect } from "react";
import axios from "axios";
import SidebarMenu from "../../Widgets/SideBar Menu/SidebarMenu";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAllAgentApplicantFormDataAPI,
  sendAgentApplicantFormDataAPI,
} from "../../../api";

const AgentApplicantForm = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [accountType, setAccountType] = useState("");

  const [countries, setCountries] = useState([]);

  // Fetch countries data from the API
  const fetchCountries = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://api.aqato.com.au/api/get_country"
      );

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        setCountries(response.data.response.data);
      } else {
        console.log(response.data.error_msg);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    businessName: "",
    isMaraAgent: false,
    maraNo: "",
    phoneCountryCode: "13",
    phoneNumber: "",
    telCountryCode: "13",
    telAreaCode: "",
    telNumber: "",
    email: "",
    unitFlat: "",
    streetLotNumber: "",
    streetName: "",
    suburbCity: "",
    stateProvince: "",
    postcode: "",
    isPostalAddressSame: false,
    postalUnitFlat: "",
    postalStreetLotNumber: "",
    postalStreetName: "",
    postalSuburbCity: "",
    postalStateProvince: "",
    postalPostcode: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const IsAppplicantTab = accountType !== "Agent";

  const getDataEmploymentFunction = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", id);
      const response = await getAllAgentApplicantFormDataAPI(formData);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const allResData = response?.data?.response?.data;

        setAccountType(allResData?.account_type);

        // Updated isChecked function
        const isChecked = (value) => {
          if (value === "Yes" || value === "yes" || value === "1") {
            return true;
          }
          return false;
        };

        // Update setFormData
        if (allResData) {
          setFormData({
            firstName: allResData.name || "",
            middleName: allResData.middle_name || "",
            lastName: allResData.last_name || "",
            businessName: allResData.business_name || "",
            isMaraAgent: isChecked(allResData.are_u_mara_agent) || false,
            maraNo: allResData.mara_no || "",
            phoneCountryCode: allResData.mobile_code || "13",
            phoneNumber: allResData.mobile_no || "",
            telCountryCode: allResData.tel_code || "13",
            telAreaCode: allResData.tel_area_code || "",
            telNumber: allResData.tel_no || "",
            email: allResData.email || "",
            unitFlat: allResData.unit_flat || "",
            streetLotNumber: allResData.street_lot || "",
            streetName: allResData.street_name || "",
            suburbCity: allResData.suburb_city || "",
            stateProvince: allResData.state_province || "",
            postcode: allResData.postcode || "",
            isPostalAddressSame:
              isChecked(allResData.postal_ad_same_physical_ad_check) || false,
            postalUnitFlat: allResData.postal_unit_flat || "",
            postalStreetLotNumber: allResData.postal_street_lot || "",
            postalStreetName: allResData.postal_street_name || "",
            postalSuburbCity: allResData.postal_suburb_city || "",
            postalStateProvince: allResData.postal_state_province || "",
            postalPostcode: allResData.postal_postcode || "",
          });
        }
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataEmploymentFunction();
  }, [id]);

  // useEffect to synchronize postal address with physical address
  useEffect(() => {
    if (formData.isPostalAddressSame) {
      setFormData((prevData) => ({
        ...prevData,
        postalUnitFlat: prevData.unitFlat,
        postalStreetLotNumber: prevData.streetLotNumber,
        postalStreetName: prevData.streetName,
        postalSuburbCity: prevData.suburbCity,
        postalStateProvince: prevData.stateProvince,
        postalPostcode: prevData.postcode,
      }));
    }
  }, [formData.isPostalAddressSame]);

  const handleBack = () => {
    navigate("/admin/applicant_agent");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const formDataForAPI = new FormData();

      // Append fields to FormData
      formDataForAPI.append("id", id);
      formDataForAPI.append("email", formData.email || "");
      formDataForAPI.append("name", formData.firstName || "");
      formDataForAPI.append("middle_name", formData.middleName || "");
      formDataForAPI.append("last_name", formData.lastName || "");
      formDataForAPI.append("contract_details", formData.contractDetails || "");
      formDataForAPI.append("mobile_code", formData.phoneCountryCode || "");
      formDataForAPI.append("mobile_no", formData.phoneNumber || "");
      formDataForAPI.append("tel_code", formData.telCountryCode || "");
      formDataForAPI.append("tel_area_code", formData.telAreaCode || "");
      formDataForAPI.append("tel_no", formData.telNumber || "");
      formDataForAPI.append("address", formData.unitFlat || "");
      formDataForAPI.append("unit_flat", formData.unitFlat || "");
      formDataForAPI.append("street_lot", formData.streetLotNumber || "");
      formDataForAPI.append("street_name", formData.streetName || "");
      formDataForAPI.append("suburb_city", formData.suburbCity || "");
      formDataForAPI.append("state_province", formData.stateProvince || "");
      formDataForAPI.append("postcode", formData.postcode || "");
      formDataForAPI.append(
        "postal_ad_same_physical_ad_check",
        formData.isPostalAddressSame ? "1" : ""
      );
      formDataForAPI.append("postal_address", formData.postalUnitFlat || "");
      formDataForAPI.append("postal_unit_flat", formData.postalUnitFlat || "");
      formDataForAPI.append(
        "postal_street_lot",
        formData.postalStreetLotNumber || ""
      );
      formDataForAPI.append(
        "postal_street_name",
        formData.postalStreetName || ""
      );
      formDataForAPI.append(
        "postal_suburb_city",
        formData.postalSuburbCity || ""
      );
      formDataForAPI.append(
        "postal_state_province",
        formData.postalStateProvince || ""
      );
      formDataForAPI.append("postal_postcode", formData.postalPostcode || "");

      if (accountType === "Agent") {
        formDataForAPI.append("business_name", formData.businessName || "");
        formDataForAPI.append(
          "are_u_mara_agent",
          formData.isMaraAgent ? "yes" : ""
        );
        formDataForAPI.append("mara_no", formData.maraNo || "");
      }

      formDataForAPI.append("account_type", accountType);

      const response = await sendAgentApplicantFormDataAPI(formDataForAPI);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        handleBack();
        toast.success(
          response?.data?.response?.success_msg || "Updated Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error in updating.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      toast.error("Error in updating.");
    }
  };

  const handleNavigateApplicant = (e, IsAppplicantTab) => {
    e.preventDefault();
    navigate("/admin/applicant_agent", { state: { IsAppplicantTab } });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />
        <div className="main-wrapper ">
          <div className="sub-wrapper-sidebar sidebar-margin">
            <SidebarMenu />
          </div>
          <div className="sub-wrapper-main mb-1">
            <form onSubmit={handleSubmit}>
              <div className="main-heading-title d-flex">
                <div className="col-6">
                  <h3 className="heading-admin-title text-color-green">
                    {accountType} Form
                  </h3>
                </div>

                {accountType === "Agent" && (
                  <>
                    <div class="col-6 namekey_btnback">
                      <Link
                        to="/admin/applicant_agent"
                        class="btn btn_yellow_green float-end"
                      >
                        <i class="bi bi-arrow-left"></i>
                        Back
                      </Link>
                    </div>
                  </>
                )}

                {accountType !== "Agent" && (
                  <>
                    <div className="col-6 namekey_btnback">
                      <button
                        onClick={(e) =>
                          handleNavigateApplicant(e, IsAppplicantTab)
                        }
                        className="btn btn_yellow_green float-end"
                      >
                        <i className="bi bi-arrow-left"></i>
                        Back
                      </button>
                    </div>
                  </>
                )}
              </div>
              <section className="section dashboard mb-5 shadow">
                <div className="row">
                  <div
                    className="card shadow bg-white"
                    style={{ borderRadius: "5px" }}
                  >
                    <div className="card-body" style={{ padding: 0 }}>
                      <div
                        className="form_col row"
                        style={{ padding: 10, borderRadius: "5px" }}
                      >
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control mb-2"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label> Middle Name </label>
                          <input
                            type="text"
                            name="middleName"
                            className="form-control mb-2"
                            value={formData.middleName}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control mb-2"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>

                        {accountType === "Agent" && (
                          <>
                            <div className="col-12 col-md-4">
                              <label>
                                {" "}
                                Business Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="businessName"
                                className="form-control mb-2"
                                value={formData.businessName}
                                required
                                onChange={handleInputChange}
                              />
                            </div>

                            <div
                              className="col-12 col-md-4 label_margin"
                              style={{ marginTop: 30, textAlign: "center" }}
                            >
                              <div className="row justify-content-center">
                                <label
                                  className="col-md-6 col-12"
                                  style={{ width: "fit-content" }}
                                >
                                  {" "}
                                  Are you MARA Agent?{" "}
                                </label>
                                <input
                                  type="checkbox"
                                  className="col-md-1 col-2"
                                  style={{
                                    height: "20px",
                                    position: "relative",
                                    top: "2px",
                                    cursor: "pointer",
                                  }}
                                  name="isMaraAgent"
                                  checked={formData.isMaraAgent}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-4">
                              <label> MARA Number </label>
                              <input
                                type="number"
                                name="maraNo"
                                className="form-control mb-4"
                                value={formData.maraNo}
                                onChange={handleInputChange}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <hr className="mb-0 thick-hr" />

                      <div className="row">
                        <h5
                          className="text-green text-center mt-4 mb-4"
                          style={{ fontSize: 20 }}
                        >
                          Contact Details
                        </h5>
                      </div>
                      <div className="form_col row" style={{ padding: 10 }}>
                        <div className="col-md-6">
                          <label>
                            {" "}
                            Phone No <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div
                              className="col-sm-5"
                              style={{ paddingRight: 0 }}
                            >
                              <span className="label_2"> Country code </span>

                              <select
                                className="form-control mb-2"
                                name="phoneCountryCode"
                                value={formData.phoneCountryCode}
                                onChange={handleInputChange}
                                required
                                style={{
                                  appearance: "auto",
                                  backgroundColor: "white",
                                  border: "1px solid #ced4da",
                                  padding: "0.375rem 0.75rem",
                                  fontSize: "1rem",
                                  borderRadius: "0.25rem",
                                  cursor: "pointer",
                                }}
                              >
                                {countries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name} (+{country.phonecode})
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div
                              className="col-sm-7"
                              style={{ paddingRight: 0 }}
                            >
                              <span className="label_2"> Number </span>
                              <input
                                name="phoneNumber"
                                type="number"
                                className="form-control"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label> Telephone </label>
                          <div className="row">
                            <div
                              className="col-sm-5"
                              style={{ paddingRight: 0 }}
                            >
                              <span className="label_2"> Country code </span>

                              <select
                                name="telCountryCode"
                                className="form-control mb-2"
                                value={formData.telCountryCode}
                                onChange={handleInputChange}
                                style={{
                                  appearance: "auto",
                                  backgroundColor: "white",
                                  border: "1px solid #ced4da",
                                  padding: "0.375rem 0.75rem",
                                  fontSize: "1rem",
                                  borderRadius: "0.25rem",
                                  width: "100%",
                                  boxSizing: "border-box",
                                  cursor: "pointer",
                                }}
                              >
                                {countries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name} (+{country.phonecode})
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div
                              className="col-sm-2"
                              style={{ paddingRight: 0 }}
                            >
                              <span className="label_2"> Area Code </span>
                              <input
                                name="telAreaCode"
                                type="number"
                                className="form-control"
                                value={formData.telAreaCode}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div
                              className="col-sm-5"
                              style={{ paddingRight: 0 }}
                            >
                              <span className="label_2"> Number </span>
                              <input
                                name="telNumber"
                                type="number"
                                className="form-control"
                                value={formData.telNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <label>
                            {" "}
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control mb-2"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <hr className="mb-0 thick-hr" />

                      <div className="row">
                        <h5
                          className="text-green text-center mt-4 mb-4"
                          style={{ fontSize: 20 }}
                        >
                          Address Details
                        </h5>
                      </div>
                      <div className="form_col row" style={{ padding: 10 }}>
                        <div className="col-12 col-md-4">
                          <label> Unit Flat </label>
                          <input
                            type="number"
                            name="unitFlat"
                            className="form-control mb-2"
                            value={formData.unitFlat}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            Street Lot Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="streetLotNumber"
                            className="form-control mb-2"
                            value={formData.streetLotNumber}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            Street Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="streetName"
                            className="form-control mb-2"
                            value={formData.streetName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            Suburb City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="suburbCity"
                            className="form-control mb-2"
                            value={formData.suburbCity}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            State/Province{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="stateProvince"
                            className="form-control mb-2"
                            value={formData.stateProvince}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label>
                            {" "}
                            Postcode <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="postcode"
                            className="form-control mb-4"
                            value={formData.postcode}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div
                        className="form_col row"
                        style={{ paddingBottom: 10 }}
                      >
                        <div
                          className="col-12 col-md-6"
                          style={{ textAlign: "center" }}
                        >
                          <div className="row">
                            <label
                              className="col-md-4 col-12 m-0 ps-4"
                              style={{ width: "fit-content" }}
                            >
                              If Postal Address Is Same ?
                            </label>
                            <input
                              type="checkbox"
                              className="col-md-1 col-2"
                              name="isPostalAddressSame"
                              checked={formData.isPostalAddressSame}
                              onChange={handleInputChange}
                              style={{
                                height: "20px",
                                position: "relative",
                                top: "2px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {!formData.isPostalAddressSame && (
                        <>
                          <hr className="mt-1 thick-hr" />

                          <div className="row" id="postal_row_name">
                            <h5
                              className="text-green text-center mt-4 mb-4"
                              style={{ fontSize: 20 }}
                            >
                              Postal Address Details
                            </h5>
                          </div>
                          <div
                            className="form_col row"
                            id="postal_row_fields"
                            style={{ padding: 10 }}
                          >
                            <div className="col-12 col-md-4">
                              <label>Postal Unit Flat</label>
                              <input
                                type="number"
                                name="postalUnitFlat"
                                className="form-control mb-2"
                                value={formData.postalUnitFlat}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label>Postal Street Lot Number</label>
                              <input
                                type="number"
                                name="postalStreetLotNumber"
                                className="form-control mb-2"
                                value={formData.postalStreetLotNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label>Postal Street Name</label>
                              <input
                                type="text"
                                name="postalStreetName"
                                className="form-control mb-2"
                                value={formData.postalStreetName}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label>Postal Suburb City</label>
                              <input
                                type="text"
                                name="postalSuburbCity"
                                className="form-control mb-2"
                                value={formData.postalSuburbCity}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label>Postal State/Province</label>
                              <input
                                type="text"
                                name="postalStateProvince"
                                className="form-control mb-2"
                                value={formData.postalStateProvince}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label>Postal Postcode</label>
                              <input
                                type="number"
                                name="postalPostcode"
                                className="form-control mb-2"
                                value={formData.postalPostcode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <hr className="mt-1 thick-hr" />

                      <div
                        className="text-center three_buttons d-flex justify-content-center"
                        style={{
                          padding: 30,
                          position: "relative",
                          top: "2px",
                          gap: "10px", // Adds a 10px gap between the buttons
                        }}
                      >
                        <a
                          onClick={handleBack}
                          id="cancel"
                          className="btn btn_yellow_green btn_three"
                        >
                          Back
                        </a>
                        <button
                          type="submit"
                          className="btn btn_green_yellow btn_three"
                          id="action_validation_btn"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                        <a
                          onClick={handleBack}
                          id="cancel"
                          className="btn btn_yellow_green btn_three"
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentApplicantForm;
