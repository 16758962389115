import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import {
  downloadExcelOffShoreAPI,
  downloadPDFOffShoreAPI,
  getOffShoreFeesDataAPI,
} from "../../../api";
import { useNavigate } from "react-router-dom";

import { FaCaretDown } from "react-icons/fa";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OffshoresFees = () => {
  const [loading, setLoading] = useState(false);
  const [ExtraData, setExtraData] = useState();
  const navigate = useNavigate();

  const [dataPerPageButton, setDataPerPageButton] = useState("10");

  const today = new Date().toISOString().split("T")[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [agentlist, setAgentList] = useState([]);
  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [agent_id, setAgent_id] = useState(null);

  const [originalData, setOriginalData] = useState([]);

  const getReportingDataFunction = async (data) => {
    try {
      setLoading(true);
      const response = await getOffShoreFeesDataAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOriginalData(response?.data?.response?.data?.page_data);
        setAgentList(response?.data?.response?.data?.agent_listing);
        setExtraData(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching reporting data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching reporting data:", error);
    }
  };

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("page", 1);
    formdata.append("itemsPerPage", 10);
    formdata.append("from_date", "");
    formdata.append("to_date", "");
    formdata.append("interview_location", "");

    formdata.append("stage", "");
    formdata.append("status", "");

    getReportingDataFunction(formdata);
  }, []);

  const handleDataPerPageChange = (e) => {
    const newDataPerPage = e.target.value;
    setDataPerPageButton(newDataPerPage);

    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", newDataPerPage);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("interview_location", agent_id);

    formdata.append("stage", "");
    formdata.append("status", "");

    getReportingDataFunction(formdata);
  };

  const downloadPDFReportingFunction = async (data) => {
    try {
      setLoading(true);
      const response = await downloadPDFOffShoreAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        if (response?.data?.response?.data?.url) {
          window.open(response?.data?.response?.data?.url, "_blank");
          toast.success(
            response?.data?.response?.success_msg || "Download Opened File."
          );
        }
      } else {
        setLoading(false);
        console.error(
          "Error while downloading PDF",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in downloading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage wise status data:", error);
    }
  };
  const downloadExcelReportingFunction = async (data) => {
    try {
      setLoading(true);
      const response = await downloadExcelOffShoreAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        if (response?.data?.response?.data?.url) {
          window.open(response?.data?.response?.data?.url, "_blank");
          toast.success(
            response?.data?.response?.success_msg || "Download Opened File."
          );
        }
      } else {
        setLoading(false);
        console.error(
          "Error while downloading PDF",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in downloading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage wise status data:", error);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;

    const formdata = new FormData();
    formdata.append("page", currentPreviousPageNo);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("interview_location", agent_id);

    formdata.append("stage", "");
    formdata.append("status", "");

    getReportingDataFunction(formdata);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    const currentNextPageNo = currentPage + 1;

    const formdata = new FormData();
    formdata.append("page", currentNextPageNo);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("interview_location", agent_id);

    formdata.append("stage", "");
    formdata.append("status", "");

    getReportingDataFunction(formdata);
  };

  const viewPDF = () => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("interview_location", agent_id);

    formdata.append("stage", "");
    formdata.append("status", "");

    downloadPDFReportingFunction(formdata);
  };
  const viewSpreadSheet = () => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("interview_location", agent_id);

    formdata.append("stage", "");
    formdata.append("status", "");

    downloadExcelReportingFunction(formdata);
  };

  const onSearch = () => {
    const onSearchFormData = new FormData();
    onSearchFormData.append("page", currentPage);
    onSearchFormData.append("itemsPerPage", dataPerPageButton);
    onSearchFormData.append("from_date", from_date);
    onSearchFormData.append("to_date", to_date);
    onSearchFormData.append("interview_location", agent_id);

    onSearchFormData.append("stage", "");
    onSearchFormData.append("status", "");

    getReportingDataFunction(onSearchFormData);
  };
  const onRefresh = () => {
    setDataPerPageButton("10");
    setCurrentPage(1);
    setAgent_id("");
    setFrom_date("");
    setTo_date("");

    const formdata = new FormData();
    formdata.append("page", 1);
    formdata.append("itemsPerPage", 10);
    formdata.append("from_date", "");
    formdata.append("to_date", "");
    formdata.append("interview_location", "");

    formdata.append("stage", "");
    formdata.append("status", "");

    getReportingDataFunction(formdata);
  };
  const onFromDateChange = (e) => {
    setFrom_date(e.target.value);
  };
  const onToDateChange = (e) => {
    setTo_date(e.target.value);
  };
  const onAgentIdChange = (e) => {
    setAgent_id(e.target.value);
  };


    // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };


  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    
    // Open the URL in a new tab with pointerID as a query parameter
    window.open(`/admin/application_manager/view_application?pointerID=${encodeURIComponent(id)}`, "_blank");
  };
  

  return (
    <>
      {loading && <Loader />}

      <div className="card-body table-responsive">
        <div className="row align-items-end sdrop">
          <Col lg="" className="datatable_width">
     <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
          </Col>
          <div className="col-md-2 col-12">
            <label>
              <b>From Date</b>
            </label>
            <input
              type="date"
              name="from_date"
              value={from_date}
              id="from_date"
              onChange={onFromDateChange}
              className="form-control"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-md-2 col-12">
            <label>
              <b>To Date</b>
            </label>
            <input
              type="date"
              name="to_date"
              value={to_date}
              id="to_date"
              max={today}
              onChange={onToDateChange}
              className="form-control"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="col-md-3 col-12">
            <label>
              <b>Interview Location</b>
            </label>
            <select
              className="form-select"
              id="agent_id"
              value={agent_id}
              onChange={onAgentIdChange}
              data-tooltip-id="my-tooltip"
              style={{ cursor: "pointer" }}
            >
              <option value="">Choose Interview Location</option>
              {agentlist.map((item, index) => (
                <option
                  key={index}
                  value={item?.agent_id}
                  data-tooltip-content={item.hover_text}
                  data-tooltip-place="top"
                >
                  {item?.dropdown_text}
                </option>
              ))}
            </select>
            <Tooltip id="my-tooltip" place="top" />
          </div>
          <div className="col d-flex exports_fun align-items-end">
            <button
              className="btn_green_yellow me-2 btn_report_search"
              onClick={onSearch}
            >
              <i className="bi bi-search"></i>
            </button>
            <button
              className="btn_yellow_green btn_report_search"
              onClick={onRefresh}
              title="Refresh"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
          <div className="d-flex exports_fun col filesd">
            <a
              onClick={viewPDF}
              className="btn float-end pdf_design me-2"
              title="Export to PDF"
              style={{
                transition: "transform 0.1s ease-in-out",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.2)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <i
                className="bi bi-file-pdf-fill"
                style={{
                  transition: "transform 0.1s ease-in-out",
                }}
              ></i>
            </a>

            <a
              onClick={viewSpreadSheet}
              className="btn float-end excel_design"
              title="Export to Excel"
              style={{
                transition: "transform 0.1s ease-in-out",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.2)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <i
                className="bi bi-file-earmark-spreadsheet"
                style={{
                  transition: "transform 0.1s ease-in-out",
                }}
              ></i>
            </a>
          </div>
        </div>
      </div>

      <div id="table_wrapper" className="dataTables_wrapper no-footer ">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive table-wrapper">
                <table
                  class="table table-striped table-hover dataTable no-footer"
                  style={{ cursor: "default" }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "13%" }}
                        className="th_font_size"
                      >
                        Applicant No.
                      </th>
                      <th
                        scope="col"
                        style={{ width: "17%" }}
                        className="th_font_size"
                      >
                        Applicant Name
                      </th>
                      <th
                        scope="col"
                        style={{ width: "10%" }}
                        className="th_font_size"
                      >
                        D.O.B
                      </th>
                      <th
                        scope="col"
                        style={{ width: "15%" }}
                        className="th_font_size"
                      >
                        Occupation
                      </th>
                      <th
                        scope="col"
                        style={{ width: "10%" }}
                        className="th_font_size"
                      >
                        Pathway
                      </th>
                      <th
                        scope="col"
                        style={{ width: "15%" }}
                        className="th_font_size"
                      >
                        Interview Location
                      </th>
                      <th
                        scope="col"
                        style={{ width: "10%" }}
                        className="th_font_size"
                      >
                        Interview Date
                      </th>
                      <th
                        scope="col"
                        style={{ width: "10%" }}
                        className="th_font_size"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {originalData.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even" : "odd"}
                      >
                        <td className="td_font_size">{item?.applicant_no}</td>
                        <td
                          onClick={(event) =>
                            handleNavigateToViewApplication(event, item?.id)
                          }
                          style={{
                            color: "#055837",
                            // color: "red",
                            cursor: "pointer",
                          }}
                          className="hover-underline td_font_size"
                        >
                          {item?.applicant_name}
                        </td>
                        <td className="td_font_size">{item?.d_o_b}</td>
                        <td className="td_font_size">{item?.occupation}</td>
                        <td className="td_font_size">{item?.pathway}</td>

                        <td className="td_font_size">
                          {item?.interview_location}
                        </td>
                        <td className="td_font_size">{item?.interview_date}</td>
                        <td className="td_font_size">{item?.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{ cursor: "default" }}
                  className="d-flex flex-row-reverse total"
                >
                  <div className="num2 td_font_size">{ExtraData?.subtotal}</div>
                  <div className="mr-5 text-end td_font_size">
                    <b>Sub Total:</b>
                  </div>
                </div>
                <hr className="hr-acc" />
                <div
                  style={{ cursor: "default" }}
                  className="d-flex flex-row-reverse total"
                >
                  <div className="num2 td_font_size">
                    {ExtraData?.final_total}
                  </div>
                  <div className="mr-5 text-end td_font_size">
                    <b>Total:</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-3 thick-hr" />

      <div className="pagination-container">
        <div className="dataTables_info">
          Showing {ExtraData?.currentPage} to {ExtraData?.itemsPerPage} of{" "}
          {ExtraData?.totalRows} entries
        </div>

        <div className="pagination-buttons">
          <button
            className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
            onClick={handlePrevious}
            disabled={currentPage === 1}
            style={
              currentPage === 1
                ? {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            Previous
          </button>

          <button className="paginate_button">{currentPage}</button>

          <button
            className={`paginate_button ${
              ExtraData?.next_disabled === true ? "disabled" : ""
            }`}
            onClick={handleNext}
            disabled={ExtraData?.next_disabled === true}
            style={
              ExtraData?.next_disabled === true
                ? {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default OffshoresFees;
