import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Application Manager.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button } from "react-bootstrap";

import { postApplicationManagerRecodsData_API } from "../../api";
import redFlag from "../Widgets/flag-red.png";
import greenFlag from "../Widgets/flag-green.png";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../Widgets/Loader/Loader";
import AdditionalInformationPopup from "./AdditionalInformationPopup";
import AdditionalImportNotePopup from "./AdditionalImportNotePopup";

const ApplicationManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  // const [searchQuery, setSearchQuery] = useState("");
  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [FlagValue, setFlagValue] = useState("");
  // const [CommentFilter, setCommentFilter] = useState(0);

  // Initialize state from localStorage or default values
  const [dataPerPageButton, setDataPerPageButton] = useState(() => {
    const storedValue = localStorage.getItem(
      "applicationManagerAdmin_dataPerPageButton"
    );
    return storedValue ? JSON.parse(storedValue) : "10";
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const storedValue = localStorage.getItem(
      "applicationManagerAdmin_currentPage"
    );
    return storedValue ? JSON.parse(storedValue) : 1;
  });

  const [searchQuery, setSearchQuery] = useState(() => {
    const storedValue = localStorage.getItem(
      "applicationManagerAdmin_searchQuery"
    );
    return storedValue || "";
  });

  const [CommentFilter, setCommentFilter] = useState(() => {
    const storedValue = localStorage.getItem(
      "applicationManagerAdmin_CommentFilter"
    );
    return storedValue ? JSON.parse(storedValue) : 0;
  });

  const [FlagValue, setFlagValue] = useState(() => {
    const storedValue = localStorage.getItem(
      "applicationManagerAdmin_FlagValue"
    );
    return storedValue || "";
  });

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem(
      "applicationManagerAdmin_dataPerPageButton",
      JSON.stringify(dataPerPageButton)
    );
  }, [dataPerPageButton]);

  useEffect(() => {
    localStorage.setItem(
      "applicationManagerAdmin_currentPage",
      JSON.stringify(currentPage)
    );
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("applicationManagerAdmin_searchQuery", searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem(
      "applicationManagerAdmin_CommentFilter",
      JSON.stringify(CommentFilter)
    );
  }, [CommentFilter]);

  useEffect(() => {
    localStorage.setItem("applicationManagerAdmin_FlagValue", FlagValue);
  }, [FlagValue]);

  const debounceTimeout = useRef(null);

  const [currentPageData, setCurrentPageData] = useState([]);

  const [ExtraData, setExtraData] = useState();

  const [isCommentFilterButtonActive, setIsCommentFilterButtonActive] =
    useState(false);
  const [isGreenButtonActive, setIsGreenButtonActive] = useState(false);
  const [isRedButtonActive, setIsRedButtonActive] = useState(false);

  const [AdditionalInformationPopupModal, setAdditionalInformationPopupModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setAdditionalInformationPopupModal(true);
  };

  const closeModal = () => {
    setAdditionalInformationPopupModal(false);
    setSelectedItem(null);
  };

  const [AdditionalNotePopupModal, setAdditionalNotePopupModal] =
    useState(false);
  const [selectedNoteItem, setSelectedNoteItem] = useState(null);

  const openNoteModal = (item) => {
    setSelectedNoteItem(item);
    setAdditionalNotePopupModal(true);
  };

  const closeNoteModal = () => {
    setAdditionalNotePopupModal(false);
    setSelectedNoteItem(null);
  };

  const getApplicationManagerFunction = async (data) => {
    try {
      setLoading(true);

      const response = await postApplicationManagerRecodsData_API(data);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data &&
        response?.data?.response?.data?.page_data
      ) {
        setCurrentPageData(response?.data?.response?.data?.page_data);
        setExtraData(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching application manager records data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching application manager records data:", error);
    }
  };

  // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };


  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    
    // Open the URL in a new tab with pointerID as a query parameter
    window.open(`/admin/application_manager/view_application?pointerID=${encodeURIComponent(id)}`, "_blank");
  };
  

  const toggleCommentFilter = () => {
    setIsCommentFilterButtonActive((prevState) => !prevState);
    // setIsRedButtonActive(false);
    // setIsGreenButtonActive(false);

    setCommentFilter((prevValue) => {
      const newFilterValue = prevValue === 0 ? 1 : 0;

      // Prepare form data
      const formDataApplicationsRecods = new FormData();
      formDataApplicationsRecods.append("search_input", searchQuery);
      formDataApplicationsRecods.append("search_flag", FlagValue);
      formDataApplicationsRecods.append("add_comment_filter", newFilterValue);
      formDataApplicationsRecods.append("itemsPerPage", dataPerPageButton);
      formDataApplicationsRecods.append("page", currentPage);

      getApplicationManagerFunction(formDataApplicationsRecods);

      return newFilterValue;
    });
  };

  const handleRowDataLength = (event) => {
    setDataPerPageButton(event.target.value);

    const rowLength = event.target.value;
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);
    formDataApplicationsRecods.append("search_flag", FlagValue);
    formDataApplicationsRecods.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecods.append("itemsPerPage", rowLength);
    formDataApplicationsRecods.append("page", currentPage);

    getApplicationManagerFunction(formDataApplicationsRecods);
  };

  const handleGreenFlag = () => {
    setIsGreenButtonActive((prevState) => {
      const newState = !prevState;

      const formDataApplicationsRecods = new FormData();
      formDataApplicationsRecods.append("search_input", searchQuery);
      formDataApplicationsRecods.append(
        "search_flag",
        newState ? "upload" : ""
      );
      formDataApplicationsRecods.append("add_comment_filter", CommentFilter);
      formDataApplicationsRecods.append("itemsPerPage", dataPerPageButton);
      formDataApplicationsRecods.append("page", currentPage);

      getApplicationManagerFunction(formDataApplicationsRecods);

      // If green button becomes inactive, reset the red button
      if (!newState) {
        setIsRedButtonActive(false);
      }

      return newState;
    });
  };

  const handleRedFlag = () => {
    setIsRedButtonActive((prevState) => {
      const newState = !prevState;

      const formDataApplicationsRecods = new FormData();
      formDataApplicationsRecods.append("search_input", searchQuery);
      formDataApplicationsRecods.append("search_flag", newState ? "send" : "");
      formDataApplicationsRecods.append("add_comment_filter", CommentFilter);
      formDataApplicationsRecods.append("itemsPerPage", dataPerPageButton);
      formDataApplicationsRecods.append("page", currentPage);

      getApplicationManagerFunction(formDataApplicationsRecods);

      // If red button is active, deactivate green button
      if (newState) {
        setIsGreenButtonActive(false);
      }

      return newState;
    });
  };

  const handleSearchButtonData = () => {
    // Prepare form data
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);
    formDataApplicationsRecods.append("search_flag", FlagValue);
    formDataApplicationsRecods.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecods.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecods.append("page", currentPage);

    getApplicationManagerFunction(formDataApplicationsRecods);
  };

  const handlePrevious = () => {
    // Only decrement the current page if it's greater than 1
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }

    // Determine the previous page number, ensuring it doesn't go below 0
    const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchQuery);
    formDataApplicationsRecords.append("search_flag", FlagValue);
    formDataApplicationsRecords.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecords.append("page", currentPreviousPageNo);

    // Call the function to get the application manager data
    getApplicationManagerFunction(formDataApplicationsRecords);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);

    const currentNextPageNo = currentPage + 1;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchQuery);
    formDataApplicationsRecords.append("search_flag", FlagValue);
    formDataApplicationsRecords.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecords.append("page", currentNextPageNo);

    getApplicationManagerFunction(formDataApplicationsRecords);
  };

  const handlSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    const searchInputByUser = event.target.value;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchInputByUser);
    formDataApplicationsRecords.append("search_flag", FlagValue);
    formDataApplicationsRecords.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecords.append("itemsPerPage", 10);
    formDataApplicationsRecords.append("page", 1);

    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getApplicationManagerFunction(formDataApplicationsRecords);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleClearSearchData = () => {
    setIsRedButtonActive(false);
    setIsGreenButtonActive(false);
    setIsCommentFilterButtonActive(false);

    setSearchQuery("");
    setFlagValue("");
    setCommentFilter("");
    setDataPerPageButton(10);
    setCurrentPage(1);

    // Prepare form data
    const formDataClearApplicationsRecods = new FormData();
    formDataClearApplicationsRecods.append("search_input", "");
    formDataClearApplicationsRecods.append("search_flag", "");
    formDataClearApplicationsRecods.append("add_comment_filter", "");
    formDataClearApplicationsRecods.append("itemsPerPage", 10);
    formDataClearApplicationsRecods.append("page", 1);

    getApplicationManagerFunction(formDataClearApplicationsRecods);
  };

  useEffect(() => {
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);
    formDataApplicationsRecods.append("search_flag", FlagValue);
    formDataApplicationsRecods.append("add_comment_filter", CommentFilter);
    formDataApplicationsRecods.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecods.append("page", currentPage);

    getApplicationManagerFunction(formDataApplicationsRecods);
  }, []);

  return (
    <>
      <div className="main_desktop">
        {/* <Row>
          <Col>
            <NavigationButtons handleLogout={handleLogout} />
          </Col>
        </Row> */}

        <hr className="mb-2 thick-hr" />

        {/* {loading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )} */}

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Application Manager
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <Card.Body>
                  <div className="action_buttons_top bg-white">
                    <Row className="my-2 mx-2 align-items-center">
                      <Col lg="2" className="my-auto">
                    <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
    value={dataPerPageButton}
    onChange={handleRowDataLength}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>

                      </Col>
                      <Col lg="8">
                        <Row className="justify-content-center align-items-center">
                          <Col lg="9">
                            <Form.Control
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handlSearchQuery}
                              className="searchInput"
                            />
                          </Col>

                          <Col
                            lg="2"
                            className="my-auto text-center d-flex justify-content-around"
                          >
                            {/* <Button
                              onClick={handleSearchButtonData}
                              className="btn_green_yellow"
                            >
                              <i className="bi bi-search"></i>
                            </Button> */}

                            <Button
                              className="btn_yellow_green"
                              title="Refresh"
                              onClick={handleClearSearchData}
                              style={{
                                outline: "none",
                                boxShadow: "none",
                                border: "none",
                              }}
                            >
                              <i className="bi bi-arrow-clockwise"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        lg="2"
                        className="my-auto text-end d-flex justify-content-end"
                      >
                        <Button
                          className={`btn filter_flag_buttons message_btn_color_2 fz-20 ${
                            isCommentFilterButtonActive
                              ? "comment_filter_active"
                              : ""
                          }`}
                          onClick={toggleCommentFilter}
                          style={{
                            outline: "none",
                            boxShadow: "none",
                            border: "none",
                          }}
                        >
                          <i className="bi bi-exclamation-lg"></i>
                        </Button>

                        <Button
                          className={`btn filter_flag_buttons ${
                            isGreenButtonActive ? "green_button_active" : ""
                          }`}
                          style={{
                            padding: "6px 10px",
                            outline: "none",
                            boxShadow: "none",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={handleGreenFlag}
                        >
                          <img
                            src={greenFlag}
                            className={`filter_flag_image ${
                              isGreenButtonActive ? "green_button_active" : ""
                            }`}
                            alt="Green Flag"
                          />
                        </Button>

                        <Button
                          className={`btn filter_flag_buttons ${
                            isRedButtonActive ? "red_button_active" : ""
                          }`}
                          style={{
                            padding: "6px 10px",
                            outline: "none",
                            boxShadow: "none",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={handleRedFlag}
                        >
                          <img
                            src={redFlag}
                            className={`filter_flag_image ${
                              isRedButtonActive ? "red_button_active" : ""
                            }`}
                            alt="Red Flag"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  {currentPageData?.length === 0 && !loading && (
                    <div className="no-data-message">
                      <p className="oops-message">Oops! No data to display.</p>
                    </div>
                  )}

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style={{ width: "2%" }}></th>{" "}
                                  {/* Empty space column */}
                                  <th scope="col" style={{ width: "10%" }}>
                                    PRN
                                  </th>
                                  <th scope="col" style={{ width: "13%" }}>
                                    Applicant No.
                                  </th>
                                  <th scope="col" style={{ width: "20%" }}>
                                    Applicant Name
                                  </th>
                                  <th scope="col" style={{ width: "20%" }}>
                                    Occupation
                                  </th>
                                  <th scope="col" style={{ width: "7%" }}>
                                    P1/P2
                                  </th>
                                  <th scope="col" style={{ width: "14%" }}>
                                    Date Submitted
                                  </th>
                                  <th scope="col" style={{ width: "12%" }}>
                                    Current Status
                                  </th>
                                  {accountType !== "head_office" && (
                                    <th scope="col" style={{ width: "2%" }}>
                                      TM
                                    </th>
                                  )}
                                </tr>
                              </thead>

                              <tbody>
                                {currentPageData?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={index % 2 === 0 ? "even" : "odd"}
                                  >
                                    <td>
                                      {item?.flag_data.length !== 0 &&
                                      item?.flag_data?.flag_img ? (
                                        <img
                                          src={item?.flag_data?.flag_img}
                                          className="filter_flag_image"
                                          alt="Flag"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => openModal(item)}
                                        />
                                      ) : null}
                                    </td>

                                    <td>{item?.prn}</td>

                                    <td>
                                      <span
                                        style={{
                                          color:
                                            item?.applicant_no?.text_color ===
                                            "red"
                                              ? "red"
                                              : "",
                                          cursor:
                                            item?.applicant_no?.text_color ===
                                            "red"
                                              ? "pointer"
                                              : "default",
                                          textDecoration: "none",
                                          position: "relative",
                                          display: "inline-block",
                                          transition:
                                            "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                        }}
                                        onClick={() => {
                                          if (
                                            item?.applicant_no?.text_color ===
                                            "red"
                                          ) {
                                            openNoteModal(item);
                                          } else {
                                            console.log("onClick");
                                          }
                                        }}
                                        onMouseEnter={(e) => {
                                          if (
                                            item?.applicant_no?.text_color ===
                                            "red"
                                          ) {
                                            e.currentTarget.style.textDecoration =
                                              "underline";
                                            e.currentTarget.style.textDecorationColor =
                                              "red";
                                            e.currentTarget.style.transform =
                                              "scale(1.1)";
                                          }
                                        }}
                                        onMouseLeave={(e) => {
                                          if (
                                            item?.applicant_no?.text_color ===
                                            "red"
                                          ) {
                                            e.currentTarget.style.textDecoration =
                                              "none";
                                            e.currentTarget.style.transform =
                                              "scale(1)";
                                          }
                                        }}
                                      >
                                        {item?.applicant_no?.applicant_no}
                                      </span>
                                    </td>

                                    <td
                                      onClick={(event) =>
                                        handleNavigateToViewApplication(
                                          event,
                                          item?.id
                                        )
                                      }
                                      style={{
                                        color: "#055837",
                                        cursor: "pointer",
                                        fontSize: "17px",
                                      }}
                                      className="hover-underline"
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          transition:
                                            "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.transform =
                                            "scale(1.1)";
                                          e.target.style.textDecoration =
                                            "underline";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.transform = "scale(1)";
                                          e.target.style.textDecoration =
                                            "none";
                                        }}
                                      >
                                        {item?.applicant_name}
                                      </span>
                                    </td>

                                    <td>{item?.occupation}</td>
                                    <td>{item?.pathway}</td>
                                    <td>{item?.submitted_date}</td>
                                    <td>{item?.current_status}</td>

                                    {accountType !== "head_office" && (
                                      <td
                                        style={{ cursor: "pointer" }}
                                        title={item?.team_member?.full_name}
                                      >
                                        <strong>
                                          {" "}
                                          {item?.team_member?.inital}
                                        </strong>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-3 thick-hr" />

                    <div className="pagination-container">
                      <div className="dataTables_info">
                        Showing {ExtraData?.currentPage} to{" "}
                        {ExtraData?.itemsPerPage} of {ExtraData?.totalRows}{" "}
                        entries
                      </div>

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          style={
                            currentPage === 1
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Previous
                        </button>

                        <button className="paginate_button">
                          {currentPage}
                        </button>

                        <button
                          className={`paginate_button ${
                            ExtraData?.next_disabled === true ? "disabled" : ""
                          }`}
                          onClick={handleNext}
                          disabled={ExtraData?.next_disabled === true}
                          style={
                            ExtraData?.next_disabled === true
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <AdditionalInformationPopup
        show={AdditionalInformationPopupModal}
        onClose={closeModal}
        selectedItem={selectedItem}
        ApplicationManagerFetch={handleClearSearchData}
      />

      <AdditionalImportNotePopup
        show={AdditionalNotePopupModal}
        onClose={closeNoteModal}
        selectedItem={selectedNoteItem}
        ApplicationManagerFetch={handleClearSearchData}
      />
    </>
  );
};

export default ApplicationManager;
