import React, { useState, useEffect } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { getDataPracticalAPI } from "../../api"; // Ensure this path is correct
import InterviewPracticalChange from "./InterviewPracticalchange";
import Loader from "../Widgets/Loader/Loader";

const Practical = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const [originalData, setOriginalData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const getPracticalFunction = async () => {
    try {
      setLoading(true);
      const response = await getDataPracticalAPI();
      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        const PracticalData = response.data.response.data;
        setOriginalData(PracticalData);
        setCurrentPageData(PracticalData.slice(0, parseInt(dataPerPageButton)));
      } else {
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getPracticalFunction();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main_desktop">
        <Card.Body>
          <Row className="my-2 mx-2 align-items-center">
            <Col lg="2" className="colIn dataTable_practical">
       <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
            </Col>
            <Col lg="10" className="search_practical">
              <Row className="justify-content-end align-items-center">
                <Col lg="9" className="sear">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="searchInput"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div id="table_wrapper" className="dataTables_wrapper no-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive table-wrapper">
                    <table className="table table-striped table-hover dataTable no-footer">
                      <thead>
                        <tr style={{ cursor: "default" }}>
                          <th scope="col" className="col-1 th_font_size">
                            Sr.No.
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            City
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            Country
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            Venue
                          </th>
                          <th scope="col" className="col-4 th_font_size">
                            Address
                          </th>
                          <th scope="col" className="col-1 th_font_size">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageData
                          .slice(0, parseInt(dataPerPageButton))
                          .map((item, index) => (
                            <tr
                              key={index}
                              className={index % 2 === 0 ? "even" : "odd"}
                            >
                              <td className="td_font_size">{index + 1}.</td>
                              <td className="td_font_size">
                                {item?.city_name}
                              </td>
                              <td className="td_font_size">{item?.country}</td>
                              <td className="td_font_size">{item?.venue}</td>
                              <td className="td_font_size">
                                {item?.office_address}
                              </td>
                              <td className="td_font_size">
                                <div className="btn-container">
                                  <button
                                    className="btn btn-sm edit"
                                    style={{
                                      backgroundColor: "#055837",
                                      color: "#ffcc01",
                                      marginRight: "8px",
                                      transition:
                                        "background-color 0.2s, color 0.2s",
                                    }}
                                    onClick={() => handleShow(item?.id)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-3 thick-hr" />
            <div className="pagination-container">
              <div className="dataTables_info">
                Showing{" "}
                {currentPage === 1
                  ? 1
                  : (currentPage - 1) * dataPerPageButton + 1}{" "}
                to{" "}
                {Math.min(currentPage * dataPerPageButton, originalData.length)}{" "}
                of {originalData.length} entries
              </div>
              <div className="pagination-buttons">
                <button
                  className={`paginate_button ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                  style={
                    currentPage === 1
                      ? { pointerEvents: "none", cursor: "not-allowed" }
                      : {}
                  }
                >
                  Previous
                </button>
                <button className="paginate_button">{currentPage}</button>
                <button
                  className={`paginate_button ${
                    currentPage ===
                    Math.ceil(originalData.length / dataPerPageButton)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={handleNext}
                  disabled={
                    currentPage ===
                    Math.ceil(originalData.length / dataPerPageButton)
                  }
                  style={
                    currentPage ===
                    Math.ceil(originalData.length / dataPerPageButton)
                      ? { pointerEvents: "none", cursor: "not-allowed" }
                      : {}
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Card.Body>
      </div>

      <InterviewPracticalChange
        showModal={showModal}
        handleClose={handleClose}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        refreshData={getPracticalFunction}
      />
    </>
  );
};

export default Practical;
