import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import { getDataSAQAPI, getDeleteOFflineFileData } from "../../../api";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../../Widgets/Loader/Loader";
import VerificationModal from "../VerificationModal/VerificationModal";
import EditThirdPartyreport from "./EditThirdPartyreport";

const ThirdPartyReport = () => {
  const [loading, setLoading] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClose = () => setShowModal(false);

  const handleShow = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  const handleShowDeleteSAQ = (item) => {
    setShowModalDeleteSAQ(true);
    setSelectedItem(item);
  };

  const [showModalDeleteSAQ, setShowModalDeleteSAQ] = useState(false);
  const handleCloseDeleteSAQ = () => setShowModalDeleteSAQ(false);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getDataSAQFunction = async () => {
    try {
      setLoading(true);
      const useFor = "third_party";

      const response = await getDataSAQAPI(useFor);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const SAQData = response.data.response.data;
        setOriginalData(SAQData);
        setCurrentPageData(SAQData.slice(0, parseInt(dataPerPageButton)));
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getDataSAQFunction();
  }, []);

  const handleConfirmationDeleteYes = async () => {
    const id = selectedItem?.id;
    try {
      setLoading(true);

      const response = await getDeleteOFflineFileData(id);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        getDataSAQFunction();
        handleCloseDeleteSAQ();
        toast.success("Application Deleted successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to delete Incomplete Application"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to delete Incomplete Application");
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));

      if (filteredData.length === 0) {
        setShowNoDataMessage(true);
      } else {
        setShowNoDataMessage(false);
      }
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  return (
    <>
      {loading && <Loader />}

      <div className="bg-white  disdesk ">
        <Row className="my-2 mx-2 align-items-center">
          <Col lg="2" className="my-auto">
        <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
          </Col>
          <Col lg="7">
            <Row className="justify-content-center align-items-center">
              <Col lg="9">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="searchInput"
                />
              </Col>
            </Row>
          </Col>
          <Col lg="3" className="my-auto text-end  justify-content-end">
            <button
              className="btn btn_green_yellow text-sm-center offline_btn"
              onClick={() => {
                handleShow() && setSelectedItem(null);
              }}
            >
              + ADD THIRD PARTY REPORT
            </button>
          </Col>
        </Row>
      </div>

      <div id="table_wrapper" className="dataTables_wrapper no-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive table-wrapper">
                <table
                  class="table table-striped table-hover dataTable no-footer"
                  style={{ cursor: "default" }}
                >
                  <thead>
                    <tr>
                      <th scope="col" className="col-1 th_font_size">
                        Sr.No.
                      </th>
                      <th scope="col" className="col-4 th_font_size">
                        Occupation
                      </th>

                      <th scope="col" className="col-6 th_font_size">
                        File
                      </th>

                      <th scope="col" className="col-1 th_font_size">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentPageData
                      .slice(0, parseInt(dataPerPageButton))
                      .map((item, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "even" : "odd"}
                        >
                          <td className="td_font_size">{index + 1}.</td>
                          <td className="td_font_size">
                            {item?.occupation_name}
                          </td>
                          <td className="td_font_size">
                            <a
                              href={item?.path_text}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "none",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.textDecoration = "underline")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.textDecoration = "none")
                              }
                            >
                              {item?.file_name}
                            </a>
                          </td>

                          <td className="td_font_size">
                            <div className="btn-container">
                              <button
                                className="btn btn-sm edit"
                                style={{
                                  backgroundColor: "#055837",
                                  color: "#ffcc01",
                                  marginRight: "8px",
                                  transition:
                                    "background-color 0.2s, color 0.2s",
                                }}
                                onClick={() => {
                                  handleShow(item);
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-danger text-white"
                                style={{ marginLeft: "8px" }}
                                onClick={() => {
                                  handleShowDeleteSAQ(item);
                                }}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <hr className="mb-3 thick-hr" />

        <div className="pagination-container">
          <div className="dataTables_info">
            Showing{" "}
            {currentPage === 1 ? 1 : (currentPage - 1) * dataPerPageButton + 1}{" "}
            to {Math.min(currentPage * dataPerPageButton, originalData.length)}{" "}
            of {originalData.length} entries
          </div>

          <div className="pagination-buttons">
            <button
              className={`paginate_button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={
                currentPage === 1
                  ? {
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              Previous
            </button>

            <button className="paginate_button">{currentPage}</button>

            <button
              className={`paginate_button ${
                currentPage ===
                Math.ceil(originalData.length / dataPerPageButton)
                  ? "disabled"
                  : ""
              }`}
              onClick={handleNext}
              disabled={
                currentPage ===
                Math.ceil(originalData.length / dataPerPageButton)
              }
              style={
                currentPage ===
                Math.ceil(originalData.length / dataPerPageButton)
                  ? {
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <EditThirdPartyreport
        showModal={showModal}
        handleClose={handleClose}
        selectedItem={selectedItem}
        getDataSAQFunction={getDataSAQFunction}
      />

      <VerificationModal
        show={showModalDeleteSAQ}
        onClose={handleCloseDeleteSAQ}
        onYes={handleConfirmationDeleteYes}
        Title="Confirm !"
        Message={
          <>
            Are you sure you want to delete{" "}
            <span style={{ fontWeight: "bold", color: "blue" }}>
              {selectedItem?.file_name}{" "}
            </span>{" "}
            File for ,{" "}
            <span style={{ fontWeight: "bold", color: "red" }}>
              {selectedItem?.occupation_name}{" "}
            </span>
            Occupation ?
          </>
        }
      />
    </>
  );
};

export default ThirdPartyReport;
