import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import NewBookingModal from "./NewBookingModal/NewBookingModal";
import { getNonAquatoInterviewBookingsAPI } from "../../../api";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../../Widgets/Loader/Loader";
import ZoomDetails from "./ZoomDetails/ZoomDetails";
import CancelInterview from "./CancelInterview/CancelInterview";


const NonAqato = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };
  const [selectedItem, setSelectedItem] = useState(null);

  const [showZoomModal, setZoomShow] = useState(false);
  const handleZoomClose = () => setZoomShow(false);
  const handleZoomShow = (item) => {
    setZoomShow(true);
    setSelectedItem(item);
  
  };

  const [showCancelInterviewModal, setCancelInterviewShow] = useState(false);
  const handleCancelInterviewClose = () => setCancelInterviewShow(false);
  const handleCancelInterviewShow = (item) => {
    setCancelInterviewShow(true);
    setSelectedItem(item);
  };

  const [showNewInterviewModal, setNewInterviewModalShow] = useState(false);
  const handleNewInterviewModalClose = () => setNewInterviewModalShow(false);
  const handleNewInterviewModalShow = () => setNewInterviewModalShow(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getNonAquatoDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getNonAquatoInterviewBookingsAPI();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOriginalData(response?.data?.response?.data?.not_aqato_s3_model);
        setCurrentPageData(
          response?.data?.response?.data?.not_aqato_s3_model.slice(
            0,
            parseInt(dataPerPageButton)
          )
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  useEffect(() => {
    getNonAquatoDataFunction();
  }, []);

  
    // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };


  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    
    // Open the URL in a new tab with pointerID as a query parameter
    window.open(`/admin/application_manager/view_application?pointerID=${encodeURIComponent(id)}`, "_blank");
  };
  

  return (
    <>
      {loading && <Loader />}

      <div className="main-body-area mb-5">
        <Card className="shadow">
          <Card.Body>
            <div className="action_buttons_top bg-white action_btn_desk">
              <Row className="my-2 mx-2 align-items-center justify-content-between">
                <Col xs="auto" className="d-flex align-items-center pe-0">
            <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>

                </Col>

                {/* Search input */}
                <Col xs={5} className="d-flex align-items-center pe-0">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="searchInput"
                  />
                </Col>

                {/* New Booking Button */}
                <Col xs={3} className="d-flex align-items-center text-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#edit_form2"
                    className="btn btn-sm btn_green_yellow"
                    onClick={handleNewInterviewModalShow}
                    style={{ padding: "8px", width: "100%" }}
                  >
                    <i className="bi bi-plus"></i> New AQATO Booking
                  </button>
                </Col>
              </Row>
            </div>

            <div id="table_wrapper" className="dataTables_wrapper no-footer">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive table-wrapper">
                      <table
                        class="table table-striped table-hover dataTable no-footer"
                        style={{ cursor: "default" }}
                      >
                        <thead>
                          <tr>
                            <th className="col-1  th_font_size">Sr. No.</th>
                            <th className="col-2  th_font_size">
                              Application No.
                            </th>
                            <th className="col-2  th_font_size">
                              Applicant Name
                            </th>
                            <th className="col-2  th_font_size">Occupation</th>
                            <th className="col-1  th_font_size">Location</th>
                            <th className="col-1  th_font_size">Date</th>
                            <th className="col-1  th_font_size">Time(QLD)</th>

                            <th className="col-2  th_font_size">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData
                            .slice(0, parseInt(dataPerPageButton))
                            .map((item, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td className="td_font_size">{index + 1}</td>
                                <td className="td_font_size">
                                  {item?.applicant_application_no}
                                </td>


                                <td className="td_font_size">
                                {item?.applicant_name}
                                </td>


                                {/* <td

                                  onClick={(event) =>
                                    handleNavigateToViewApplication(
                                      event,
                                      item?.id
                                    )
                                  }

                                  style={{
                                    color: "#055837",
                                    cursor: "pointer",
                                    fontSize: "17px",
                                  }}
                                  className="hover-underline"
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      transition:
                                        "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                    }}
                                    onMouseEnter={(e) => {
                                      e.target.style.transform = "scale(1.1)";
                                      e.target.style.textDecoration =
                                        "underline";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.transform = "scale(1)";
                                      e.target.style.textDecoration = "none";
                                    }}
                                  
                                  >
                                    {item?.applicant_name}
                                  </span>
                                </td> */}

                                <td className="td_font_size">
                                  {item?.applicant_occupation}
                                </td>

                                <td className="td_font_size">
                                  {item?.applicant_location?.name}
                                </td>
                                <td className="td_font_size">
                                  {item?.applicant_interview_date}
                                </td>
                                <td className="td_font_size">
                                  {item?.applicant_interview_time}
                                </td>
                                <td className="btn-flex">
                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_form2"
                                    className="btn btn-sm btn_yellow_green ms-1"
                                    disabled={item?.zoom_btn_disabled}
                                    onClick={() => handleZoomShow(item)}
                                  >
                                    <i className="bi bi-forward"></i>
                                  </button>

                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_req2"
                                    className="btn btn-sm btn-danger ms-1"
                                    onClick={() =>
                                      handleCancelInterviewShow(item)
                                    }
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mb-3 thick-hr" />

              <div className="pagination-container">
                <div className="dataTables_info">
                  Showing{" "}
                  {currentPage === 1
                    ? 1
                    : (currentPage - 1) * dataPerPageButton + 1}{" "}
                  to{" "}
                  {Math.min(
                    currentPage * dataPerPageButton,
                    originalData.length
                  )}{" "}
                  of {originalData.length} entries
                </div>

                <div className="pagination-buttons">
                  <button
                    className={`paginate_button ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    style={
                      currentPage === 1
                        ? {
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                  >
                    Previous
                  </button>

                  <button className="paginate_button">{currentPage}</button>

                  <button
                    className={`paginate_button ${
                      currentPage ===
                      Math.ceil(originalData.length / dataPerPageButton)
                        ? "disabled"
                        : ""
                    }`}
                    onClick={handleNext}
                    disabled={
                      currentPage ===
                      Math.ceil(originalData.length / dataPerPageButton)
                    }
                    style={
                      currentPage ===
                      Math.ceil(originalData.length / dataPerPageButton)
                        ? {
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <NewBookingModal
        showModal={showNewInterviewModal}
        handleClose={handleNewInterviewModalClose}
        getNonAquatoDataFunction={getNonAquatoDataFunction}
      />

      <CancelInterview
        showModal={showCancelInterviewModal}
        handleClose={handleCancelInterviewClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={getNonAquatoDataFunction}
      />

      <ZoomDetails
        showModal={showZoomModal}
        handleClose={handleZoomClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={getNonAquatoDataFunction}
      />
    </>
  );
};

export default NonAqato;
