import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import "./MailTemplate.css";
import { Link, useNavigate } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import { getMailTemplateDataAPI } from "../../api";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../Widgets/Loader/Loader";

const MailTemplate = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getMailTemplateDataFunction = async () => {
    try {
      setLoading(true);
      const response = await getMailTemplateDataAPI();

      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const mailTemplateData = response?.data?.response?.data;

        setOriginalData(mailTemplateData);
        setCurrentPageData(
          mailTemplateData.slice(0, parseInt(dataPerPageButton))
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getMailTemplateDataFunction();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  const handleNavigateToViewApplication = (event, item) => {
    event.preventDefault();
    navigate(`/admin/mail_template/edit`, {
      state: { pointerID: item },
    });
  };

  return (
    <>
      <div className="main_desktop mb-5">
        <hr className="mb-2 thick-hr" />

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Mail Template
              </h3>
            </div>
            <div className="main-body-area">
              <Card className="shadow">
                <Card.Body>
                  <div className="action_buttons_top bg-white disdeskm">
                    <Row className="my-2 mx-2 align-items-center">
                      <Col lg="2" className="my-auto datalen">
                       <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
                      </Col>
                      <Col
                        lg="2"
                        className="my-auto text-end d-flex justify-content-start"
                      >
                        <Link
                          to="/admin/mail_template/add_mail"
                          className="btn btn_green_yellow "
                        >
                          + Add Mail
                        </Link>
                      </Col>

                      <Col lg="6">
                        <Row className="justify-content-center align-items-center">
                          <Col lg="9">
                            <Form.Control
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearch}
                              className="searchInput"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="my-auto text-end d-flex justify-content-end name-key">
                        <Link
                          to="/admin/mail_template/name_keywords"
                          className="btn btn_green_yellow "
                        >
                          + Name Keywords
                        </Link>
                      </Col>
                    </Row>
                  </div>

             
                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default" ,overflowX: 'auto'}}
                            >
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Sr. No.
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    IDs
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "25%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Name
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "20%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Keyword
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "45%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Subject
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentPageData
                                  .slice(0, parseInt(dataPerPageButton))
                                  .map((item, index) => (
                                    <tr
                                      key={index}
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                    >
                                      <td className="td_font_size">
                                        {index + 1}.
                                      </td>
                                      <td className="td_font_size">
                                        {item?.id}
                                      </td>

                                      <td
                                        onClick={(event) =>
                                          handleNavigateToViewApplication(
                                            event,
                                            item
                                          )
                                        }
                                        style={{
                                          // color: "#055837",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        className="hover-underline"
                                      >
                                        {item?.name}
                                      </td>

                                      <td className="td_font_size">
                                        {item?.name_keyword}
                                      </td>
                                      <td className="td_font_size">
                                        {item?.subject}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-3 thick-hr" />

                    <div className="pagination-container">
                      <div className="dataTables_info">
                        Showing{" "}
                        {currentPage === 1
                          ? 1
                          : (currentPage - 1) * dataPerPageButton + 1}{" "}
                        to{" "}
                        {Math.min(
                          currentPage * dataPerPageButton,
                          originalData.length
                        )}{" "}
                        of {originalData.length} entries
                      </div>

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          style={
                            currentPage === 1
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Previous
                        </button>

                        <button className="paginate_button">
                          {currentPage}
                        </button>

                        <button
                          className={`paginate_button ${
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? "disabled"
                              : ""
                          }`}
                          onClick={handleNext}
                          disabled={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                          }
                          style={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailTemplate;
