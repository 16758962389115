import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Spinner, Col, Row, Table, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  getDataForTransferApplicationPreviewForm,
  postDataFinalSubmissionForTransferApplicationForm,
} from "../../../../api";

import SidebarMenu from "../../../Widgets/SideBar Menu/SidebarMenu";
import Loader from "../../../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const ApplicantTransferPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const { pointerID, selectedOption } = location.state || {};

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [CurrentPageData, setCurrentPageData] = useState([]);

  const getApplicationManagerFunction = async () => {
    try {
      setLoading(true);

      const response = await getDataForTransferApplicationPreviewForm(
        pointerID,
        selectedOption
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setCurrentPageData(response?.data?.response?.data || []);
      } else {
        setLoading(false);
        console.error(
          "Error Fetching Records",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error Fetching Records");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error Fetching Records", error);
    }
  };

  useEffect(() => {
    getApplicationManagerFunction();
  }, []);

  const handleBack = (event) => {
    // event.preventDefault();
    navigate("/admin/application_manager/view_application/transfer_applicant", {
      state: { pointerID: pointerID },
    });
  };

  const handleExit = (event) => {
    // event.preventDefault();
    navigate("/admin/application_manager/view_application", {
      state: { pointerID: pointerID },
    });
  };

  const handleRequestAdditionalInfoModalOpen = () => {
    setShowVerificationModal(true);
  };

  const handleVerificationModalClose = () => {
    setShowVerificationModal(false);
  };

  const handleVerificationModalYes = () => {
    handleNext();
  };

  const handleNext = async () => {
    // event.preventDefault();

    // navigate("/admin/application_manager/view_application", {
    //   state: { pointerID: pointerID },
    // });

    try {
      setLoading(true);

      const response = await postDataFinalSubmissionForTransferApplicationForm(
        pointerID,
        selectedOption
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Transferred Successfully."
        );

        handleVerificationModalClose();

        navigate("/admin/application_manager/view_application", {
          state: { pointerID: pointerID },
        });
      } else {
        setLoading(false);
        console.error(
          "Error in Saving",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.error_msg || "Error in Saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Saving", error);
    }
  };
  const renderTableContent = (data) => {
    return Object.keys(data).map((sectionKey) => {
      const sectionData = data[sectionKey];
  
      return (
        <React.Fragment key={sectionKey}>
          <thead>
            <tr>
              <th
                colSpan={2}
                style={{
                  textAlign: "center",
                  backgroundColor: "#f8f9fa",
                }}
              >
                {sectionKey}
              </th>
            </tr>
          </thead>
          <tbody>
            {sectionData.map((item, index) => (
              <React.Fragment key={index}>
                {Object.keys(item).map((key) => {
                  const value = item[key];
  
                  // Check if value is an array (for subarray handling)
                  if (Array.isArray(value)) {
                    return (
                      <React.Fragment key={key}>
                        <tr>
                          <td
                            colSpan={2}
                            style={{
                              padding: "8px",
                              border: "none",
                              verticalAlign: "top",
                            }}
                          >
                            <h6 style={{ fontWeight: "bold", color: "#055837" }}>
                              {key}
                            </h6>
                          </td>
                        </tr>
                        {value.map((subItem, subIndex) => (
                          <React.Fragment key={subIndex}>
                            {Object.entries(subItem).map(
                              ([subKey, subValue]) =>
                                // Check if subValue is not empty or ""
                                subValue !== "" && subValue != null && (
                                  <tr key={subKey}>
                                    <td style={{ width: "50%", padding: "8px" }}>
                                      {subKey}
                                    </td>
                                    <td style={{ width: "50%", padding: "8px" }}>
                                      {subValue}
                                    </td>
                                  </tr>
                                )
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    // Check if value is not empty or ""
                    return (
                      value !== "" &&
                      value != null && (
                        <tr key={key}>
                          <td style={{ width: "50%", padding: "8px" }}>{key}</td>
                          <td style={{ width: "50%", padding: "8px" }}>
                            {value}
                          </td>
                        </tr>
                      )
                    );
                  }
                })}
              </React.Fragment>
            ))}
          </tbody>
        </React.Fragment>
      );
    });
  };
  

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Application Transfer Preview
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <Card.Body>
                  {CurrentPageData?.length === 0 && !loading && (
                    <div className="no-data-message">
                      <p className="oops-message">Oops! No data to display.</p>
                    </div>
                  )}

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div>
                      <table
                        className="table table-striped table-bordered w-100"
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          borderCollapse: "separate",
                          borderSpacing: 0,
                          border: "0.5px solid #fecc00",
                          cursor: "default",
                          width: "100%",
                        }}
                      >
                        {renderTableContent(CurrentPageData)}
                      </table>
                    </div>

                    <hr className="mb-1 thick-hr" />

                    <div
                      className="button-group text-center mb-0"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleBack}
                        style={{
                          backgroundColor: "#055837",
                          color: "#ffcc01",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#ffcc01",
                          color: "#055837",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onClick={handleExit}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                      >
                        Exit
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleRequestAdditionalInfoModalOpen}
                        style={{
                          backgroundColor: "#055837",
                          color: "#ffcc01",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                      >
                        Complete Transfer
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <VerificationModal
        show={showVerificationModal}
        onClose={handleVerificationModalClose}
        onYes={handleVerificationModalYes}
        Title="Confirm !"
        Message={
          <>
            Are you sure you want to transfer{" "}
            {/* <span style={{ fontWeight: "bold", color: "#055837" }}>
              Additional Information
            </span> */}
            ?
          </>
        }
      />
    </>
  );
};

export default ApplicantTransferPreview;
