import React, { useEffect, useState } from "react";
import "./Admin Functions.css";
import { Col, Form } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import {
  downloadExcelReportingAPI,
  downloadPDFReportingAPI,
  getReportingDataAPI,
  getReportingMenuAPI,
  getStageWiseStatusAPI,
} from "../../api";
import { useNavigate } from "react-router-dom";

import { FaCaretDown } from "react-icons/fa";
import Loader from "../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reporting = () => {
  const [loading, setLoading] = useState(false);
  const [ExtraData, setExtraData] = useState();
  const navigate = useNavigate();

  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [currentPage, setCurrentPage] = useState(1);

// Initialize state from localStorage or default values
const [dataPerPageButton, setDataPerPageButton] = useState(() => {
  const storedValue = localStorage.getItem("reporting_dataPerPageButton");
  return storedValue ? JSON.parse(storedValue) : "10";
});

const [currentPage, setCurrentPage] = useState(() => {
  const storedValue = localStorage.getItem("reporting_currentPage");
  return storedValue ? JSON.parse(storedValue) : 1;
});

// Update localStorage whenever state changes
useEffect(() => {
  localStorage.setItem("reporting_dataPerPageButton", JSON.stringify(dataPerPageButton));
}, [dataPerPageButton]);

useEffect(() => {
  localStorage.setItem("reporting_currentPage", JSON.stringify(currentPage));
}, [currentPage]);





  const handleDataPerPageChange = (e) => {
    const newDataPerPage = e.target.value;
    setDataPerPageButton(newDataPerPage);

    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", newDataPerPage);
    formdata.append("stage", stage);
    formdata.append("status", status);
    formdata.append("current_status", currentStatus);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("agent_id", agent_id);
    formdata.append("prn", prn_no);
    formdata.append("applicant_no", applicant_no);
    formdata.append("pathway", pathway);
    formdata.append("d_o_b", dob);
    formdata.append("occupation", occupation);

    getReportingDataFunction(formdata);
  };

  const today = new Date().toISOString().split("T")[0];

  const [stage, setStage] = useState("");
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [ShowDown, setShowDown] = useState(false);
  const [stages, setStages] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [currentStatusesList, setCurrentStatusesList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [agentlist, setAgentList] = useState([]);
  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [agent_id, setAgent_id] = useState(null);
  const [prn_no, setPrn_no] = useState("");
  const [applicant_no, setApplicant_no] = useState("");
  const [pathway, setPathway] = useState("");
  const [dob, setDOB] = useState("");
  const [occupation, setOccupation] = useState("");
  const toggleChange = () => {
    setShowDown(!ShowDown);
  };

  const [originalData, setOriginalData] = useState([]);
  const getReportingMenuFunction = async () => {
    try {
      setLoading(true);
      const response = await getReportingMenuAPI();
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOccupationList(response?.data?.response?.data?.occupation_list);
        setCurrentStatusesList(response?.data?.response?.data?.status);
        setStages(response?.data?.response?.data?.stage);
      } else {
        setLoading(false);
        console.error(
          "Error fetching reporting data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching reporting data:", error);
    }
  };

  useEffect(() => {
    getReportingMenuFunction();
  }, []);

  const getStageWiseStatusFunction = async (data) => {
    try {
      setLoading(true);
      const response = await getStageWiseStatusAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // console.log(response?.data?.response?.data?.stage);
        setStatuses(response?.data?.response?.data?.status);
      } else {
        setLoading(false);
        console.error(
          "Error fetching stage wise status data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage wise status data:", error);
    }
  };

  const getReportingDataFunction = async (data) => {
    try {
      setLoading(true);
      const response = await getReportingDataAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOriginalData(response?.data?.response?.data?.page_data);
        setAgentList(response?.data?.response?.data?.agent_listing);
        setExtraData(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching reporting data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching reporting data:", error);
    }
  };

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);

    formdata.append("stage", "");
    formdata.append("status", "");
    formdata.append("current_status", "");
    formdata.append("from_date", "");
    formdata.append("to_date", "");
    formdata.append("agent_id", "");
    formdata.append("prn", "");
    formdata.append("applicant_no", "");
    formdata.append("pathway", "");
    formdata.append("d_o_b", "");
    formdata.append("occupation", "");
    getReportingDataFunction(formdata);
  }, []);

  const downloadPDFReportingFunction = async (data) => {
    try {
      setLoading(true);
      const response = await downloadPDFReportingAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        if (response?.data?.response?.data?.url) {
          window.open(response?.data?.response?.data?.url, "_blank");
          toast.success(
            response?.data?.response?.success_msg || "Download Opened File."
          );
        }
      } else {
        setLoading(false);
        console.error(
          "Error while downloading PDF",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in downloading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage wise status data:", error);
    }
  };
  const downloadExcelReportingFunction = async (data) => {
    try {
      setLoading(true);
      const response = await downloadExcelReportingAPI(data);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        if (response?.data?.response?.data?.url) {
          window.open(response?.data?.response?.data?.url, "_blank");
          toast.success(
            response?.data?.response?.success_msg || "Download Opened File."
          );
        }
      } else {
        setLoading(false);
        console.error(
          "Error while downloading PDF",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in downloading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage wise status data:", error);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;
    const formdata = new FormData();
    formdata.append("page", currentPreviousPageNo);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("stage", stage);
    formdata.append("status", status);
    formdata.append("current_status", currentStatus);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("agent_id", agent_id);
    formdata.append("prn", prn_no);
    formdata.append("applicant_no", applicant_no);
    formdata.append("pathway", pathway);
    formdata.append("d_o_b", dob);
    formdata.append("occupation", occupation);

    getReportingDataFunction(formdata);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    const currentNextPageNo = currentPage + 1;
    const formdata = new FormData();
    formdata.append("page", currentNextPageNo);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("stage", stage);
    formdata.append("status", status);
    formdata.append("current_status", currentStatus);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("agent_id", agent_id);
    formdata.append("prn", prn_no);
    formdata.append("applicant_no", applicant_no);
    formdata.append("pathway", pathway);
    formdata.append("d_o_b", dob);
    formdata.append("occupation", occupation);

    getReportingDataFunction(formdata);
  };

  const viewPDF = () => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("stage", stage);
    formdata.append("status", status);
    formdata.append("current_status", currentStatus);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("agent_id", agent_id);
    formdata.append("prn", prn_no);
    formdata.append("applicant_no", applicant_no);
    formdata.append("pathway", pathway);
    formdata.append("d_o_b", dob);
    formdata.append("occupation", occupation);
    downloadPDFReportingFunction(formdata);
  };
  const viewSpreadSheet = () => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("stage", stage);
    formdata.append("status", status);
    formdata.append("current_status", currentStatus);
    formdata.append("from_date", from_date);
    formdata.append("to_date", to_date);
    formdata.append("agent_id", agent_id);
    formdata.append("prn", prn_no);
    formdata.append("applicant_no", applicant_no);
    formdata.append("pathway", pathway);
    formdata.append("d_o_b", dob);
    formdata.append("occupation", occupation);

    downloadExcelReportingFunction(formdata);
  };
  const handleStageChange = (e) => {
    setStage(e.target.value);
    const formdata = new FormData();
    formdata.append("stage", e.target.value);
    getStageWiseStatusFunction(formdata);
    const formdataAllInfo = new FormData();
    formdataAllInfo.append("page", currentPage);
    formdataAllInfo.append("itemsPerPage", dataPerPageButton);
    formdataAllInfo.append("stage", e.target.value);
    getReportingDataFunction(formdataAllInfo);
  };

  const onSearch = () => {
    const onSearchFormData = new FormData();
    onSearchFormData.append("page", currentPage);
    onSearchFormData.append("itemsPerPage", dataPerPageButton);
    onSearchFormData.append("stage", stage);
    onSearchFormData.append("status", status);
    onSearchFormData.append("current_status", currentStatus);
    onSearchFormData.append("from_date", from_date);
    onSearchFormData.append("to_date", to_date);
    onSearchFormData.append("agent_id", agent_id);
    onSearchFormData.append("prn", prn_no);
    onSearchFormData.append("applicant_no", applicant_no);
    onSearchFormData.append("pathway", pathway);
    onSearchFormData.append("d_o_b", dob);
    onSearchFormData.append("occupation", occupation);
    getReportingDataFunction(onSearchFormData);
  };
  const onRefresh = () => {
    setDataPerPageButton("10");
    setCurrentPage(1);
    setStage("");
    setDOB("");
    setAgent_id("");
    setFrom_date("");
    setTo_date("");
    setPrn_no("");
    setApplicant_no("");
    setPathway("");
    setOccupation("");
    setStatus("");
    setCurrentStatus("");
    const formdata = new FormData();
    formdata.append("page", 1);
    formdata.append("itemsPerPage", 10);
    formdata.append("stage", "");
    formdata.append("status", "");
    formdata.append("current_status", "");
    formdata.append("from_date", "");
    formdata.append("to_date", "");
    formdata.append("agent_id", "");
    formdata.append("prn", "");
    formdata.append("applicant_no", "");
    formdata.append("pathway", "");
    formdata.append("d_o_b", "");
    formdata.append("occupation", "");
    getReportingDataFunction(formdata);
  };
  const onFromDateChange = (e) => {
    setFrom_date(e.target.value);
  };
  const onToDateChange = (e) => {
    setTo_date(e.target.value);
  };
  const onAgentIdChange = (e) => {
    setAgent_id(e.target.value);
  };
  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const onCurrentStatusChange = (e) => {
    setCurrentStatus(e.target.value);
    const formDatacurrentStatus = new FormData();
    formDatacurrentStatus.append("page", 1);
    formDatacurrentStatus.append("itemsPerPage", dataPerPageButton);
    formDatacurrentStatus.append("stage", stage);
    formDatacurrentStatus.append("status", status);
    formDatacurrentStatus.append("current_status", e.target.value);
    formDatacurrentStatus.append("from_date", from_date);
    formDatacurrentStatus.append("to_date", to_date);
    formDatacurrentStatus.append("agent_id", agent_id);
    formDatacurrentStatus.append("prn", prn_no);
    formDatacurrentStatus.append("applicant_no", applicant_no);
    formDatacurrentStatus.append("pathway", pathway);
    formDatacurrentStatus.append("d_o_b", dob);
    formDatacurrentStatus.append("occupation", occupation);
    getReportingDataFunction(formDatacurrentStatus);
  };
  const onPrnChange = (e) => {
    setPrn_no(e.target.value);
  };
  const onApplicantNoChange = (e) => {
    setApplicant_no(e.target.value);
  };
  const onPathwayChange = (e) => {
    setPathway(e.target.value);
  };
  const onDOBChange = (e) => {
    setDOB(e.target.value);
  };
  const onOccupationChange = (e) => {
    setOccupation(e.target.value);
  };


    // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };


  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    
    // Open the URL in a new tab with pointerID as a query parameter
    window.open(`/admin/application_manager/view_application?pointerID=${encodeURIComponent(id)}`, "_blank");
  };
  







  return (
    <>
      {loading && <Loader />}

      <div className="card-body table-responsive">
        <div className="row align-items-end sdrop">
          <Col lg="" className="datatable_width">
     <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
          </Col>
          <div className="col-md-1 col-12">
            <label>
              <b>From Date</b>
            </label>
            <input
              type="date"
              name="from_date"
              value={from_date}
              id="from_date"
              onChange={onFromDateChange}
              className="form-control"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-md-1 col-12">
            <label>
              <b>To Date</b>
            </label>
            <input
              type="date"
              name="to_date"
              value={to_date}
              id="to_date"
              max={today}
              onChange={onToDateChange}
              className="form-control"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="col-md-1 col-12">
            <label>
              <b>Stage</b>
            </label>
            <select
              name="stage"
              id="stage"
              value={stage}
              className="form-select sdrop"
              onChange={handleStageChange}
              style={{ cursor: "pointer" }}
            >
              <option value="">All</option>
              {stages.map((stage, index) => {
                const [key, value] = Object.entries(stage)[0];
                return (
                  <option value={key} key={index}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-1 col-12">
            <label>
              <b>Status</b>
            </label>
            <select
              name="status"
              id="status"
              value={status}
              onChange={onStatusChange}
              className="form-select"
              style={{ cursor: "pointer" }}
            >
              <option value="">All</option>
              {statuses.map((status, index) => {
                const [key, value] = Object.entries(status)[0];
                return (
                  <option value={key} key={index}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-1 col-12">
            <label>
              <b>Current Status</b>
            </label>
            <select
              name="currentStatus"
              id="status"
              value={currentStatus}
              onChange={onCurrentStatusChange}
              className="form-select"
              style={{ cursor: "pointer" }}
            >
              <option disabled value="">
                All
              </option>
              {currentStatusesList.map((status, index) => {
                const [key, value] = Object.entries(status)[0];
                return (
                  <option value={key} key={index}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-md-2 col-12">
            <label>
              <b>Agent/Applicant</b>
            </label>
            <select
              className="form-select"
              id="agent_id"
              value={agent_id}
              onChange={onAgentIdChange}
              data-tooltip-id="my-tooltip"
              style={{ cursor: "pointer" }}
            >
              <option value="">Select Agent / Applicant</option>
              {agentlist.map((item, index) => (
                <option
                  key={index}
                  value={item.agent_id}
                  data-tooltip-content={item.hover_text}
                  data-tooltip-place="top"
                >
                  {item.dropdown_text}
                </option>
              ))}
            </select>
            <Tooltip id="my-tooltip" place="top" />
          </div>
          <div className="col d-flex exports_fun align-items-end">
            <button
              className="btn_green_yellow me-2 btn_report_search"
              onClick={onSearch}
            >
              <i className="bi bi-search"></i>
            </button>
            <button
              className="btn_yellow_green btn_report_search"
              onClick={onRefresh}
              title="Refresh"
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
            <a
              className="btn collapsed arr"
              onClick={toggleChange}
              data-bs-toggle="collapse"
              // href="#collapseExample"
              role="button"
              aria-expanded={ShowDown}
              aria-controls="collapseExample"
              title="Advance Options"
              style={{
                transition: "transform 0.1s ease-in-out",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.3)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <span id="arrow_icon">
                {ShowDown ? (
                  <i className="bi bi-caret-up-fill"></i>
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>
            </a>
          </div>
          <div className="d-flex exports_fun col filesd">
            <a
              onClick={viewPDF}
              className="btn float-end pdf_design me-2"
              title="Export to PDF"
              style={{
                transition: "transform 0.1s ease-in-out",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.2)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <i
                className="bi bi-file-pdf-fill"
                style={{
                  transition: "transform 0.1s ease-in-out",
                }}
              ></i>
            </a>

            <a
              onClick={viewSpreadSheet}
              className="btn float-end excel_design"
              title="Export to Excel"
              style={{
                transition: "transform 0.1s ease-in-out",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.2)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <i
                className="bi bi-file-earmark-spreadsheet"
                style={{
                  transition: "transform 0.1s ease-in-out",
                }}
              ></i>
            </a>
          </div>
        </div>
      </div>

      <div
        className={`collapse-transition collapse-trans ${
          ShowDown ? "show" : ""
        }`}
      >
        <div className="card card-body">
          <div className="row dropdown_report">
            <div className="col">
              <label>
                <b>PRN</b>
              </label>
              <input
                type="text"
                name="prn_no"
                value={prn_no}
                onChange={onPrnChange}
                className="form-control"
                id="prn"
              />
            </div>
            <div className="col">
              <label>
                <b>Applicant No.</b>
              </label>
              <input
                type="text"
                name="applicant_no"
                value={applicant_no}
                onChange={onApplicantNoChange}
                className="form-control"
                id="applicant_no"
              />
            </div>
            <div className="col">
              <label>
                <b>Pathway</b>
              </label>
              <select
                className="form-select"
                name="pathway"
                value={pathway}
                onChange={onPathwayChange}
                id="pathway"
              >
                <option value="">All</option>
                <option value="Pathway 1">Pathway 1</option>
                <option value="Pathway 2">Pathway 2</option>
              </select>
            </div>

            <div className="col">
              <label>
                <b>D.O.B</b>
              </label>
              <input
                type="date"
                name="dob"
                className="form-control"
                value={dob}
                onChange={onDOBChange}
                id="dob"
              />
            </div>

            <div className="col">
              <label>
                <b>Occupation</b>
              </label>
              <select
                name="occupation"
                className="form-select"
                value={occupation}
                onChange={onOccupationChange}
                id="occupation"
              >
                <option value="">All</option>
                {occupationList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div id="table_wrapper" className="dataTables_wrapper no-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive table-wrapper">
                <table
                  class="table table-striped table-hover dataTable no-footer"
                  style={{ cursor: "default" }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ width: "5%" }}
                        className="th_font_size"
                      >
                        PRN
                      </th>
                      <th
                        scope="col"
                        style={{ width: "8%" }}
                        className="th_font_size"
                      >
                        Applicant No.
                      </th>
                      <th
                        scope="col"
                        style={{ width: "20%" }}
                        className="th_font_size"
                      >
                        Applicant Name
                      </th>
                      <th
                        scope="col"
                        style={{ width: "10%" }}
                        className="th_font_size"
                      >
                        D.O.B.
                      </th>
                      <th
                        scope="col"
                        style={{ width: "15%" }}
                        className="th_font_size"
                      >
                        Occupation
                      </th>
                      <th
                        scope="col"
                        style={{ width: "12%" }}
                        className="th_font_size"
                      >
                        Pathway
                      </th>
                      <th
                        scope="col"
                        style={{ width: "15%" }}
                        className="th_font_size"
                      >
                        Date Submitted
                      </th>
                      <th
                        scope="col"
                        style={{ width: "15%" }}
                        className="th_font_size"
                      >
                        Current Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {originalData.map((item, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even" : "odd"}
                      >
                        <td className="td_font_size">{item?.prn}</td>
                        <td className="td_font_size">{item?.applicant_no}</td>

                        <td
                          onClick={(event) =>
                            handleNavigateToViewApplication(event, item?.id)
                          }
                          style={{
                            color: "#055837",
                            // color: "red",
                            cursor: "pointer",
                          }}
                          className="hover-underline td_font_size"
                        >
                          {item?.applicant_name}
                        </td>

                        <td className="td_font_size">{item?.dob}</td>
                        <td className="td_font_size">{item?.occupation}</td>

                        <td className="td_font_size">{item?.pathway}</td>
                        <td className="td_font_size">{item?.date?.value}</td>
                        <td className="td_font_size">{item?.current_status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <hr className="mb-3 thick-hr" />

        <div className="pagination-container">
          <div className="dataTables_info">
            Showing {ExtraData?.currentPage} to {ExtraData?.itemsPerPage} of{" "}
            {ExtraData?.totalRows} entries
          </div>

          <div className="pagination-buttons">
            <button
              className={`paginate_button ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={
                currentPage === 1
                  ? {
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              Previous
            </button>

            <button className="paginate_button">{currentPage}</button>

            <button
              className={`paginate_button ${
                ExtraData?.next_disabled === true ? "disabled" : ""
              }`}
              onClick={handleNext}
              disabled={ExtraData?.next_disabled === true}
              style={
                ExtraData?.next_disabled === true
                  ? {
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reporting;
