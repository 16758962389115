import React from "react";
const DataAnalytics = () => {
  return (
    <div>
      <div class="col-12">Data Analysis</div>
    </div>
  );
};

export default DataAnalytics;
