import React, { useState, useEffect, useRef } from "react";
import { Accordion, Col, Row, Table } from "react-bootstrap";
import {
  postSaveSingleFieldOfViewUpdateOfApplicationManager,
  postUpdateStageStatusOfDataViewUpdateOfApplicationManager,
} from "../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Widgets/Loader/Loader";
import VerificationModal from "./VerificationModal/VerificationModal";

const Stage3 = ({ ApiPageData, pointerID, getAllDataOfViewUpdateFunction }) => {
  const stage = "stage_3";

  const [loading, setLoading] = useState(false);

  const [extraData, setExtraData] = useState({});
  const [isExtraDataEditing, setIsExtraDataEditing] = useState({});

  const [BigTextAreaData, setBigTextAreaData] = useState({});
  const [isBigTextAreaEditing, setIsBigTextAreaEditing] = useState({});

  const [teamMember, setTeamMember] = useState({});
  const [isTeamMemberEditing, setIsTeamMemberEditing] = useState({});

  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState("");

  const [isFileEditing, setIsFileEditing] = useState({});
  const [fileState, setFileState] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const fileInputRefs = useRef({});

  const [currentFields, setCurrentFields] = useState([]);

  useEffect(() => {
    if (ApiPageData) {
      setStatusOptions(ApiPageData?.options || []);
      //   setSelectedStatusValue(ApiPageData?.status || "");
      const currentStatusOption = ApiPageData?.options?.find(
        (opt) => opt?.option === ApiPageData?.status
      );
      setCurrentFields(currentStatusOption?.fields || []);
    }
  }, [ApiPageData]);

  useEffect(() => {
    const currentStatusOption = statusOptions.find(
      (opt) => opt?.option === selectedStatusValue
    );
    setCurrentFields(currentStatusOption?.fields || []);
  }, [selectedStatusValue, statusOptions]);

  const saveSingleDataFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Saved Successfully."
        );

        getAllDataOfViewUpdateFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving single data:", error);
    }
  };

  const autoSaveSingleDataFunction = async (formData) => {
    try {
      // setLoading(true);

      const response =
        await postSaveSingleFieldOfViewUpdateOfApplicationManager(formData);

      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // toast.success(
        //   response?.data?.response?.success_msg || "Saved Successfully."
        // );
        // getAllDataOfViewUpdateFunction(pointerID);
      } else {
        // setLoading(false);
        console.error(
          "Error saving single data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        // toast.error(response?.data?.response?.error_msg || "Error in saving");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error saving single data:", error);
    }
  };

  const UpdateStageStatusFunction = async (formData) => {
    try {
      setLoading(true);

      const response =
        await postUpdateStageStatusOfDataViewUpdateOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setSelectedStatusValue("");
        toast.success(
          response?.data?.response?.success_msg || "Stage Updated Successfully."
        );
        getAllDataOfViewUpdateFunction(pointerID);
        setCancelRequestShow(false);
      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const handleChangeStatus = (event) => {
    const newStatus = event.target.value;

    // Clear all states when status changes
    if (newStatus !== selectedStatusValue) {
      setExtraData({});
      setFileState({});
      setFileErrors({});
      setTeamMember({});
      setIsFileEditing({});
      setIsExtraDataEditing({});
      setIsTeamMemberEditing({});
      setBigTextAreaData({});
      setIsBigTextAreaEditing({});
    }

    setSelectedStatusValue(newStatus);
  };
  const handleUpdate = () => {
    if (selectedStatusValue === "") {
      toast.warning("Please select status");
      return;
    }

    const formData = new FormData();
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);
    formData.append("id", ApiPageData?.id);

    [
      ...Object.entries(extraData),
      ...Object.entries(BigTextAreaData),
      ...Object.entries(fileState),
      ...Object.entries(teamMember),
    ].forEach(([param, value]) => {
      if (currentFields.some((field) => field.param === param)) {
        if (value) {
          formData.append(param, value);
        }
      }
    });

    UpdateStageStatusFunction(formData);
  };

  const handleChangeAQATO_TeamMember = (e, param) => {
    setTeamMember((prevData) => ({
      ...prevData,
      [param]: e.target.value,
    }));
    setIsTeamMemberEditing((prevEditing) => ({
      ...prevEditing,
      [param]: true,
    }));

    const formData = new FormData();
    formData.append("param", param);
    formData.append("value", e.target.value);
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    autoSaveSingleDataFunction(formData);
  };

  const toggleTeamMemberEditing = (param) => {
    setIsTeamMemberEditing((prevEditing) => ({
      ...prevEditing,
      [param]: !prevEditing[param],
    }));

    if (isTeamMemberEditing[param]) {
      saveTeamMember(param);
    }
  };

  const saveTeamMember = (param) => {
    console.log(
      `Saving team member for field: ${param}, Value:`,
      teamMember[param]
    );

    const formData = new FormData();

    formData.append("param", param);
    formData.append("value", teamMember[param]);

    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    saveSingleDataFunction(formData);
    setTeamMember((prevState) => ({ ...prevState, [param]: "" }));
  };

  const handleFileChange = (e, param) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [param]: "Please upload a PDF file.",
      }));
      toast.error("Please upload a PDF file.");
      setFileState((prevState) => ({ ...prevState, [param]: null }));
      fileInputRefs.current[param].value = null;
    } else {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [param]: "",
      }));
      setFileState((prevState) => ({ ...prevState, [param]: file }));
    }
  };

  const toggleFileEditing = (param) => {
    const isEnteringEditMode = !isFileEditing[param];
    setIsFileEditing((prevEditing) => ({
      ...prevEditing,
      [param]: isEnteringEditMode,
    }));

    if (!isEnteringEditMode) {
      saveFile(param);
    }
  };

  const saveFile = (param) => {
    if (!fileState[param]) {
      toast.error("Please select a PDF file.");
      return;
    }

    console.log(`Saving file for field: ${param}, File:`, fileState[param]);

    const formData = new FormData();
    formData.append("param", param);
    formData.append("value", fileState[param]);
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    saveSingleDataFunction(formData);

    setFileState((prevState) => ({ ...prevState, [param]: null }));
    setFileState((prevState) => ({ ...prevState, [param]: null }));
    fileInputRefs.current[param].value = null;
  };

  const handleExtraDataChange = (e, param) => {
    setExtraData((prevData) => ({
      ...prevData,
      [param]: e.target.value,
    }));

    const formData = new FormData();
    formData.append("param", param);
    formData.append("value", e.target.value);
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    autoSaveSingleDataFunction(formData);
  };

  const toggleExtraDataEditing = (param) => {
    setIsExtraDataEditing((prevEditing) => ({
      ...prevEditing,
      [param]: !prevEditing[param],
    }));

    if (isExtraDataEditing[param]) {
      saveExtraData(param);
    }
  };

  const saveExtraData = (param) => {
    console.log(
      `Saving extra data for field: ${param}, Value:`,
      extraData[param]
    );

    const formData = new FormData();

    formData.append("param", param);
    formData.append("value", extraData[param]);

    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    saveSingleDataFunction(formData);
    setExtraData((prevState) => ({ ...prevState, [param]: "" }));
  };

  const handleBigTextAreaDataChange = (e, param) => {
    setBigTextAreaData((prevData) => ({
      ...prevData,
      [param]: e.target.value,
    }));

    const formData = new FormData();
    formData.append("param", param);
    formData.append("value", e.target.value);
    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    autoSaveSingleDataFunction(formData);
  };

  const toggleBigTextAreaDataEditing = (param) => {
    setIsBigTextAreaEditing((prevEditing) => ({
      ...prevEditing,
      [param]: !prevEditing[param],
    }));

    if (isBigTextAreaEditing[param]) {
      saveBigTextAreaData(param);
    }
  };

  const saveBigTextAreaData = (param) => {
    console.log(
      `Saving big text area data for field: ${param}, Value:`,
      BigTextAreaData[param]
    );
    const formData = new FormData();

    formData.append("param", param);
    formData.append("value", BigTextAreaData[param]);

    formData.append("status", selectedStatusValue);
    formData.append("stage", stage);
    formData.append("pointer_id", pointerID);

    saveSingleDataFunction(formData);
    setBigTextAreaData((prevState) => ({ ...prevState, [param]: "" }));
  };

  const [showCancelRequestModal, setCancelRequestShow] = useState(false);
  const handleCancelRequestClose = () => setCancelRequestShow(false);
  const handleCancelRequestShow = () => {
    setCancelRequestShow(true);
  };

  return (
    <>
      <Accordion.Item eventKey="3">
        <Accordion.Header className="accordion_header">
          Stage 3 - Technical Interview
        </Accordion.Header>

        {loading && <Loader />}

        <Accordion.Body>
          <Table striped>
            <tbody>
              {ApiPageData?.status && (
                <tr style={{ cursor: "default" }}>
                  <td>
                    <b style={{ color: "#055837" }}>Current Status</b>
                  </td>
                  <td className="w-25">
                    <b style={{ color: "#055837" }}>{ApiPageData?.status}</b>
                  </td>
                </tr>
              )}

              {ApiPageData?.submitted_date !== "" && (
                <tr>
                  <td width="30%">Date Submitted</td>
                  <td className="w-25">{ApiPageData?.submitted_date}</td>
                </tr>
              )}

              {ApiPageData?.lodged_date !== "" && (
                <tr>
                  <td width="30%">Date Loged</td>
                  <td className="w-25">{ApiPageData?.lodged_date}</td>
                </tr>
              )}

              {ApiPageData?.in_progress_date !== "" && (
                <tr>
                  <td width="30%">Date In Progress</td>
                  <td className="w-25">{ApiPageData?.in_progress_date}</td>
                </tr>
              )}

              {ApiPageData?.approved_date !== "" && (
                <tr>
                  <td width="30%">Date Approved</td>
                  <td className="w-25">{ApiPageData?.approved_date}</td>
                </tr>
              )}

              {ApiPageData?.omitted_date !== "" && (
                <tr>
                  <td width="30%">Ommited Date</td>
                  <td className="w-25">{ApiPageData?.omitted_date}</td>
                </tr>
              )}

              {ApiPageData?.withdraw_date !== "" && (
                <tr>
                  <td width="30%">Withdraw Date</td>
                  <td className="w-25">{ApiPageData?.withdraw_date}</td>
                </tr>
              )}

              {ApiPageData?.declined_date !== "" && (
                <tr>
                  <td width="30%">Declined Date</td>
                  <td className="w-25">{ApiPageData?.declined_date}</td>
                </tr>
              )}

              {ApiPageData?.archive_date !== "" && (
                <tr>
                  <td width="30%">Archive Date</td>
                  <td className="w-25">{ApiPageData?.archive_date}</td>
                </tr>
              )}

              {ApiPageData?.expiry_custom_date &&
                ApiPageData?.expiry_custom_date.length > 0 && (
                  <tr>
                    <td width="30%">
                      {ApiPageData?.expiry_custom_date?.label}
                    </td>
                    <td className="w-25">
                      {ApiPageData?.expiry_custom_date?.value}
                    </td>
                  </tr>
                )}

              {/* {ApiPageData?.expiry_date !== "" && (
                <tr>
                  <td width="30%">Expiry Date</td>
                  <td className="w-25">{ApiPageData?.expiry_date}</td>
                </tr>
              )}

              {ApiPageData?.closure_date !== "" && (
                <tr>
                  <td width="30%">Closure Date</td>
                  <td className="w-25">{ApiPageData?.closure_date}</td>
                </tr>
              )} */}

              {ApiPageData?.date_reinstate !== "" && (
                <tr>
                  <td width="30%">Reinstate Date</td>
                  <td className="w-25">{ApiPageData?.date_reinstate}</td>
                </tr>
              )}

              {ApiPageData?.date_reinstate !== "" && (
                <tr>
                  <td width="30%">Loged Date Additional</td>
                  <td className="w-25">
                    {ApiPageData?.lodged_date_additional}
                  </td>
                </tr>
              )}

              {ApiPageData?.lodged_comment !== "" && (
                <tr>
                  <td width="30%">Loged Comment</td>
                  <td className="w-25">{ApiPageData?.lodged_comment}</td>
                </tr>
              )}

              {ApiPageData?.approved_comment !== "" && (
                <tr>
                  <td width="30%">Approved Comment</td>
                  <td className="w-25">{ApiPageData?.approved_comment}</td>
                </tr>
              )}

              {ApiPageData?.declined_reason !== "" && (
                <tr>
                  <td width="30%">Declined Reason</td>
                  <td className="w-25">{ApiPageData?.declined_reason}</td>
                </tr>
              )}

              <tr>
                <td width="30%">Change Status</td>
                <td className="w-25">
                  <Row>
                    <Col lg={8}>
                      <select
                        className="form-select"
                        value={selectedStatusValue}
                        onChange={handleChangeStatus}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        {statusOptions.map((opt) => (
                          <option
                            key={opt?.option}
                            value={opt?.option}
                            disabled={opt?.is_enabled === 0}
                          >
                            {opt?.option}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </td>
              </tr>

              {
                currentFields.length > 0
                  ? currentFields.map((field) => (
                      <React.Fragment key={field?.param}>
                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ||
                                      field?.db_value ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"
                                    disabled={
                                      !isTeamMemberEditing[field?.param]
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field.type === "textarea" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    value={
                                      BigTextAreaData[field?.param] ||
                                      field?.db_value ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleBigTextAreaDataChange(
                                        e,
                                        field?.param
                                      )
                                    }
                                    disabled={
                                      !isBigTextAreaEditing[field?.param]
                                    }
                                  />
                                </Col>

                                <Col lg={4} className="my-auto">
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    title={
                                      isBigTextAreaEditing[field?.param]
                                        ? "Save"
                                        : "Edit"
                                    }
                                    onClick={() =>
                                      toggleBigTextAreaDataEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isBigTextAreaEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                {field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && (
                                    <div className="d-flex">
                                      <span style={{ cursor: "default" }}>
                                        Uploaded File:
                                      </span>
                                      <a
                                        href={
                                          field?.db_value?.document_fullpath
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          marginBottom: "10px",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                          color: "blue",
                                          marginLeft: "5px",
                                        }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "underline")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "none")
                                        }
                                      >
                                        {field?.db_value?.document_name}
                                      </a>
                                    </div>
                                  )}

                                <Col lg={8} className="my-auto">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }
                                    disabled={!isFileEditing[field.param]}
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleFileEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isFileEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ||
                                        field?.db_value ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }
                                      disabled={
                                        !isExtraDataEditing[field?.param]
                                      }
                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">
                                    <button
                                      className="btn btn-sm btn_green_yellow"
                                      title={
                                        isExtraDataEditing[field?.param]
                                          ? "Save"
                                          : "Edit"
                                      }
                                      onClick={() =>
                                        toggleExtraDataEditing(field?.param)
                                      }
                                    >
                                      <i
                                        className={
                                          isExtraDataEditing[field?.param]
                                            ? "bi bi-check-lg"
                                            : "bi bi-pencil-square"
                                        }
                                      ></i>
                                    </button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }

              {
                ApiPageData?.selected_options?.fields.length > 0
                  ? ApiPageData?.selected_options?.fields.map((field) => (
                      <React.Fragment key={field?.param}>
                        {field?.type === "select" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <select
                                    value={
                                      teamMember[field?.param] ||
                                      field?.db_value ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleChangeAQATO_TeamMember(
                                        e,
                                        field?.param
                                      )
                                    }
                                    className="form-select"
                                    disabled={
                                      !isTeamMemberEditing[field?.param]
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <option value="" disabled>
                                      Select Team Member
                                    </option>
                                    {field.options?.map((member) => (
                                      <option
                                        key={member.id}
                                        value={member.id}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {member.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </Col>

                                <Col>
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleTeamMemberEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isTeamMemberEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field.type === "textarea" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                <Col lg={8}>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    value={
                                      BigTextAreaData[field?.param] ||
                                      field?.db_value ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleBigTextAreaDataChange(
                                        e,
                                        field?.param
                                      )
                                    }
                                    disabled={
                                      !isBigTextAreaEditing[field?.param]
                                    }
                                  />
                                </Col>

                                <Col lg={4} className="my-auto">
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    title={
                                      isBigTextAreaEditing[field?.param]
                                        ? "Save"
                                        : "Edit"
                                    }
                                    onClick={() =>
                                      toggleBigTextAreaDataEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isBigTextAreaEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field?.type === "file" && (
                          <tr>
                            <td>{field?.label}</td>
                            <td className="w-25">
                              <Row>
                                {field?.db_value?.document_name !== "" &&
                                  field?.db_value?.document_fullpath !== "" && (
                                    <div className="d-flex">
                                      <span style={{ cursor: "default" }}>
                                        Uploaded File:
                                      </span>
                                      <a
                                        href={
                                          field?.db_value?.document_fullpath
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          marginBottom: "10px",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                          color: "blue",
                                          marginLeft: "5px",
                                        }}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "underline")
                                        }
                                        onMouseOut={(e) =>
                                          (e.currentTarget.style.textDecoration =
                                            "none")
                                        }
                                      >
                                        {field?.db_value?.document_name}
                                      </a>
                                    </div>
                                  )}

                                <Col lg={8} className="my-auto">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    className="form-control"
                                    ref={(el) =>
                                      (fileInputRefs.current[field.param] = el)
                                    }
                                    onChange={(e) =>
                                      handleFileChange(e, field?.param)
                                    }
                                    disabled={!isFileEditing[field.param]}
                                  />
                                  {fileErrors[field?.param] && (
                                    <div className="text-danger">
                                      {fileErrors[field?.param]}
                                    </div>
                                  )}
                                </Col>
                                <Col lg={4} className="my-auto">
                                  <button
                                    className="btn btn-sm btn_green_yellow"
                                    onClick={() =>
                                      toggleFileEditing(field?.param)
                                    }
                                  >
                                    <i
                                      className={
                                        isFileEditing[field?.param]
                                          ? "bi bi-check-lg"
                                          : "bi bi-pencil-square"
                                      }
                                    ></i>
                                  </button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )}

                        {field.type !== "file" &&
                          field.type !== "select" &&
                          field.type !== "textarea" && (
                            <tr>
                              <td>{field?.label}</td>
                              <td className="w-25">
                                <Row>
                                  <Col lg={8}>
                                    <input
                                      type={field?.type}
                                      className="form-control"
                                      value={
                                        extraData[field?.param] ||
                                        field?.db_value ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleExtraDataChange(e, field?.param)
                                      }
                                      disabled={
                                        !isExtraDataEditing[field?.param]
                                      }
                                    />
                                  </Col>

                                  <Col lg={4} className="my-auto">
                                    <button
                                      className="btn btn-sm btn_green_yellow"
                                      title={
                                        isExtraDataEditing[field?.param]
                                          ? "Save"
                                          : "Edit"
                                      }
                                      onClick={() =>
                                        toggleExtraDataEditing(field?.param)
                                      }
                                    >
                                      <i
                                        className={
                                          isExtraDataEditing[field?.param]
                                            ? "bi bi-check-lg"
                                            : "bi bi-pencil-square"
                                        }
                                      ></i>
                                    </button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))
                  : ""

                // <tr>
                //   <td colSpan="2">
                //     <b>No field is present for the selected status.</b>
                //   </td>
                // </tr>
              }

              <tr>
                <td colSpan={2} className="text-center">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="submit"
                      className="btn btn_yellow_green"
                      // onClick={handleUpdate}
                      disabled={!selectedStatusValue}
                      onClick={handleCancelRequestShow}
                    >
                      Update
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>

      {showCancelRequestModal && selectedStatusValue && (
        <VerificationModal
          show={showCancelRequestModal}
          onClose={handleCancelRequestClose}
          onYes={handleUpdate}
          Title="Confirm Update!"
          Message={
            <>
              Are you sure you want to update status to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                {selectedStatusValue}
              </span>
              &nbsp;this ?
            </>
          }
        />
      )}
    </>
  );
};

export default Stage3;
