import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateStaffApi, getSingleStaffManagementAPI } from "../../../../api";
import Loader from "../../../Widgets/Loader/Loader";

const EditOccupationModal = ({
  getOccupationsFunction,
  countries,
  showModal,
  handleClose,
  SelectedItem,
  type,
}) => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    applicantFirstName: "",
    applicantMiddleName: "",
    applicantInitial: "",
    applicantLastName: "",
    applicantNo: "",
    selectedRole: "head_office",
    selectedStatus: "active",
    selectedCountryCode: "13",
    email: "",
    password: "",
  });

  useEffect(() => {
    setCountryCodes(countries);
  }, [countries]);

  const [loading, setLoading] = useState(false);

  const handlePasswordVisibility = () => {
    const passwordField = document.getElementById("password-1");
    const showEye = document.getElementById("show_eye-1");
    const hideEye = document.getElementById("hide_eye-1");

    if (passwordField.type === "password") {
      passwordField.type = "text";
      showEye.style.display = "none";
      hideEye.style.display = "block";
    } else {
      passwordField.type = "password";
      showEye.style.display = "block";
      hideEye.style.display = "none";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for required fields
    if (!formData.applicantFirstName) {
      toast.error("First Name is required");
      return;
    }
    // if (!formData.applicantMiddleName) {
    //   toast.error("Middle Name is required");
    //   return;
    // }
    if (!formData.applicantInitial) {
      toast.error("Initial is required");
      return;
    }
    if (!formData.applicantLastName) {
      toast.error("Last Name is required");
      return;
    }
    if (!formData.applicantNo) {
      toast.error("Mobile Number is required");
      return;
    }
    if (!formData.selectedRole) {
      toast.error("User Role is required");
      return;
    }
    if (!formData.selectedStatus) {
      toast.error("Status is required");
      return;
    }
    if (!formData.selectedCountryCode) {
      toast.error("Country Code is required");
      return;
    }
    if (!formData.email) {
      toast.error("Email is required");
      return;
    }
    if (!formData.password) {
      toast.error("Password is required");
      return;
    }

    setLoading(true);

    const passingData = new FormData();
    passingData.append("id", SelectedItem?.id);

    passingData.append("first_name", formData.applicantFirstName);
    passingData.append("middle_name", formData.applicantMiddleName);
    passingData.append("inital_name", formData.applicantInitial);
    passingData.append("last_name", formData.applicantLastName);
    passingData.append("mobile_no", formData.applicantNo);
    passingData.append("account_type", formData.selectedRole);
    passingData.append("status", formData.selectedStatus);
    passingData.append("mobile_code", formData.selectedCountryCode);
    passingData.append("email", formData.email);
    passingData.append("password", formData.password);
    passingData.append("type", type);

    try {
      const updateStaffApi_ = await updateStaffApi(passingData);
      setLoading(false);

      if (updateStaffApi_?.data?.response?.response === true) {
        getOccupationsFunction();
        toast.success(updateStaffApi_?.data?.response?.success_msg);
        handleClose();
      } else {
        toast.error(updateStaffApi_?.data?.response?.error_msg);
      }
    } catch (error) {
      toast.error("An error occurred while adding staff.");
      setLoading(false);
    }
  };

  const roles = [
    // { id: 1, value: "admin", label: "AQATO" },
    { id: 2, value: "head_office", label: "ATTC" },
  ];

  const statuses = [
    { id: 1, value: "active", label: "Active" },
    { id: 2, value: "inactive", label: "In-Active" },
  ];

  const getPrefilledDataFunction = async () => {
    if (!SelectedItem?.id) return;

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("id", SelectedItem.id);

      const response = await getSingleStaffManagementAPI(formData);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        const RES_Data = response.data.response.data;

        if (RES_Data?.staff?.id) {
          setFormData({
            id: RES_Data?.staff?.id,
            applicantFirstName: RES_Data?.staff?.first_name,
            applicantMiddleName: RES_Data?.staff?.middle_name,
            applicantInitial: RES_Data?.staff?.inital_name,
            applicantLastName: RES_Data?.staff?.last_name,
            applicantNo: RES_Data?.staff?.mobile_no,
            selectedRole: RES_Data?.staff?.account_type,
            selectedStatus: RES_Data?.staff?.status,
            selectedCountryCode: RES_Data?.staff?.mobile_code,
            email: RES_Data?.staff?.email,
            password: "",
          });
        }
      } else {
        console.error(
          "Error fetching data:",
          response.data.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (showModal) {
      getPrefilledDataFunction();
    }
  }, [showModal]);

  return (
    <>
      {loading && <Loader />}
      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        backdrop="static"
        centered
        size="xl"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="text-green text-center">
              <h5>Edit ATTC Staff</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md="6">
                <Row>
                  <Col md="4">
                    <div className="zoom_details">
                      <label
                        htmlFor="applicantInitial"
                        className="form-label label-font-size"
                      >
                        <p className="para">Initial</p>
                      </label>
                      <input
                        name="applicantInitial"
                        type="text"
                        className="form-control"
                        id="applicantInitial"
                        value={formData.applicantInitial}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                  <Col md="8">
                    <div className="zoom_details">
                      <label
                        htmlFor="applicantFirstName"
                        className="form-label label-font-size"
                      >
                        <p className="para">
                          First Name <span className="text-danger">*</span>
                        </p>
                      </label>
                      <input
                        name="applicantFirstName"
                        type="text"
                        className="form-control"
                        id="applicantFirstName"
                        value={formData.applicantFirstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <div className="zoom_details">
                  <label
                    htmlFor="applicantMiddleName"
                    className="form-label label-font-size"
                  >
                    <p className="para">Middle Name</p>
                  </label>
                  <input
                    name="applicantMiddleName"
                    type="text"
                    className="form-control"
                    id="applicantMiddleName"
                    value={formData.applicantMiddleName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="zoom_details">
                  <label
                    htmlFor="applicantLastName"
                    className="form-label label-font-size"
                  >
                    <p className="para">
                      Last Name <span className="text-danger">*</span>
                    </p>
                  </label>
                  <input
                    name="applicantLastName"
                    type="text"
                    className="form-control"
                    id="applicantLastName"
                    value={formData.applicantLastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </Col>

              <Col md="6">
                <label htmlFor="selectedCountryCode" className="form-label">
                  <label htmlFor="selectedCountryCode" className="form-label">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                </label>
                <Row>
                  <Col md="5">
                    <select
                      name="selectedCountryCode"
                      className="form-select"
                      value={formData.selectedCountryCode}
                      onChange={handleChange}
                      required
                      style={{ cursor: "pointer" }}
                    >
                      {countryCodes.map((countryCode) => (
                        <option key={countryCode?.id} value={countryCode?.id}>
                          {countryCode.name} (+{countryCode.phonecode})
                        </option>
                      ))}
                    </select>
                  </Col>

                  <Col md="7">
                    <input
                      name="applicantNo"
                      type="text"
                      className="form-control"
                      id="applicantNo"
                      value={formData.applicantNo}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <label htmlFor="email" className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md="6">
                <label htmlFor="password" className="form-label">
                  Password <span className="text-danger">*</span>
                </label>

                <div className="input-group mb-3">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    id="password-1"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="false"
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={handlePasswordVisibility}
                      style={{
                        cursor: "pointer",
                        height: "38px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="bi bi-eye-fill"
                        id="show_eye-1"
                        style={{ display: "block" }}
                      />
                      <i
                        className="bi bi-eye-slash-fill"
                        id="hide_eye-1"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <label htmlFor="selectedRole" className="form-label">
                  User Role <span className="text-danger">*</span>
                </label>
                <select
                  name="selectedRole"
                  className="form-select mb-3"
                  value={formData.selectedRole}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option disabled value="">
                    Select Role
                  </option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md="6">
                <label htmlFor="selectedStatus" className="form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <select
                  name="selectedStatus"
                  className="form-select mb-3"
                  value={formData.selectedStatus}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option value="">Select Status</option>
                  {statuses.map((status) => (
                    <option key={status.id} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn_yellow_green"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="btn_modal"
              type="submit"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={handleSubmit}
            >
            Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditOccupationModal;
