import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { getDataArchiveAPI } from "../../api";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../Widgets/Loader/Loader";

const Archive = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getArchiveDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getDataArchiveAPI();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const archiveData = response?.data?.response?.data;
        setOriginalData(archiveData);
        setCurrentPageData(archiveData.slice(0, parseInt(dataPerPageButton)));
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getArchiveDataFunction();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
    }
  }, [searchQuery, dataPerPageButton, originalData]);


    // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };


  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    
    // Open the URL in a new tab with pointerID as a query parameter
    window.open(`/admin/application_manager/view_application?pointerID=${encodeURIComponent(id)}`, "_blank");
  };
  

  return (
    <>


{loading && <Loader />}


      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

   

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main mb-5">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">Archive</h3>
            </div>
            <div className="main-body-area">
              <Card className="shadow">
                <Card.Body>
                  <div className="action_buttons_top bg-white action_btn_desk">
                    <Row className="my-2 mx-2 align-items-center">
                      <Col lg="2" sm="2" className="my-auto">
                      <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
   value={dataPerPageButton}
   onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>

                      </Col>
                      <Col lg="10" sm="12">
                        <Row className="justify-content-end align-items-center search-btn">
                          <Col lg="9">
                            <Form.Control
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearch}
                              className="searchInput"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{ width: "10%" }}
                                    className="th_font_size"
                                  >
                                    PRN
                                  </th>
                                  <th
                                    style={{ width: "14%" }}
                                    className="th_font_size"
                                  >
                                    Applicant No.
                                  </th>
                                  <th
                                    style={{ width: "20%" }}
                                    className="th_font_size"
                                  >
                                    Applicant Name
                                  </th>
                                  <th
                                    style={{ width: "14%" }}
                                    className="th_font_size"
                                  >
                                    D.O.B
                                  </th>
                                  <th
                                    style={{ width: "14%" }}
                                    className="th_font_size"
                                  >
                                    Occupation
                                  </th>
                                  <th
                                    style={{ width: "14%" }}
                                    className="th_font_size"
                                  >
                                    Date Submitted
                                  </th>
                                  <th
                                    style={{ width: "14%" }}
                                    className="th_font_size"
                                  >
                                    Current Status
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentPageData.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={index % 2 === 0 ? "even" : "odd"}
                                  >
                                    <td className="td_font_size">
                                      {item?.portal_reference_no}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.unique_id}
                                    </td>

                                    <td
                                      onClick={(event) =>
                                        handleNavigateToViewApplication(
                                          event,
                                          item?.pointer_id
                                        )
                                      }
                                      style={{
                                        color: "#055837",
                                        cursor: "pointer",
                                        fontSize: "17px",
                                      }}
                                      className="hover-underline"
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          transition:
                                            "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.transform =
                                            "scale(1.1)";
                                          e.target.style.textDecoration =
                                            "underline";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.transform = "scale(1)";
                                          e.target.style.textDecoration =
                                            "none";
                                        }}
                                      >
                                        {item?.Applicant_name}
                                      </span>
                                    </td>

                                    <td className="td_font_size">
                                      {item?.date_of_birth}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.occupation_name}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.submitted_date_format}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.Current_Status}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-3 thick-hr" />

                    <div className="pagination-container">
                      <div className="dataTables_info">
                        Showing{" "}
                        {currentPage === 1
                          ? 1
                          : (currentPage - 1) * dataPerPageButton + 1}{" "}
                        to{" "}
                        {Math.min(
                          currentPage * dataPerPageButton,
                          originalData.length
                        )}{" "}
                        of {originalData.length} entries
                      </div>

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          style={
                            currentPage === 1
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Previous
                        </button>

                        <button className="paginate_button">
                          {currentPage}
                        </button>

                        <button
                          className={`paginate_button ${
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? "disabled"
                              : ""
                          }`}
                          onClick={handleNext}
                          disabled={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                          }
                          style={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Archive;
