import React, { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "../MailTemplate.css";

import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
// import { BsArrowLeft } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import SidebarMenu from "../../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Add from "./Add";
import EditNameKey from "./EditNameKey";
import { getNameKeywordsDataAPI } from "../../../api";
import Loader from "../../Widgets/Loader/Loader";

// import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";

const NameKeywords = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1); // Reset to the first page whenever the items per page change
  };

  const [deletingPersonName, setDeletingPersonName] = useState("");
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);

  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [PointerID, setPointerID] = useState(null);

  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  //   const formatDate = (dateString) => {
  //     const date = new Date(dateString);
  //     const day = date.getDate();
  //     const month = date.getMonth() + 1;
  //     const year = date.getFullYear();

  //     const formattedDay = day < 10 ? `0${day}` : day;
  //     const formattedMonth = month < 10 ? `0${month}` : month;

  //     return `${formattedDay}/${formattedMonth}/${year}`;
  //   };

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseEdit = () => setShowModalEdit(false);
  const handleShowEdit = (item) => {
    setShowModalEdit(true);
    setSelectedItem(item);
  };

  const getNameKeywordFunction = async () => {
    try {
      setLoading(true);
      const response = await getNameKeywordsDataAPI();

      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const nameKeywordData = response?.data?.response?.data;

        setOriginalData(nameKeywordData);
        setCurrentPageData(
          nameKeywordData.slice(0, parseInt(dataPerPageButton))
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getNameKeywordFunction();
  }, []);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));

      // Check if filtered data is empty and update state accordingly
      if (filteredData.length === 0) {
        setShowNoDataMessage(true);
      } else {
        setShowNoDataMessage(false);
      }
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  const handleNavigateToViewApplication = (event, item) => {
    event.preventDefault();
    navigate(`/admin/mail_template/name_keywords/edit`, {
      state: { pointerID: item },
    });
  };



  return (
    <>
      <div className="main_desktop">
        {/* <Row>
          <Col>
            <NavigationButtons handleLogout={handleLogout} />
          </Col>
        </Row> */}

        <hr className="mb-2 thick-hr" />

          {/* {loading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )} */}

{loading && (
 <Loader />
      )}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Mail Template Name Keywords
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <div className="row g-0 card-header">
                  <div className="col-2 namekey_btnadd">
                    <button
                      className="btn btn_green_yellow"
                      onClick={handleShow}
                    >
                      + Add
                    </button>
                  </div>
                  <div className="col-10 namekey_btnback">
                    <Link
                      to="/admin/mail_template"
                      className="btn btn_yellow_green float-end"
                    >
                      <i className="bi bi-arrow-left"></i>
                      Back
                    </Link>
                  </div>
                </div>

                <Card.Body>
                  <Row className="my-2 mx-2 align-items-center">
                    <Col lg="2" className="dataTables_name_key ">
                      <div className="dataTables_length">
                        <div className="select-wrapper">
                          <Form.Control
                            as="select"
                            value={dataPerPageButton}
                            onChange={handleDataPerPageChange}
                            className="dataPerPageSelect"
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <FaCaretDown className="select-caret-down" />
                          </Form.Control>
                        </div>
                      </div>
                    </Col>
                    <Col lg="10" className="search_name_key">
                      <Row className="justify-content-end align-items-center">
                        <Col lg="9" className="">
                          <Form.Control
                            type="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearch}
                            className="searchInput"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive table-wrapper">
                            <table className="table table-striped table-hover dataTable no-footer">
                              <thead>
                     







                                <tr>
                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Sr. No.
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    IDs
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "40%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Name
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "45%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Keyword
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Action
                                  </th>
                                </tr>

                              </thead>

                              <tbody>
                                {currentPageData
                                  .slice(0, parseInt(dataPerPageButton))
                                  .map((item, index) => (
                                    <tr
                                      key={index}
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                    >
                                            <td className="td_font_size">
                                        {index + 1}.
                                      </td>

                                      <td className="td_font_size">
                                        {item?.id}
                                      </td>


                                      <td
                                        onClick={(event) =>
                                          handleNavigateToViewApplication(
                                            event,
                                            item
                                          )
                                        }
                                        style={{
                                          // color: "#055837",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        className="hover-underline"
                                      >
                                        {item?.name}
                                      </td>


                                      <td className="td_font_size">
                                        {item?.name_keyword}
                                      </td>

                                      <td className="td_font_size">
                                        <div className="btn-container">
                                          <button
                                            className="btn btn-sm edit"
                                            style={{
                                              backgroundColor: "#055837",
                                              color: "#ffcc01",
                                              marginRight: "8px",
                                              transition:
                                                "background-color 0.2s, color 0.2s",
                                            }}
                                            onClick={() => {
                                              handleShowEdit(item?.id);
                                            }}
                                          >
                                            <i className="bi bi-pencil-square"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pagination-container">
                      <div className="dataTables_info">
                        Showing{" "}
                        {currentPage === 1
                          ? 1
                          : (currentPage - 1) * dataPerPageButton + 1}{" "}
                        to{" "}
                        {Math.min(
                          currentPage * dataPerPageButton,
                          originalData.length
                        )}{" "}
                        of {originalData.length} entries
                      </div>

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          style={
                            currentPage === 1
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Previous
                        </button>

                        <button className="paginate_button">
                          {currentPage}
                        </button>

                        <button
                          className={`paginate_button ${
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? "disabled"
                              : ""
                          }`}
                          onClick={handleNext}
                          disabled={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                          }
                          style={
                            currentPage ===
                            Math.ceil(originalData.length / dataPerPageButton)
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : {}
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Add showModal={showModal} handleClose={handleClose} />
      <EditNameKey
        showModal={showModalEdit}
        handleClose={handleCloseEdit}
        selectedItem={selectedItem}
      />
    </>
  );
};

export default NameKeywords;
