import React, { useState, useEffect } from "react";
import "./SidebarMenu.css";
import { useNavigate } from "react-router-dom";

const SidebarMenu = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let getRoute = window.location.pathname;

  const isActive = (routes) => {
    return routes.includes(getRoute) ? "active-newbar" : "";
  };

  const sideBarnavigation = (url) => {
    navigate(url);
  };

  return (
    <div className="sidebar-wrapper">
      <ul className="sidebar-nav" id="sidebar-nav">





        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/dashboard",
              "/admin/dashboard/view_application",
            ])}`}
            onClick={() => sideBarnavigation("/admin/dashboard")}
          >
            <i className="bi bi-house-door-fill"></i>
            {!isMobile && <span>Dashboard</span>}
          </a>
        </li>






        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/application_manager",
              "/admin/application_manager/view_application",
              "/admin/application_manager/view_application/transfer_applicant",
              "/admin/application_manager/view_application/transfer_applicant/preview"
            ])}`}
            onClick={() => sideBarnavigation("/admin/application_manager")}
          >
            <i className="bi bi-file-earmark-check-fill"></i>
            {!isMobile && <span>Application Manager</span>}
          </a>
        </li>
        





        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/interview_bookings",
            ])}`}
            onClick={() => sideBarnavigation("/admin/interview_bookings")}
          >
            <i className="bi bi-calendar-check-fill"></i>
            {!isMobile && <span>Interview Bookings</span>}
          </a>
        </li>






        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/practical_bookings",
            ])}`}
            onClick={() => sideBarnavigation("/admin/practical_bookings")}
          >
            <i className="bi bi-calendar-check-fill"></i>
            {!isMobile && <span>Practical Bookings</span>}
          </a>
        </li>



        {accountType !== "head_office" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/applicant_agent",
              "/admin/applicant_agent/form",
              "/admin/applicant_agent/company",
            ])}`}
            onClick={() => sideBarnavigation("/admin/applicant_agent")}
          >
            <i className="bi bi-person-check-fill"></i>
            {!isMobile && <span>Applicant / Agent</span>}
          </a>
        </li>
        )}




{accountType === "super_admin" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/staff_management",
           
            ])}`}
            onClick={() => sideBarnavigation("/admin/staff_management")}
          >
            <i className="bi bi-person-fill"></i>
            {!isMobile && <span>Staff Management</span>}
          </a>
        </li>

          )}



{accountType === "super_admin" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/occupation_manager",
            ])}`}
            onClick={() => sideBarnavigation("/admin/occupation_manager")}
          >
            <i className="bi bi-briefcase-fill"></i>
            {!isMobile && <span>Occupation Manager</span>}
          </a>
        </li>
)}



{accountType !== "head_office" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/verification",
              "/admin/verification/referee_details",
              "/admin/verification/RTO_details",
            ])}`}
            onClick={() => sideBarnavigation("/admin/verification")}
          >
            <i className="bi bi-box-arrow-up-right"></i>
            {!isMobile && <span>Verification</span>}
          </a>
        </li>
)}




{accountType !== "head_office" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/archive",
            ])}`}
            onClick={() => sideBarnavigation("/admin/archive")}
          >
            <i className="bi bi-archive-fill"></i>
            {!isMobile && <span>Archive</span>}
          </a>
        </li>
)}




{accountType === "super_admin" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/mail_template",
              "/admin/mail_template/add_mail",
              "/admin/mail_template/name_keywords",
              "/admin/mail_template/edit",
              "/admin/mail_template/name_keywords/edit"
            ])}`}
            onClick={() => sideBarnavigation("/admin/mail_template")}
          >
            <i className="bi bi-envelope-fill"></i>
            {!isMobile && <span>Mail Template</span>}
          </a>
        </li>
)}




{accountType === "super_admin" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/offline_files",
            ])}`}
            onClick={() => sideBarnavigation("/admin/offline_files")}
          >
            <i className="bi bi-file-earmark-check-fill"></i>
            {!isMobile && <span>Offline Files</span>}
          </a>
        </li>

          )}


{accountType !== "head_office" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/admin_functions",
            ])}`}
            onClick={() => sideBarnavigation("/admin/admin_functions")}
          >
            <i className="bi bi-file-earmark-check-fill"></i>
            {!isMobile && <span>Admin Functions</span>}
          </a>
        </li>
)}




{accountType !== "head_office" && (

        <li className="nav-item">
          <a
            className={`nav-link collapsed ${isActive([
              "/admin/locations",
            ])}`}
            onClick={() => sideBarnavigation("/admin/locations")}
          >
            <i className="bi bi-geo-alt-fill"></i>
            {!isMobile && <span>Locations</span>}
          </a>
        </li>
)}








      </ul>
    </div>
  );
};

export default SidebarMenu;
