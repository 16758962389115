import React, { useEffect } from "react";
import axios from "axios";
import { decryptData } from "./components/CRYPTO/crypto";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AQATO_ADMIN_PORTAL_BASE_API_URL,
  timeout: 30000,
});

// Create a new Axios instance without setting the Authorization header
const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_AQATO_ADMIN_PORTAL_BASE_API_URL,
  timeout: 30000,
});

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.status === 200 && response.data === "Missing JWT token") {
      // Re-authorize before retrying
      await authorizeMe();

      // Retry the request with the same config
      const originalRequest = response.config;
      originalRequest.headers["Authorization"] =
        axiosInstance.defaults.headers.common["Authorization"];

      // Return the retried request's response
      return axiosInstance(originalRequest);
    }
    return response;
  },
  (error) => {
    const { navigate } = require("react-router-dom");
    if (
      error?.response &&
      error?.response?.data &&
      error?.response?.data?.message === "Expired token"
    ) {
      toast.error("Time elapsed, Please log in again!");
      console.log("Expired token error....");
      localStorage.removeItem("isAQATO_adminLoggedIn");
      localStorage.removeItem("encryptedTokenForAQATO_Admin");
      localStorage.removeItem("AQATO_SuperAdminName");
      navigate("/");
    }
    return Promise.reject(error);
  }
);

export function authorizeMe() {
  const encryptedTokenForAQATO_Admin = localStorage.getItem(
    "encryptedTokenForAQATO_Admin"
  );

  const token = decryptData(encryptedTokenForAQATO_Admin);

  if (token && token !== null && token !== undefined) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
}

// Intercept requests and authorize them before sending
axiosInstance.interceptors.request.use(async (config) => {
  await authorizeMe();
  return config;
});

// Shreyash API's

export async function LoginAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post("/logincheck_api", data);

    return response;
  } catch (error) {
    throw error;
  }
}
//Locations
export async function getDataInterviewAPI() {
  try {
    const response = await axiosInstance.get(
      "/location/get_all_data_api/interview"
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}
export async function getDataPracticalAPI() {
  try {
    const response = await axiosInstance.get(
      "/location/get_all_data_api/pratical"
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical data:", error);
    throw error;
  }
}

export async function getLocationIndividualAPI(user_id) {
  try {
    const response = await axiosInstance.get(
      `/location/get_single_data_api/${user_id}`
    );

    return response;
  } catch (error) {
    console.error("Error fetching practical data:", error);
    throw error;
  }
}

export async function updateLocationIndividualAPI(data) {
  try {
    const response = await axiosInstance.post(`/location/update_api`, data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllOccupationsAPI() {
  try {
    const response = await axiosInstance.get("/occupation_manager/listing_api");

    return response;
  } catch (error) {
    throw error;
  }
}

// Staff Managment

export async function getDataStaffManagementAPI(type) {
  try {
    const response = await axiosInstance.get(
      "/staff_management/get_listing_data_api/" + type
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function updateStaffApi(data) {
  try {
    const response = await axiosInstance.post(
      "/staff_management/update_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}
export async function insertStaffApi(data) {
  try {
    const response = await axiosInstance.post(
      "/staff_management/insert_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function changeStatusForStaffManagment_Api(id) {
  try {
    const response = await axiosInstance.get(
      `/staff_management/status_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function deleteForStaffManagment_Api(id) {
  try {
    const response = await axiosInstance.get(
      `/staff_management/delete_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function deleteAgentApplicant_Api(formData) {
  try {
    const response = await axiosInstance.post(
      `/applicant_agent/delete_api`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getSingleStaffManagementAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/staff_management/get_single_data_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

// END








export async function getAllMassagesOfNotes(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/notes/fetch_notes_api`,formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}











export async function sendMassagesOfNotes(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/notes/notes_message_send_api`,formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}








export async function deletedMassagesOfNotes(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/notes/for_delete_message_api`,formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}









export async function getAllAgentApplicantAPI(type) {
  try {
    const response = await axiosInstance.get(
      `/applicant_agent/get_all_data_api/${type}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getAllAgentApplicantFormDataAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/applicant_agent/find_contract_details_api`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function sendAgentApplicantFormDataAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/applicant_agent/update_api`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getShowContractDetailsAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/applicant_agent/find_contract_details_api`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function UpdateShowContractDetailsAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/applicant_agent/update_contract_details_api`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

//Verification
export async function getDataEmploymentAPI() {
  try {
    const response = await axiosInstance.get(
      "/verification/get_all_data_api/employment"
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getDataQualificationAPI() {
  try {
    const response = await axiosInstance.get(
      "verification/get_all_data_api/qualification"
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getIndividualVerificationAPI(user_id) {
  try {
    const response = await axiosInstance.get(
      `/verification/view_application_api/${user_id}`
    );

    return response;
  } catch (error) {
    console.error("Error fetching practical data:", error);
    throw error;
  }
}

export async function changeStatusOfRefree(formData) {
  try {
    const response = await axiosInstance.post(
      `/verification/change_status_employment_api`,formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function changeStatusOfRTO(formData) {
  try {
    const response = await axiosInstance.post(
      `/verification/change_status_quali_api`,formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

//Archive

export async function getDataArchiveAPI() {
  try {
    const response = await axiosInstance.get("/archive/get_all_data");
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

// Offline Files

export async function getDataSAQAPI(param) {
  try {
    const response = await axiosInstance.get(
      `/offline_files/get_all_data_api/${param}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function getDeleteOFflineFileData(id) {
  try {
    const response = await axiosInstance.post(
      `/offline_files/delete_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

//Mail TEmplate

export async function getMailTemplateDataAPI() {
  try {
    const response = await axiosInstance.get(
      "/mail_template/get_mail_template_data_api"
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}
export async function getNameKeywordsDataAPI() {
  try {
    const response = await axiosInstance.get(
      "/mail_template/get_name_keywords_data_api"
    );
    return response;
  } catch (error) {
    console.error("Error fetching verification data:", error);
    throw error;
  }
}

export async function addMailTemplate(data) {
  try {
    const response = await axiosInstance.post(
      "/mail_template/add_template_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function addNameKeywordTemplate(data) {
  try {
    const response = await axiosInstance.post(
      "/mail_template/add_name_keyword_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postAgentApplicantCompanyRecodsData_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/common_get_admin_side_pagination_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

// Application Manager
export async function postApplicationManagerRecodsData_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/common_get_admin_side_pagination_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDashboardRecodsData_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/common_get_admin_dashboard_data_pagination_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getFlagRecordsOfApplicationManager_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/flag/show_data_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getNoteRecordsOfApplicationManager_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/comment_store_documents/show_s1_s2_s3_comments_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteSingleFlagRecordOfApplicationManager_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/flag/delete_single_request_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteSingleCommentNoteOfApplicationManager_API(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/comment_store_documents/delete_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function SaveSingleCommentNoteOfApplicationManager_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/comment_store_documents/request_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteCompleteFlagRecordOfApplicationManager_API(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/flag/delete_all_request_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteCompleteCommentOfApplicationManager_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/comment_store_documents/show_s1_s2_s3_comments_all_delete_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteStageCommentOfApplicationManager_API(formData) {
  try {
    const response = await axiosInstance.post(
      `/comment_store_documents/show_s1_s2_s3_comments_single_delete_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllViewUpdateOfAppManager(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/view_update/show_all_data_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllViewUpdateNotesCountOfAppManager(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/application_manager/notes/chat_system_count_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDataForTransferApplicationForm(pointerID) {
  try {
    const response = await axiosInstance.get(
      `/application_transfer/get_application_transfer_data_api/${pointerID}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDataForTransferApplicationPreviewForm(
  pointerID,
  selectedOption
) {
  try {
    const response = await axiosInstance.get(
      `/application_transfer/create_new_trf_file_api/${pointerID}/${selectedOption}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDataForTransferApplicationForm(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_transfer/file_Upload_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDataFinalSubmissionForTransferApplicationForm(
  pointerID,
  selectedOption
) {
  try {
    const response = await axiosInstance.get(
      `/application_transfer/complete_transfer_submit_api/${pointerID}/${selectedOption}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postEditFileOfSubmittedApplicationsOfViewUpdateApplicationForm(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/update_file_submitted_application_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDeleteInformationOfSubmittedApplicationsOfViewUpdateApplicationForm(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/delete_file_submitted_application_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDeleteStageSubmittedApplicationsOfViewUpdateApplicationForm(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/delete_stage_2_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSaveReminderDateApplicationManager(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/view_update/change_the_status_followup_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSaveSingleFieldOfViewUpdateOfApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/view_update/change_the_anything_data_in_view_update_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postUpdateStageStatusOfDataViewUpdateOfApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/send_mail/all_stage_change_status`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postUploadSingleFileForMailOfVerificationQualificationApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/upload_qualification_verification_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postUploadSingleFileForMailOfRTOApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/upload_qualification_verification_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postRemoveFileForMailOfVerificationQualificationApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/delete_qualification_verification_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postRemoveFileForMailOfRTOApplicationManager(formData) {
  try {
    const response = await axiosInstance.post(
      `/verification/delete_extra_docs_qualification_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSendAllDataForMailOfVerificationQualificationApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/qualification_verification_form_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSendAllDataForMailOfRTOApplicationManager(formData) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/uhiewfukhewbaufyation_form_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSendAllDataForMailOfRefreePopupApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/verification/submit_popup_employment_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSendNodataForMailOfVerificationQualificationApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/send_email_employ_stage_2_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postAddiotionalRequestInformationForSubmittedApplicationsOfApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/request_replace_file_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postRequestAgainTRAForSubmittedApplicationsOfApplicationManager(
  pointerID
) {
  try {
    const response = await axiosInstance.get(
      `/application_transfer/regenerate_tra_form_api/${pointerID}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postSubmitVerificationQualificationDocumentForSubmittedApplicationsOfApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/verify_qualification_email_stage_1_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postDownoloadZipFileForSubmittedApplicationsOfApplicationManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/download_zip_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllSubmittedApplicationsOfAppManager(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/application_manager/submitted_documents/show_all_docs_data_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataForQualificationVerificationMailAppManager(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/application_manager/submitted_documents/get_qualification_verification_data_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataForRTOMailAppManager(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/verification/fetch_qualification_popup_data_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataForRefreeDetailsPopupMailAppManager(
  id,
  selectedItemID
) {
  try {
    const response = await axiosInstance.get(
      `/verification/fetch_employment_popup_data_api/${id}/${selectedItemID}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllUploadedFilesForQualificationVerificationMailAppManager(
  formData
) {
  try {
    const response = await axiosInstance.post(
      `/application_manager/submitted_documents/check_extra_files_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllUploadedFilesForRTOMailAppManager(id) {
  try {
    const response = await axiosInstance.get(
      `/verification/fetch_extra_docs_qualification_popup_api/${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

//============================================================================

export async function LoginAPIIIIIIIi(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/user_login_check",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function forgotPasswordAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/user_Forgot_Password_check_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createNewAccountAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/create_an_account",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateProfileAPI(data) {
  try {
    const response = await axiosInstance.post("/user/account_update_", data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updatePasswordAPI(data) {
  try {
    const response = await axiosInstanceNoAuth.post(
      "/user/account_update_pass_reset",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function dashboardDataAPI() {
  try {
    const response = await axiosInstance.get("/user/get_dashboard_data_api");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getUserDataAPI(user_id) {
  try {
    const response = await axiosInstance.get(
      `/user/get_user_details_api/${user_id}`
    );

    if (response.status === 200 && response.data === "Missing JWT token") {
      getUserDataAPI(user_id);
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export async function creatwNewApplicationIncreasedAPI() {
  try {
    const response = await axiosInstance.get(
      "/user/create_new_application_api"
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function checkFillAllTheDetailsDataAPI() {
  try {
    const response = await axiosInstance.get(
      "/user/check_fill_all_the_details_api"
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllCountryDataAPI() {
  try {
    const response = await axiosInstanceNoAuth.get("/get_country");

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createOccupationDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_occupation_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getOccupationDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/occupation/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createPersonalDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_personal_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPersonalDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/personal_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createContactDetails_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_contact_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getContactDetails_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/contact_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createIdentification_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_identification_details_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIdentification_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/identification_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createUSIandAvetmiss_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_usi_avetmiss__api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getUSIandAvetmiss_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/usi_avetmiss/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function createEducationAndEmployment_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1_employment_details__api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEducationAndEmployment_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/education_employment_details/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getReviewAndConfirm_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/stage_1_application_preview_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postReviewAndConfirm_stage1_Application_PDF_API(data) {
  try {
    const response = await axiosInstance.post(`/user/pdf_html_code_api`, data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getApplicantDeclaration_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_1/stage_1_applicant_declaration_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postApplicantDeclaration_Files_by_parameter_stage1_API(
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/pdf_Download_check_api`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage1_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_1_by_pointer_id/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage2_Assessment_API(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/verify_docs_assessment/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getuploadedDocumentsList_stage2_Employment_API(
  pointer_id,
  employer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/verify_docs_employer/${pointer_id}/${employer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploaadDocuments_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1/stage_1_upload_documents_api",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploaad_Emplyment_Documents_stage2_API(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/employe_document_multiple_upload_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function upload_Assessment_Documents_stage2_API(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/assessment_documents_multiple_upload_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocument_stage1_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_1/delet_file_",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocumentAPI(pointer_id, docId) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/delete_documents_common/${pointer_id}/${docId}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetails_stage1_API(
  pointer_id,
  required_document_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_1_by_pointer/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetailsAPI(pointer_id, required_document_id) {
  try {
    const response = await axiosInstance.get(
      `/user/get_document_by_id/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getForms_PDF_URL__API(data) {
  try {
    const response = await axiosInstance.post("/user/download_Form", data);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIncomplete_Application_API() {
  try {
    const response = await axiosInstance.get(
      `/user/incomplete_application_api`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getOccupationManager_API() {
  try {
    const response = await axiosInstance.get(`/occupation_manager/listing_api`);

    return response;
  } catch (error) {
    throw error;
  }
}

export async function AddOccupationAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/occupation_manager/insert_occupation_manager_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function EditOccupationAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/occupation_manager/update_occupation_manager_api`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function DeleteOccupationAPI(formData) {
  try {
    const response = await axiosInstance.post(
      `/occupation_manager/deleteeeeeeeeeeeeeeeeeeeeeeeeeeee`,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function ChangeStatusOccupationAPI(id) {
  try {
    const response = await axiosInstance.get(
      `/occupation_manager/status_popup_data_change_occupation_manager_api/${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSingleOccupationAPI(id) {
  try {
    const response = await axiosInstance.get(
      `/occupation_manager/edit_data_fetch_occupation_manager_api/${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteIncomplete_Application_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/incomplete_application_delete/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getIncomplete_Application_path_for_routing_API(
  pointer_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/incomplete_application_route_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSubmitted_Application_API() {
  try {
    const response = await axiosInstance.get(
      `/user/submitted_applications_api`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_ViewApplications_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/view_application/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_add_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_add_employment_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_update_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_edite_employment_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getEmploymentDetailsForTable_stage2_API(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/add_employment_document_page_api/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function get_One_EmploymentDetailsForTable_stage2_API(
  pointer_id,
  employee_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_2/get_single_employer/${pointer_id}/${employee_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_delete_employment_details(data, pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2_delete_employe_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLocationsForStage_4(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/receipt_upload/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchLocationsForStage_3(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/get_all_preferred_location/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function fetchAddressDetailsByAPIByCityname(data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/get_address`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function submitLocationOfStage_4(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/save_Preferred_location/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function submitLocationOfStage_3(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/save_preferred_location/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataOfStage_3_ByPointer_ID(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/receipt_get_all_data/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllDataOfStage_4_ByPointer_ID(pointer_id) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_4/receipt_get_all_data/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetails_stage4_API(
  pointer_id,
  required_document_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_444444444444444444444444444444444444_by_pointer/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentsDetails_stage3_API(
  pointer_id,
  required_document_id
) {
  try {
    const response = await axiosInstance.get(
      `/user/get_documents_stage_333333333333333333333333333333333333333333333333_by_pointer/${pointer_id}/${required_document_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocument_stage4_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_4444444444444444444444444/delet_file_",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocument_stage3_API(data) {
  try {
    const response = await axiosInstance.post(
      "/user/stage_33333/delet_file_",
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocuments_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/upload_tra_payment_receipt/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function uploadDocuments_stage3_API(
  pointer_id,
  required_document_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/upload_document_file/${pointer_id}/${required_document_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_Practical_Interview_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/save_Preferred_info_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_FinalSubmission_Of_stage4_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_4/submit_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_FinalSubmission_Of_stage3_API(pointer_id, data) {
  try {
    const response = await axiosInstance.get(
      `/user/stage_3/submit_/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_submissionOf_Terms_Yes_Or_No_Of_stage3_API(
  pointer_id,
  data
) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/store_yes_no/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function post_Technical_Interview_stage3_API(pointer_id, data) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_3/save_stage_3_basic_info/${pointer_id}`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_organisations_names(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/get_employment_document_list_/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_assessments_docs_names(pointer_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/assessment_documents_list_/${pointer_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function stage2_get_employment_docs_names(pointer_id, employe_id) {
  try {
    const response = await axiosInstance.post(
      `/user/stage_2/get_employment_documents_data/${pointer_id}/${employe_id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAQUATOInterviewBookingsAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/fetch_application_records_interview_booking_api",
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAllApplicantsAPI() {
  try {
    const response = await axiosInstance.get(
      "/interview_booking/insert_popup_data_show_api"
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postNewAQATO_InterviewBookings(formData) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/insert_aqato_booking_api",
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function getSingleRescheduleAPI(data) {
  try {
    const response = await axiosInstance.get(
      `/interview_booking/get_aqato_interview_booking_data_api/${data}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function getPreferenceLocation(data) {
  try {
    const response = await axiosInstance.post(
      `/interview_booking/get_preference_location_api`,
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function getSingleZoomDetailAPI(id) {
  try {
    const response = await axiosInstance.get(
      `/interview_booking/get_zoom_details_popup_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function getSingleZoomDetailNonAQATOAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/not_aqato_s3/non_aqato_cancel_booking_zoom_details_cancel_data_api`,
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function getDropdownOptionsDetailNonAQATOAPI() {
  try {
    const response = await axiosInstance.get(
      `/not_aqato_s3/insert_popup_data_show_api`
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postSubmitZoomDetailsAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/send_mail_zoom_meet_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postSubmitZoomDetailsNonAQATOAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/not_aqato_s3/send_mail_non_aqato_zoom_meet_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postSubmitReassesmentZoomDetailsAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/send_mail_zoom_meet_reassessment_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postRescheduleInterviewAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/update_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching interview data:", error);
    throw error;
  }
}

export async function postCancelInterviewAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/interview_booking_cancle_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error while canceling interview:", error);
  }
}

export async function postCancelNonAqatoInterviewAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/not_aqato_s3/non_aquato_interview_booking_cancle_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error while canceling interview:", error);
  }
}

export async function postCancelAQATO_ReassesmentInterviewAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/stage3_reass_interview_booking_cancle_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error while canceling interview:", error);
  }
}

//Interview Booking
//Non Aquato
export async function postNonAquatoInterviewBooking(data) {
  try {
    const response = await axiosInstance.post(
      "/not_aqato_s3/insert_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while creating new Intervview Booking ", error);
  }
}

export async function getNonAquatoInterviewBookingsAPI() {
  try {
    const response = await axiosInstance.get(
      "/not_aqato_s3/not_aqato_fetching_data_api"
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getNonAqatoZoomDetailsByIDAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/not_aqato_s3/non_aqato_cancel_booking_zoom_details_cancel_data_api`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Zoom Details", error);
  }
}

export async function submitNonAqatoZoomDetailsAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/not_aqato_s3/send_mail_non_aqato_zoom_meet_api`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error while submitting Zoom Details", error);
  }
}

export async function getCancelNonAqatoZoomInfoAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/not_aqato_s3/non_aquato_interview_booking_cancle_dropdown_data_api`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Zoom Details", error);
  }
}

export async function postCancelNonAqatoZoomMeetAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/not_aqato_s3/non_aquato_interview_booking_cancle_api`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error while canceling Zoom Meet", error);
  }
}

//Interview Booking
//Aqato Reassesment

export async function getAllAqatoReassesmentDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/interview_booking_reassesment_all_data_fetch_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getInsertAqatoReassesmentAPI() {
  try {
    const response = await axiosInstance.get(
      "/interview_booking_reassessment/insert_popup_data_in_reassement_interview_booking_api"
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function submitNewAqatoReassesmentAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/insert_booking_reassessment_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while inserting new Reassesment Bookings", error);
  }
}

export async function getRescheduleReassesmentDatabyIDAPI(data) {
  try {
    const response = await axiosInstance.get(
      `/interview_booking_reassessment/get_popup_interview_booking_reassesment_data_api/${data}`
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function submitResceduleReassesmentDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/update_booking_reassessment_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getZoomDetailsReassesmentbyIdAPI(data) {
  try {
    const response = await axiosInstance.get(
      `/interview_booking_reassessment/interview_booking_reassessment_zoom_details_data_fetch_api/${data}`
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function submitZoomDetailsReassesmentAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking_reassessment/send_mail_zoom_meet_reassessment_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getCancelReassesmentFormAPI(data) {
  try {
    const response = await axiosInstance.post(
      `/interview_booking_reassessment/cancel_interview_booking_reassesment__dropdown_data_api`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

//Occupation Manager
export async function getOccupationManagerAPI() {
  try {
    const response = await axiosInstance.get("/occupation_manager/listing_api");
    return response;
  } catch (error) {
    console.error("Error fetching occupation manager", error);
    throw error;
  }
}

// Practical Booking
export async function getPracticalBookingDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/practical_booking/fetch_application_records_practical_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function postNewPracticalBookingAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/practical_booking/insert_practical_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function cancelPracticalBookingAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/practical_booking/practical_booking_cancle_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function getPracticalBookingDataPopupForSelectListAPI() {
  try {
    const response = await axiosInstance.get(
      "/practical_booking/insert_popup_data_in_practical_booking_api"
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function getResceduleAQATOInterviewBookingDataAPI(id) {
  try {
    const response = await axiosInstance.get(
      `/interview_booking/get_aqato_interview_booking_data_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function getRescedulePracticalBookingDataAPI(id) {
  try {
    const response = await axiosInstance.get(
      `/practical_booking/get_aqato_pratical_booking_data_api/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function submitPracticalBookingDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/practical_booking/update_practical_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

export async function updateAQATOInterviewBookingDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/interview_booking/update_booking_api",
      data
    );
    return response;
  } catch (error) {
    console.error("Error fetching practical booking data:", error);
    throw error;
  }
}

// Admin Function
//Reporting
export async function getReportingMenuAPI() {
  try {
    const response = await axiosInstance.get(
      "/admin_functions/reporting/get_reporting_menu_data_api"
    );
    return response;
  } catch (error) {
    console.error("Error fetching admin function", error);
    throw error;
  }
}

export async function getReportingDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/reporting/fetch_application_records_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getReferralFeesDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/referral_fees/fetch_application_records__accounting_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function AddEditSAQ_OfOfflineFilesAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/offline_files/insert_update_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function getSingleSAQ_OfOfflineFilesAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/offline_files/get_single_data_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function getOffShoreFeesDataAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/offshore/fetch_application_records_offshore_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function getStageWiseStatusAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/reporting/get_stage_wise_menu_data_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while fetching Interview Bookings", error);
  }
}

export async function downloadPDFReportingAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/reporting/download_report_pdf_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function downloadPDFReferralAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/referral_fees/download_report_pdf__accounting_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function downloadPDFOffShoreAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/offshore/download_report_pdf__offshore_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function downloadExcelReportingAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/reporting/download_report_spreadsheet_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function downloadExcelReferralAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/referral_fees/download_report_spreadsheet__accounting_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

export async function downloadExcelOffShoreAPI(data) {
  try {
    const response = await axiosInstance.post(
      "/admin_functions/accounting/offshore/download_report_spreadsheet__offshore_api",
      data
    );
    return response;
  } catch (error) {
    console.log("Error while downloading PDF", error);
  }
}

const App = () => {
  useEffect(() => {
    authorizeMe();
  }, []);

  return <></>;
};

export default App;
export { axiosInstance, axiosInstanceNoAuth };
