import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Widgets/Loader/Loader";
import VerificationModal from "../VerificationModal/VerificationModal";
import {
  getAllApplicantsAPI,
  getResceduleAQATOInterviewBookingDataAPI,
  updateAQATOInterviewBookingDataAPI,
} from "../../../../api";

const Reschedule = ({
  showModal,
  handleClose,
  selectedItem,
  fetchPracticalBookingData,
}) => {
  const [loading, setLoading] = useState(false);

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = () => {
    setDeleteAccountShow(true);
  };

  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [headText, setHeadText] = useState("");
  const [selectedZone, setSelectedZone] = useState("");

  const [ApplicantsList, setApplicantsList] = useState([]);
  const [LocationsByAPI, setLocationsByAPI] = useState([]);
  const [TimeList, setTimeList] = useState([]);
  const [timezones, setTimeZones] = useState([]);

  const today = new Date().toISOString().split("T")[0];

  const getDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getAllApplicantsAPI();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setLocationsByAPI(response?.data?.response?.data?.venues);
        setApplicantsList(response?.data?.response?.data?.lodged_users);
        setTimeList(response?.data?.response?.data?.time_options);
        setTimeZones(response?.data?.response?.data?.time_zone_data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    if (showModal) {
      getDataFunction();
    }
  }, [showModal]);

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const getRescedulePracticalBookingDataFunction = async () => {
    try {
      setLoading(true);
      const response = await getResceduleAQATOInterviewBookingDataAPI(
        selectedItem?.id
      );
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const details = response?.data?.response?.data;
        setHeadText(details?.heading_text);
        setLocationsByAPI(details?.booking_form?.venues);
        setTimeList(details?.booking_form?.time_options);
        setTimeZones(details?.booking_form?.time_zone_data);

        setSelectedVenue(details?.booking_form?.venue);
        setSelectedDate(formatDate(details?.booking_form?.date));
        setSelectedTime(details?.booking_form?.time);
        setSelectedZone(details?.booking_form?.applicant_location);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((selectedItem, showModal)) {
      getRescedulePracticalBookingDataFunction();
    }
  }, [selectedItem, selectedItem]);

  const handleVenueChange = (event) => {
    setSelectedVenue(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleApplicantChange = (event) => {
    setSelectedApplicant(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  const handleSubmitCheck = () => {
    if (
      !headText ||
      !selectedVenue ||
      !selectedDate ||
      !selectedTime ||
      (selectedVenue === "9" && !selectedZone)
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    handleDeleteAccountShow();
  };

  const handleFakeSubmit = async (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    // event.preventDefault();

    if (
      !headText ||
      !selectedVenue ||
      !selectedDate ||
      !selectedTime ||
      (selectedVenue === "9" && !selectedZone)
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("applicant_name", headText);
      formData.append("stage_3_interview_booking_id", selectedItem?.id);
      formData.append("venue", selectedVenue);
      formData.append("date", selectedDate);
      formData.append("time", selectedTime);
      formData.append("time_zone", selectedZone);
      formData.append("pointer_id", selectedItem?.pointer_id);

      const response = await updateAQATOInterviewBookingDataAPI(formData);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // Clear form fields
        setSelectedVenue("");
        setSelectedDate("");
        setSelectedApplicant("");
        setSelectedTime("");
        setHeadText("");
        toast.success(
          response?.data?.response?.success_msg ||
            "Interview Booking updated successfully."
        );
        fetchPracticalBookingData();
        handleClose();
        handleDeleteAccountClose();
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Unknown Error."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal show={showModal} onHide={handleClose} animation={false} centered>
        <form onSubmit={handleFakeSubmit}>
          <Modal.Header>
            <Modal.Title className="modal-title text-center text-green">
              <h5>Reschedule Interview Booking</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className="form-group">
              <b>
                <label htmlFor="select_location_new">
                  Applicant <span className="text-danger">*</span>
                </label>
              </b>
              <select
                name="applicant_name_id"
                id="select_location_new"
                className="form-select md-4"
                value={selectedApplicant}
                onChange={handleApplicantChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option style={{ cursor: "pointer" }} disabled value="">
                  Select Applicant
                </option>
                {ApplicantsList.map((applicant) => (
                  <option
                    key={applicant.stage_4_id}
                    value={applicant.stage_4_id}
                    style={{ cursor: "pointer" }}
                  >
                    {applicant.fullname}
                  </option>
                ))}
              </select>
            </div> */}

            <h5 className="modal-title text-center text-green">{headText}</h5>

            <div className="form-group">
              <label
                htmlFor="venue-select"
                className="form-label label-font-size"
              >
                <b>
                  Venue <span className="text-danger">*</span>{" "}
                </b>
              </label>
              <select
                name="venue"
                id="venue-select"
                className="form-select"
                value={selectedVenue}
                onChange={handleVenueChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option
                  style={{ cursor: "pointer" }}
                  className="dropdown-options"
                  value=""
                  disabled
                >
                  Select Location
                </option>
                {LocationsByAPI.map((countryObj) =>
                  Object.entries(countryObj).map(([country, locations]) => (
                    <optgroup
                      key={country}
                      label={country}
                      style={{ fontWeight: "bold" }}
                    >
                      {locations.map((location) => (
                        <option
                          key={location.venue_id}
                          value={location.venue_id}
                          style={{ cursor: "pointer" }}
                        >
                          {location.venue_name}
                        </option>
                      ))}
                    </optgroup>
                  ))
                )}
              </select>
            </div>

            {selectedVenue === "9" && (
              <div className="form-group">
                <label
                  htmlFor="AppLocation"
                  className="form-label label-font-size"
                >
                  <b>
                    Applicant Location <span className="text-danger">*</span>
                  </b>
                </label>

                <select
                  name="applicantLocation"
                  id="AppLocationZone"
                  className="form-select md-4"
                  value={selectedZone}
                  onChange={handleZoneChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option style={{ cursor: "pointer" }} disabled value="">
                    Select Time Zone
                  </option>
                  {timezones.map((zn) => (
                    <option
                      key={zn.zone_name}
                      value={zn.zone_name}
                      style={{ cursor: "pointer" }}
                    >
                      {zn.zone_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="date" className="form-label label-font-size">
                <b>
                  Date <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="date"
                type="date"
                className="form-control"
                value={selectedDate}
                onChange={handleDateChange}
                min={selectedDate}
                required
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="form-group">
              <b>
                <label htmlFor="select_location_new">
                  Time (QLD) <span className="text-danger">*</span>
                </label>
              </b>
              <select
                name="applicant_name_id"
                id="select_location_new"
                className="form-select md-4"
                value={selectedTime} // This binds the selected time
                onChange={handleTimeChange} // Handle change
                required
                style={{ cursor: "pointer" }}
              >
                <option style={{ cursor: "pointer" }} disabled value="">
                  Select Time
                </option>
                {TimeList.map((tm) => (
                  <option
                    style={{ cursor: "pointer" }}
                    key={tm.time_start}
                    value={tm.time_start}
                  >
                    {tm.time_start}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn_yellow_green"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="btn_modal"
              variant="primary"
              // onClick={handleSubmit}
              onClick={handleSubmitCheck}
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              type="submit"
            >
              Save & Reschedule
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {showDeleteAccountModal && showModal && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={handleDeleteAccountClose}
          onYes={handleSubmit}
          Title="Confirm Reschedule!"
          Message={
            <>
              Are you sure you want to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                &nbsp; Reschedule Interview Booking
              </span>
              &nbsp; ?
            </>
          }
        />
      )}
    </>
  );
};

export default Reschedule;
