import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import Agent from "./Agent/Agent";
import Applicant from "./Applicant/Applicant";

const Applicant_Agent = () => {
  const location = useLocation();
  const IsAppplicantTab = location.state?.IsAppplicantTab;

  const [localIsAppplicantTab, setLocalIsAppplicantTab] =
    useState(IsAppplicantTab);
  useEffect(() => {
    if (localIsAppplicantTab) {
      setAgentTabActive(false);
      setApplicantTabActive(true);
    }
  }, []);

  const [AgentTabActive, setAgentTabActive] = useState(true);
  const [ApplicantTabActive, setApplicantTabActive] = useState(false);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Applicant Agent
              </h3>
            </div>

            <div className="card card-primary card-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul className="nav nav-pills nav-fill">
                  <li
                    className="p-1 nav-item"
                    style={{ marginTop: "0px", cursor: "pointer" }}
                  >
                    <a
                      className={`text-center tab shadow-sm text-color nav_top_bar ${
                        AgentTabActive ? "bg-green text-yellow" : ""
                      }`}
                      id="tabs_view_edit"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_view_edit"
                      aria-selected={AgentTabActive ? "true" : "false"}
                      onClick={() => {
                        setAgentTabActive(true);
                        setApplicantTabActive(false);
                        setLocalIsAppplicantTab(false);
                      }}
                    >
                      <h6 className="h6">Agent</h6>
                    </a>
                  </li>
                  <li
                    className="p-1 nav-item custom_fix_top_margin"
                    style={{ cursor: "pointer" }}
                  >
                    <a
                      className={`text-center tab shadow-sm text-color nav_top_bar ${
                        ApplicantTabActive ? "bg-green text-yellow" : ""
                      }`}
                      id="tabs_documents"
                      // href="#"
                      role="tab"
                      aria-controls="tabs_documents"
                      aria-selected={ApplicantTabActive ? "true" : "false"}
                      onClick={() => {
                        setAgentTabActive(false);
                        setApplicantTabActive(true);
                      }}
                    >
                      <h6 className="h6">Applicant</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>


            {AgentTabActive && <Agent />}
            {ApplicantTabActive && <Applicant />}



          </div>
        </div>
      </div>
    </>
  );
};

export default Applicant_Agent;
