import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFlagRecordsOfApplicationManager_API,
  deleteSingleFlagRecordOfApplicationManager_API,
  deleteCompleteFlagRecordOfApplicationManager_API,
} from "../../../api";
import Loader from "../../Widgets/Loader/Loader";

const AdditionalInformationPopup = ({
  show,
  onClose,
  selectedItem,
  ApplicationManagerFetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const pointerID = selectedItem?.id;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [WholeApiData, setWholeApiData] = useState({});
  const [choosedSingleItem, setchoosedSingleItem] = useState(null);

  const [IsChoosedSingleItem, setIsChoosedSingleItem] = useState(false);

  const handleDeleteRequest = (item) => {
    setShowConfirmModal(true);
    setchoosedSingleItem(item);
    if (item !== null) {
      setIsChoosedSingleItem(true);
    } else {
      setIsChoosedSingleItem(false);
    }
  };

  const handleConfirmDeleteSingle = async () => {
    const formData = new FormData();
    formData.append("flag_id", choosedSingleItem?.flag_id);
    formData.append("pointer_id", pointerID);

    try {
      setLoading(true);
      setIsChoosedSingleItem(false);

      const response = await deleteSingleFlagRecordOfApplicationManager_API(
        formData
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setShowConfirmModal(false);
        // onClose();
        getPopUpDataFunction(pointerID);
        ApplicationManagerFetch();
        setIsChoosedSingleItem(false);
        setchoosedSingleItem(null);
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error in deleting",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting", error);
    }
  };

  const handleConfirmDeleteCompleteRequest = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);

    try {
      setLoading(true);
      setIsChoosedSingleItem(false);

      const response = await deleteCompleteFlagRecordOfApplicationManager_API(
        formData
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setShowConfirmModal(false);
        onClose();
        getPopUpDataFunction(pointerID);
        ApplicationManagerFetch();
        setIsChoosedSingleItem(false);
        setchoosedSingleItem(null);
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );
      } else {
        setLoading(false);
        console.error(
          "Error in deleting",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting", error);
    }
  };

  const getPopUpDataFunction = async (pointerID) => {
    try {
      // setLoading(true);

      const response = await getFlagRecordsOfApplicationManager_API(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setWholeApiData(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching records:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching records:", error);
    }
  };

  useEffect(() => {
    if (pointerID && show) {
      getPopUpDataFunction(pointerID);
    }
  }, [pointerID, show]);

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onClose}
        animation={false}
        backdrop="static"
        centered
        size="xl"
        className={showConfirmModal ? "blur-background" : ""}
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title
            style={{ color: "#055837", cursor: "default" }}
            className="modal-title"
          >
            {WholeApiData?.heading_text}
          </Modal.Title>

          {WholeApiData?.delete_all_request_btn && (
            <button
              className="btn-danger ms-1"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
                transition: "transform 0.1s ease-in-out",
              }}
              onClick={() =>
                handleDeleteRequest(null) && setIsChoosedSingleItem(false)
              }
              onMouseEnter={(e) => {
                const icon = e.currentTarget.querySelector("svg");
                if (icon) {
                  icon.style.transform = "scale(1.4)";
                  icon.style.transition = "transform 0.1s ease-in-out";
                }
              }}
              onMouseLeave={(e) => {
                const icon = e.currentTarget.querySelector("svg");
                if (icon) {
                  icon.style.transform = "scale(1)";
                }
              }}
            >
              <FaTrash style={{ marginRight: "8px" }} /> Delete Request
            </button>
          )}
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              overflowX: "auto",
            }}
          >
            <table
              className="table table-striped table-bordered"
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
                border: "0.5px solid #fecc00",
                cursor: "default",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={6}
                    style={{
                      textAlign: "center",
                      backgroundColor: "#f8f9fa",
                      color: "#055837",
                      fontSize: "20px",
                    }}
                  >
                    {WholeApiData?.profile_full_text}
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "5%", padding: "8px" }}>Sr.</th>
                  <th style={{ width: "35%", padding: "8px" }}>Comments</th>
                  <th style={{ width: "25%", padding: "8px" }}>
                    Document Name
                  </th>
                  <th style={{ width: "15%", padding: "8px" }}>
                    Date Requested
                  </th>
                  <th style={{ width: "15%", padding: "8px" }}>
                    Date Received
                  </th>
                  <th style={{ width: "5%", padding: "8px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {WholeApiData?.docs?.length > 0 ? (
                  WholeApiData.docs.map((item, index) => (
                    <tr key={index} style={{ textAlign: "left" }}>
                      <td style={{ padding: "8px" }}>{index + 1}</td>
                      <td style={{ padding: "8px" }}>{item?.comments}</td>

                      <td style={{ padding: "8px" }}>
                        <a
                          href={item?.document_link || "#"}
                          target={item?.document_link ? "_blank" : "_self"}
                          rel={item?.document_link ? "noopener noreferrer" : ""}
                          style={{
                            color: item?.document_link ? "blue" : "gray",
                            textDecoration: item?.document_link
                              ? hoveredLink === index
                                ? "underline"
                                : "none"
                              : "none",
                            cursor: item?.document_link ? "pointer" : "default",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            if (!item?.document_link) {
                              e.preventDefault();
                            }
                          }}
                          onMouseEnter={() =>
                            item?.document_link && setHoveredLink(index)
                          }
                          onMouseLeave={() =>
                            item?.document_link && setHoveredLink(null)
                          }
                        >
                          {item?.document_name}
                        </a>
                      </td>

                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item?.date_requested}
                      </td>
                      <td style={{ padding: "8px", textAlign: "left" }}>
                        {item?.date_received}
                      </td>
                      <td style={{ padding: "8px", textAlign: "center" }}>
                        <Button
                          disabled={item?.delete_btn}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: 0,
                            cursor: item?.delete_btn
                              ? "not-allowed"
                              : "pointer",
                            outline: "none",
                          }}
                          // onClick={() => handleDeleteRequest(item)}
                          onClick={() =>
                            handleDeleteRequest(item) &&
                            setIsChoosedSingleItem(true)
                          }
                        >
                          <FaTrash
                            style={{
                              color: item?.delete_btn ? "black" : "red",
                              transition: "transform 0.1s ease-in-out",
                            }}
                            onMouseEnter={(e) =>
                              !item?.delete_btn &&
                              (e.currentTarget.style.transform = "scale(1.4)")
                            }
                            onMouseLeave={(e) =>
                              !item?.delete_btn &&
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn_modal"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
        backdrop="static"
        dialogClassName="custom-modal-width"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.6)",
          zIndex: 99999999,
          display: showConfirmModal ? "block" : "none",
          cursor: "default",
        }}
      >
        {loading && <Loader />}

        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{" "}
          {choosedSingleItem && IsChoosedSingleItem ? (
            <span
              style={{ color: "red", fontWeight: "bold", cursor: "default" }}
            >
              {choosedSingleItem?.document_name}
            </span>
          ) : (
            <span
              style={{ color: "red", fontWeight: "bold", cursor: "default" }}
            >
              complete request
            </span>
          )}{" "}
          ?
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() =>
              setShowConfirmModal(false) && setIsChoosedSingleItem(false)
            }
            style={{
              padding: "7px 20px",
              borderRadius: "6px",
              border: "none",
            }}
          >
            No
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              if (IsChoosedSingleItem) {
                handleConfirmDeleteSingle();
              } else {
                handleConfirmDeleteCompleteRequest();
              }
            }}
            style={{
              padding: "7px 20px",
              borderRadius: "6px",
              border: "none",
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AdditionalInformationPopup;
