import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

// const AddApplicantKit = () => {
const EditNameKey = ({ showModal, handleClose, selectedItem }) => {
  console.log(selectedItem);
  const [formValues, setFormValues] = useState({
    name_keyword: selectedItem?.applicant_number,
    name: selectedItem?.first_or_given_name,
  });

  useEffect(() => {
    if (selectedItem) {
      setFormValues((prevValues) => ({
        ...prevValues,
        name_keyword: selectedItem?.applicant_number,
        name: selectedItem?.first_or_given_name,
      }));
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Values:", formValues);
    handleClose();
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="model_mannual"
        centered
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Edit Name Keywords</h4>
          </div>
          <div class="modal-body">
            <form action="" onSubmit={handleSubmit} class="add_data">
              <div class="row">
                <div class="col-6">
                  <label>
                    Keyword
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name_keyword"
                    defaultValue={selectedItem?.applicant_number}
                    onChange={handleInputChange}
                    class="form-control md-2"
                    required
                  />
                </div>
                <div class="col-6">
                  <label>
                    Name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    defaultValue={selectedItem?.first_or_given_name}
                    onChange={handleInputChange}
                    class="form-control md-2"
                    required
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="text-end">
                  <button type="submit" class="btn btn_green_yellow mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    class="btn btn_yellow_green"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditNameKey;
