import React, { useRef,useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import {
  postUploadSingleFileForMailOfRTOApplicationManager,
  postSendAllDataForMailOfRTOApplicationManager,
  postRemoveFileForMailOfRTOApplicationManager,
  getAllDataForRTOMailAppManager,
  getAllUploadedFilesForRTOMailAppManager,
} from "../../../../../api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Widgets/Loader/Loader";
import VerificationModal from "../VerificationModal/VerificationModal";

const EditRTODetailsModal = ({
  show,
  handleClose,
  getIndividualEmploymentFunction,
  id,
  call,
  SelectedItem,
}) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [ApplicantName, setApplicantName] = useState("");
  const [ApplicantPRN, setApplicantPRN] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [inputs, setInputs] = useState([]);
  const [emailRTOError, setEmailRTOError] = useState("");
  const [qualificationLinks, setQualificationLinks] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [QualificationDocs, setQualificationDocs] = useState({});
  const [RTOEmail, setRTOEmail] = useState("");

  const [formValues, setFormValues] = useState({
    files: [],
    error: "",
    fileError: false,
  });

  const handleApplicantName = (event) => {
    setApplicantName(event.target.value);
  };

  const handleApplicantPRN = (event) => {
    setApplicantPRN(event.target.value);
  };

  const handleRTOEmail = (event) => {
    const emailValue = event.target.value;
    setRTOEmail(emailValue);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) {
      setEmailRTOError("Please enter a valid email address.");
    } else {
      setEmailRTOError("");
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue); // Update the email state

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email format
    if (!emailRegex.test(emailValue)) {
      setEmailError("Please enter a valid email address."); // Show error if invalid
    } else {
      setEmailError(""); // Clear error if valid
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setQualificationDocs({
      ...QualificationDocs,
      [id]: checked,
    });
  };

  const addMoreInput = () => {
    if (email.trim()) {
      setInputs([...inputs, email]); // Add the new email to the inputs array
      setEmail(""); // Clear the email input field
    } else {
      toast.error("Please enter a CC Email address."); // Error message if input is empty
    }
  };

  const handleInputChange = (index, value) => {
    setInputs(
      inputs.map(
        (input, i) => (i === index ? value : input) // Update the email at the specified index
      )
    );
  };

  const cancel = (index) => {
    setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index)); // Remove the input by index
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const invalidFiles = [];

    selectedFiles.forEach((file) => {
      if (file.type === "application/pdf") {
        validFiles.push({
          file: file,
          preview: URL.createObjectURL(file),
        });
      } else {
        invalidFiles.push(file);
      }
    });

    if (invalidFiles.length !== 0) {
      setFormValues({
        files: [], // Clear the files array on error
        error: "You have to choose only PDF files.",
        fileError: true,
      });

      toast.error("You have to choose only PDF files.");
    } else {
      setFormValues({
        files: validFiles,
        error: "",
        fileError: false,
      });
    }
  };

  const removeDeleteSingleFileFun = async (fileData) => {
    // e.preventDefault();

    const formData = new FormData();
    formData.append("pointer_id", id);
    formData.append("document_id", fileData?.document_id);

    try {
      setLoading(true);

      // Call the API to remove the file
      const response = await postRemoveFileForMailOfRTOApplicationManager(
        formData
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        toast.success(
          response?.data?.response?.success_msg || "File removed successfully."
        );

        setUploadedFiles((prevUploadedFiles) =>
          prevUploadedFiles.filter(
            (file) => file.document_id !== fileData.document_id
          )
        );

        getIndividualEmploymentFunction();
        getAllDataFun();
        getAllUploadedFilesFun();
      } else {
        console.error(
          "Error removing file:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Failed to remove file."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error removing file:", error);
    }

    // setFormValues({
    //   file: null,
    //   error: "",
    //   fileError: false,
    // });

    // const input = document.querySelector('input[type="file"]');
    // if (input) {
    //   input.value = "";
    // }
  };

  const uploadSingleFile = async (e) => {
    // e.preventDefault();

    if (formValues.files.length === 0) {
      toast.error("No file selected");
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", id);

    formValues.files.forEach((fileObj, index) => {
      formData.append("files[]", fileObj.file);
    });

    try {
      setLoading(true);

      const response = await postUploadSingleFileForMailOfRTOApplicationManager(
        formData
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        // handleClose();
        toast.success(
          response?.data?.response?.success_msg || "File Uploaded Successfully."
        );
        // getIndividualEmploymentFunction();
        // getAllDataFun();

        getAllUploadedFilesFun();

   // Reset the file input field
   if (fileInputRef.current) {
    fileInputRef.current.value = ""; // Clears the file input
  }

  // Clear the selected files from the state
  setFormValues({
    files: [],
    error: "",
    fileError: false,
  });


      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const sendAllData = async (e) => {
    // e.preventDefault();

    if (!ApplicantName || !ApplicantPRN || !RTOEmail) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Show confirmation modal before proceeding
    setShowVerificationModal(true);
  };

  const handleChangeStatus = async () => {
    // if (inputs.length === 0) {
    //   toast.error("Please add at least one CC email.");
    //   return;
    // }

    // if (Object.keys(QualificationDocs).length === 0) {
    //   toast.error("Please select at least one qualification document.");
    //   return;
    // }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", id);
    formData.append("applicant_name", ApplicantName);
    formData.append("applicant_prn", ApplicantPRN);
    formData.append("email", RTOEmail);

    // Append additional CC emails
    inputs.forEach((input, index) => {
      formData.append(`email_cc[]`, input?.email);
    });

    // Append selected qualification documents
    Object.keys(QualificationDocs).forEach((docId) => {
      if (QualificationDocs[docId]) {
        formData.append("document_ids[]", docId);
      }
    });

    // // Append the file if it exists
    // if (formValues.file) {
    //   formData.append("file", formValues.file.file);
    // }

    // // Log the formData entries to the console for verification
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      setLoading(true);

      const response = await postSendAllDataForMailOfRTOApplicationManager(
        formData
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Mail Sended Successfully."
        );
        getAllUploadedFilesFun();
        getIndividualEmploymentFunction(id);
        getAllDataFun();
        handleClose();
      } else {
        setLoading(false);
        console.error(
          "Error updating stage status data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating stage status data:", error);
    }
  };

  const getAllDataFun = async (e) => {
    // e.preventDefault();

    try {
      setLoading(true);

      const response = await getAllDataForRTOMailAppManager(id);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const responseData = response?.data?.response?.data;

        // Prefill form fields with incoming data
        setApplicantName(responseData?.applicant_name);
        setApplicantPRN(responseData?.prn);
        setRTOEmail(responseData?.email);
        setInputs(responseData?.email_cc || []);

        // If there are qualification documents, preselect them
        const docSelections = {};
        responseData?.documents_data.forEach((doc) => {
          if (responseData?.documents_data?.includes(doc?.document_id)) {
            docSelections[doc?.document_id] = true;
          }
        });

        setQualificationDocs(docSelections);

        setQualificationLinks(responseData?.documents_data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        // toast.error(
        //   response?.data?.response?.error_msg || "Error in fetching data"
        // );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // console.log("Badshahhhhhhhhhhh");
    // getAllDataFun();

    if (id && call) {
      getAllDataFun();
    }
  }, [id, call]);

  const getAllUploadedFilesFun = async () => {
    // e.preventDefault();

    try {
      setLoading(true);

      const response = await getAllUploadedFilesForRTOMailAppManager(id);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setUploadedFiles(response?.data?.response?.data || []);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (id && call) {
      getAllUploadedFilesFun();
    }
  }, [id, call]);

  return (
    <>
      {loading && <Loader />}

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            <h5>Edit RTO Details</h5>
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              position: "absolute",
              right: "1rem",
              top: "1rem",
            }}
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                PRN <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_id"
              type="text"
              className="form-control"
              id="meeting-id"
              value={ApplicantPRN}
              onChange={handleApplicantPRN}
              required
              disabled
            />
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Applicant Name <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_id"
              type="text"
              className="form-control"
              id="meeting-id"
              value={ApplicantName}
              onChange={handleApplicantName}
              required
              disabled
            />
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Email Id <span className="text-danger">*</span>
              </b>
            </label>
            <input
              name="meeting_email"
              type="email"
              className="form-control"
              id="meeting-email"
              value={RTOEmail}
              onChange={handleRTOEmail}
              required
            />
            {emailRTOError && <p className="text-danger">{emailRTOError}</p>}
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>
                CC Email Id <span className="text-danger">*</span>
              </b>
            </label>

            <div id="wrapper-50" className="input-group mb-2">
              <div className="col-10 p-0">
                <input
                  type="email"
                  name="email_cc[]"
                  id="email_cc"
                  value={email}
                  onChange={handleEmailChange}
                  list="email_master"
                  className="form-control"
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </div>

              <div className="col-2">
                <button
                  type="button"
                  onClick={addMoreInput}
                  className="btn btn_yellow_green add_button ml-3"
                >
                  <i className="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
            {inputs.map((input, index) => (
              <div key={index} id={`wrapper-${index}`} className="row mb-2">
                <div className="col-10">
                  <input
                    type="email"
                    name="email_cc[]"
                    id={`email_cc_${index}`} // Unique id for each input based on index
                    className="form-control"
                    value={input}
                    onChange={(e) => handleInputChange(index, e.target.value)} // Pass index instead of id
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => cancel(index)} // Use index instead of id for cancellation
                    className="btn btn-danger delete_button ml-2"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="zoom_details">
            <label htmlFor="meeting-id" className="form-label">
              <b style={{ fontSize: "12px" }}>Qualification Document:</b>
            </label>
            {qualificationLinks.map((doc) => (
              <Form.Check
                key={doc?.document_id}
                type="checkbox"
                id={doc?.document_id}
                label={
                  <a
                    className="a"
                    href={doc.document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {doc?.document_name}
                  </a>
                }
                checked={QualificationDocs[doc?.document_id] || false}
                onChange={handleCheckboxChange}
              />
            ))}
          </div>

          <Form.Group className="mb-0" controlId="extra_file">
            <b style={{ fontSize: "12px" }}>Files</b>
            <div className="row align-items-center">
              <div className="col-8 col-md-9 d-flex">
                <input
                  name="files"
                  type="file"
                  className="form-control"
                  required
                  onChange={handleFileChange}
                  accept=".pdf"
                  multiple
                  ref={fileInputRef}
                />
              </div>

              <div className="col-4 col-md-3 d-flex justify-content-end">
                <button
                  className="btn_modal"
                  onClick={uploadSingleFile}
                  disabled={
                    formValues.fileError || formValues.files.length === 0
                  }
                  style={{
                    padding: "7px 20px",
                    borderRadius: "6px",
                    border: "none",
                    cursor:
                      formValues.fileError || formValues.files.length === 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  Upload
                </button>
              </div>
              <div className="text-danger">Only: .pdf</div>
            </div>

            {formValues.fileError && (
              <p className="text-danger">{formValues.error}</p>
            )}

            <div>
              <>
                {uploadedFiles && (
                  <div className="row mt-2">
                    {uploadedFiles.map((file, index) => (
                      <div key={file?.document_id} className="col-12 mb-2">
                        <div className="row">
                          <div className="col-8 col-md-9">
                            <span style={{ fontWeight: "bold" }}>
                              {index + 1}.{" "}
                            </span>

                            <a
                              href={`${file?.document_path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                                color: "blue",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.textDecoration =
                                  "underline")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.textDecoration = "none")
                              }
                            >
                              {file?.document_name}
                            </a>
                          </div>
                          <div className="col-4 col-md-3 d-flex justify-content-end">
                            <Button
                              className="btn btn-danger"
                              onClick={() => removeDeleteSingleFileFun(file)}
                              disabled={false}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            </div>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn_yellow_green"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={handleClose}
          >
            Close
          </button>

          <button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            variant="primary"
            onClick={sendAllData}
          >
            Send Email
          </button>
        </Modal.Footer>
      </Modal>

      {/* Verification Modal */}
      <VerificationModal
        show={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        onYes={handleChangeStatus}
        Title="Confirm Send Email!"
        Message={
          <>
            Are you sure you want to send Verification Email to{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              {ApplicantName}
            </span>
            at{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              {RTOEmail}
            </span>{" "}
            address?
          </>
        }
      />
    </>
  );
};

export default EditRTODetailsModal;
