import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Heading from "../Heading/Heading";
import { LoginButton, AccountLink } from "../Common Modules/Common Modules";
import "./Login.css";
import { Card, Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CaptchaComponent from "../Captcha Component/Captcha Component";
import { encryptData } from "../CRYPTO/crypto";
import { LoginAPI } from "../../api";
import Loader from "../Widgets/Loader/Loader";

const Login = ({ onLogin, onLogout }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email, password]);

  const handleLogin = () => {
    setIsLoading(true);
    handleSubmit();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/admin/forgot_password");
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      // Validations
      if (!email || !validateEmail(email)) {
        setEmailError(true);
        toast.error("Please enter a valid email address.");
        setIsLoading(false);
        return;
      }
      if (!password) {
        toast.error("Please enter your password.");
        setIsLoading(false);
        return;
      }

      // Prepare form data
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      // Make API call
      const loginResponse = await LoginAPI(formData);

      const responseData = loginResponse?.data?.response;

      if (responseData && responseData?.response === true) {
        const token = responseData.token;
        const encryptedToken = encryptData(token);

        localStorage.removeItem("isAQATO_adminLoggedIn");
        localStorage.removeItem("encryptedTokenForAQATO_Admin");

        localStorage.setItem("isAQATO_adminLoggedIn", true);
        localStorage.setItem("encryptedTokenForAQATO_Admin", encryptedToken);
        localStorage.setItem("AQATO_SuperAdminName", responseData?.data?.fullname);
        localStorage.setItem("AQATO_SuperAdminID", responseData?.data?.admin_id);
        localStorage.setItem("AQATO_SuperAdminAccountType", responseData?.data?.admin_account_type);



        onLogin && onLogin();
        navigate("/admin/dashboard");

        toast.success(`🦘 Welcome, ${responseData?.data?.fullname}`);


      } else {
        if (responseData.error_msg === "Invalid Username Or Password") {
          toast.error("Invalid email or password. Please try again.");
        } else if (responseData.error_msg) {
          toast.error(responseData.error_msg);
        } else {
          toast.error("An error occurred during login. Please try again.");
        }
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setEmailError(!validateEmail(emailInput));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Heading text="Welcome to the Admin Login" />
      <div className="login-container-main">
        <Card className="login-card">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <i className="bi bi-shield-lock"></i> <b>Admin Login</b>
          </div>
          <Card.Body>
            <Form>
              <div className="login-card-input">
                <Form.Group controlId="formBasicEmail">
                  <div className="input-group" style={{ height: "40px" }}>
                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", height: "100%" }}
                    >
                      <i
                        className="bi bi-envelope-fill"
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bold",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></i>
                    </span>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={handleEmailChange}
                      style={{ height: "100%" }}
                    />
                  </div>

                  {emailError && (
                    <div className="error-message">
                      Invalid Email Address. Please enter a valid email. *{" "}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <div className="input-group" style={{ height: "40px" }}>
                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", height: "100%" }}
                    >
                      <i
                        className="bi bi-key-fill"
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bold",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></i>
                    </span>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ height: "100%" }}
                    />

                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        style={{ fontSize: "1rem", color: "black" }}
                      />
                    </span>
                  </div>
                </Form.Group>

                <div className="forgot-password-container">
                  <a
                    className="forgot-password-link"
                    onClick={handleForgotPassword}
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              {/* <CaptchaComponent
                onCaptchaVerification={handleCaptchaVerification}
              /> */}
            </Form>
          </Card.Body>

          {/* {isLoading && (
            <div className="text-center mt-3">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )} */}

{isLoading && (
 <Loader />
      )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoginButton
              onClick={handleLogin}
              buttonText={"Login"}
              icon={true}
            />
          </div>

          {/* <div
            style={{
              textAlign: "center",
              // marginBottom: "15px",
              // marginTop: "15px",
            }}
          >
            <div className="centered-content">
              <AccountLink
                linkText="Create an account"
                linkUrl="/admin/create_new_account"
                promptText="Don't have an account?"
                onClick={() => navigate("/admin/create_new_account")}
              />
            </div>
          </div> */}
        </Card>
      </div>
    </>
  );
};

export default Login;
