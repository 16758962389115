import React, { useState, useEffect } from "react";
import { Container, Spinner, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./View_Update.css";
import SidebarMenu from "../../Widgets/SideBar Menu/SidebarMenu";

import Agent_Applicant_data from "../Common Module/Agent_Applicant_data";
import Applicant_Data from "./Applicant_Data";
import SubmittedDocs from "../Submitted Docs/Submitted Docs";
import NotesChat from "../NotesChat/NotesChat";

import Loader from "../../Widgets/Loader/Loader";
import {
  getAllViewUpdateOfAppManager,
  getAllSubmittedApplicationsOfAppManager,
  getAllViewUpdateNotesCountOfAppManager,
} from "../../../api";
import { useLocation } from "react-router-dom";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage_3_R from "./Stage_3_R";
import Stage4 from "./Stage4";

const ViewUpdate = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  // const { pointerID } = location.state || {};

  const [pointerID, setPointerID] = useState(null);

  useEffect(() => {
    // Retrieve pointerID from query parameters
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('pointerID');
    
    console.log("Retrieved pointerID:", id); 
    getAllDataOfViewUpdateFunction(id);
    handleCallSubmittedDocsApi(id);
    setPointerID(id);
  }, [location.search]);

  useEffect(() => {
    if (pointerID) {
      getAllDataOfViewUpdateFunction(pointerID);
      getAllDataOfViewUpdateCountFunction(pointerID);
      handleCallSubmittedDocsApi(pointerID);

    }
  }, [pointerID]);



  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [ViewUpdateTabActive, setViewUpdateTabActive] = useState(true);
  const [SubmittedDocsTabActive, setSubmittedDocsTabActive] = useState(false);

  const [NotesTabActive, setNotesTabActive] = useState(false);

  const [AllDataFromApi, setAllDataFromApi] = useState({});

  const [AllDataFromApiForSubmitted, setAllDataFromApiForSubmitted] = useState(
    {}
  );

  const [currentStageKey, setCurrentStageKey] = useState("");
  const [trueStages, setTrueStages] = useState({});

  // Set aria-selected attributes based on tab state
  useEffect(() => {
    const tabs = document.querySelectorAll(".nav_top_bar");

    tabs.forEach((tab) => {
      const tabId = tab.getAttribute("id");

      if (tabId === "tabs_view_edit") {
        tab.setAttribute(
          "aria-selected",
          ViewUpdateTabActive ? "true" : "false"
        );
      } else if (tabId === "tabs_documents") {
        tab.setAttribute(
          "aria-selected",
          SubmittedDocsTabActive ? "true" : "false"
        );
      } else if (tabId === "notes_tab") {
        tab.setAttribute("aria-selected", NotesTabActive ? "true" : "false");
      }
    });
  }, [ViewUpdateTabActive, SubmittedDocsTabActive, NotesTabActive]);

  const getAllDataOfViewUpdateFunction = async (pointerID) => {
    try {
      setLoading(true);

      const response = await getAllViewUpdateOfAppManager(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setAllDataFromApi(response?.data?.response?.data);
        const applicantName =
          response?.data?.response?.data?.profile_data?.applicant_name;

        localStorage.setItem("currentViewingApplicantName", applicantName);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataOfViewUpdateFunction(pointerID);
  }, []);

  useEffect(() => {
    const currentStage = AllDataFromApi?.current_stage;

    if (currentStage) {
      let stageKey = currentStage.replace("stage_", "");

      // Convert "3_R" to "5"
      if (stageKey === "3_R") {
        stageKey = "5";
      }

      setCurrentStageKey(stageKey);
    } else {
      setCurrentStageKey("");
    }

    const overStageStatus = AllDataFromApi?.over_stage_status || {};
    setTrueStages(overStageStatus);
  }, [AllDataFromApi]);

  const handleCallSubmittedDocsApi = async (pointerID) => {
    try {
      setLoading(true);

      const response = await getAllSubmittedApplicationsOfAppManager(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setAllDataFromApiForSubmitted(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const [NotesTabCount, setNotesTabCount] = useState("");

  const getAllDataOfViewUpdateCountFunction = async (pointerID) => {
    try {
      // setLoading(true);

      const response = await getAllViewUpdateNotesCountOfAppManager(pointerID);

      // setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setNotesTabCount(response?.data?.response?.data?.count);
      } else {
        // setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataOfViewUpdateCountFunction(pointerID);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllDataOfViewUpdateCountFunction(pointerID);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [pointerID]);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="mt-1">
          {loading && <Loader />}

          <div className="main-wrapper">
            <div className="sub-wrapper-sidebar">
              <SidebarMenu />
            </div>
            <div className="sub-wrapper-main">
              <div
                className="main-heading-title"
                style={{ marginBottom: "10px" }}
              >
                <h3 className="heading-admin-title text-color-green">
                  View / Update
                </h3>
              </div>

              <Agent_Applicant_data
                API_Data={AllDataFromApi?.topbar_data}
                pointerID={pointerID}
                getAllDataOfViewUpdateFunction={getAllDataOfViewUpdateFunction}
              />

              <section
                className="main-body-area shadow mb-5"
                style={{ marginTop: "20px" }}
              >
                <div className="card card-primary card-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-pills nav-fill">
                      <li
                        className="p-1 nav-item"
                        style={{ marginTop: "0px", cursor: "pointer" }}
                      >
                        <a
                          className={`text-center tab shadow-sm text-color nav_top_bar ${
                            ViewUpdateTabActive ? "bg-yellow text-green" : ""
                          }`}
                          id="tabs_view_edit"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_view_edit"
                          aria-selected={ViewUpdateTabActive ? "true" : "false"}
                          onClick={() => {
                            setViewUpdateTabActive(true);
                            setSubmittedDocsTabActive(false);
                            setNotesTabActive(false);
                            getAllDataOfViewUpdateFunction(pointerID);
                          }}
                        >
                          View / Update
                        </a>
                      </li>
                      <li
                        className="p-1 nav-item custom_fix_top_margin"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={`text-center tab shadow-sm text-color nav_top_bar ${
                            SubmittedDocsTabActive ? "bg-yellow text-green" : ""
                          }`}
                          id="tabs_documents"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_documents"
                          aria-selected={
                            SubmittedDocsTabActive ? "true" : "false"
                          }
                          onClick={() => {
                            setSubmittedDocsTabActive(true);
                            setViewUpdateTabActive(false);
                            setNotesTabActive(false);
                            handleCallSubmittedDocsApi(pointerID);
                          }}
                        >
                          Submitted Documents
                        </a>
                      </li>

                      {accountType !== "head_office" && (
                        <li
                          className="p-1 nav-item custom_fix_top_margin"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`text-center tab shadow-sm text-color nav_top_bar ${
                              NotesTabActive ? "bg-yellow text-green" : ""
                            }`}
                            id="notes_tab"
                            onClick={() => {
                              setNotesTabActive(true);
                              setViewUpdateTabActive(false);
                              setSubmittedDocsTabActive(false);
                            }}
                            role="tab"
                            aria-controls="notes_tab"
                            aria-selected={NotesTabActive ? "true" : "false"}
                          >
                            Notes{" "}
                            <span className="fw-bold  btn btn-secondary badge bg-yellow text-green">
                              {NotesTabCount}
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                {ViewUpdateTabActive && (
                  <div className="sub_section_stages my-2">
                    {currentStageKey !== "" && (
                      <Accordion
                        defaultActiveKey={["0", currentStageKey]}
                        flush
                        alwaysOpen
                      >
                        <Applicant_Data
                          StageHeading={"Applicant's Details"}
                          API_Profile_Data={AllDataFromApi?.profile_data}
                          Aqato_Team_Members={AllDataFromApi?.aqato_teams}
                          ATTC_Team_Members={AllDataFromApi?.attc_teams}


                          // Programs={AllDataFromApi?.programs}
                          // Pathways={AllDataFromApi?.pathways}


                          pointerID={pointerID}
                          getAllDataOfViewUpdateFunction={
                            getAllDataOfViewUpdateFunction
                          }
                        />

                        {trueStages?.stage_1 === true && (
                          <Stage1
                            ApiPageData={AllDataFromApi?.page_data?.stage_1}
                            pointerID={pointerID}
                            getAllDataOfViewUpdateFunction={
                              getAllDataOfViewUpdateFunction
                            }
                          />
                        )}
                        {trueStages?.stage_2 === true && (
                          <Stage2
                            ApiPageData={AllDataFromApi?.page_data?.stage_2}
                            pointerID={pointerID}
                            getAllDataOfViewUpdateFunction={
                              getAllDataOfViewUpdateFunction
                            }
                          />
                        )}
                        {trueStages?.stage_3 === true && (
                          <Stage3
                            ApiPageData={AllDataFromApi?.page_data?.stage_3}
                            pointerID={pointerID}
                            getAllDataOfViewUpdateFunction={
                              getAllDataOfViewUpdateFunction
                            }
                          />
                        )}
                        {trueStages?.Stage_3_R === true && (
                          <Stage_3_R
                            ApiPageData={AllDataFromApi?.page_data?.stage_3_R}
                            pointerID={pointerID}
                            getAllDataOfViewUpdateFunction={
                              getAllDataOfViewUpdateFunction
                            }
                          />
                        )}
                        {trueStages?.stage_4 === true && (
                          <Stage4
                            ApiPageData={AllDataFromApi?.page_data?.stage_4}
                            pointerID={pointerID}
                            getAllDataOfViewUpdateFunction={
                              getAllDataOfViewUpdateFunction
                            }
                          />
                        )}
                      </Accordion>
                    )}
                  </div>
                )}

                {SubmittedDocsTabActive && (
                  <div className="sub_section_stages my-2">
                    <SubmittedDocs
                      AllDataFromApiForSubmitted={AllDataFromApiForSubmitted}
                      pointerID={pointerID}

                    />
                  </div>
                )}
                {accountType !== "head_office" && NotesTabActive && (
                  <div className="sub_section_stages my-2">
                    <NotesChat
                      pointerID={pointerID}
                      NotesTabActive={NotesTabActive}
                      NotesTabCount={NotesTabCount}
           
                    />
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUpdate;
