import React, { useState, useRef, useEffect } from "react";
import "../../MailTemplate.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation } from "react-router-dom";
import "./EditMailNameKey.css";
import SidebarMenu from "../../../Widgets/SideBar Menu/SidebarMenu";

const EditMailNameKey = () => {
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef(null);

  const location = useLocation();
 console.log(location?.state)
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const insertTextAtCursor = (text) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true); // Get the current cursor position
    editor.focus(); // Focus the editor
    editor.insertText(range.index, text); // Insert text at the current cursor position
    editor.setSelection(range.index + text.length); // Move cursor to the end of inserted text
  };

  const keywords = [
    "%personal_details_first_name%",
    "%personal_details_middle_name%",
    "%personal_details_surname_name%",
    "%occupation_occupation%",
    "%occupation_program%",
    "%pathway%",
    "%email_first_name%",
    "%email_surname_name%",
    "%unique_id%",
    "%email_signature%",
    "%occupation_reason_for_decline%",
    "%reason_for_decline%",
    "%day_date%",
    "%get_slot_time%",
    "%skill_title%",
    "%add_employment_referee_name%",
    "%add_employment_company_name%",
    "%s2_reason_for_decline%",
    "%s3_interview_day_and_date%",
    "%s3_interview_time%",
    "%s3_interview_venue%",
    "%s3_interview_address%",
    "%Expiry_Date%",
  ];

  EditMailNameKey.modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  EditMailNameKey.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    const handleClick = () => {
      const range = editor.getSelection();
      if (!range) {
        editor.focus();
        editor.setSelection(editor.getLength(), 0);
      }
    };

    const quillEditorContainer = quillRef.current.editor.container;
    quillEditorContainer.addEventListener("click", handleClick);

    return () => {
      quillEditorContainer.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div>
      <hr className="mb-2 thick-hr" />
      <div className="main-wrapper">
        <div className="sub-wrapper-sidebar">
          <SidebarMenu />
        </div>
        <div className="sub-wrapper-main mb-5 addmail_wrapper">
          <div className="main-heading-title">
            <h3 className="heading-admin-title text-color-green">
              Edit NameKey Mail Template
            </h3>
          </div>
          <div className="card shadow">
            <form id="test_f" className="p-3 col-12 col-md-12" onSubmit="">
              <label for="name" className="col-3 label_mail p-0">email:</label>
              <input type="email" name="email" className="col-9 col-md-3"/>{"  "}
              <button type="submit" className="test_mail_btn"  >
                {" "}
                Test Email Send
              </button>
            </form>
            <div className="card-body">
              <form action="" id="edit_form">
                <div className="row g-0 card-header">
                  <div className="col-2 col-button1">
                    <a href="#" className="btn btn_green_yellow">
                      Save Template
                    </a>
                  </div>
                  <div className="col-10 col-button2">
                    <Link
                      to="/admin/mail_template/name_keywords"
                      className="btn btn_yellow_green float-end"
                    >
                      <i className="bi bi-arrow-left "></i>
                      Back
                    </Link>
                  </div>
                </div>
                <div className="row card-body col_addmail">
                  <div className="col-8 column_first">
                    <div className="row my-2">
                      <div className="col-12">
                        <label>Name</label>
                       

                        <select
                          name="agent_name"
                          className="form-select"
                          id="agent_id"
                        >
                          <option value="">--Select Name--</option>
                          <option value="Stage 1, status reinstate admin mail">
                            Stage 1, status reinstate admin mail
                          </option>
                          <option value="s1_reinstate_applicant">
                            s1_reinstate_applicant
                          </option>
                          <option value="s1_Additional_info_received_send_to_applicant">
                            s1_Additional_info_received_send_to_applicant
                          </option>
                          <option value="s2_additional_information_received">
                            s2_additional_information_received
                          </option>
                          <option value="s2_reinstated_submission_agent">
                            s2_reinstated_submission_agent
                          </option>
                          <option value="Stage_4_submitted_agent">
                            Stage_4_submitted_agent
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row g-0 my-2">
                      <div className="col-12">
                        <label htmlFor="">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          className="form-control subject"
                          id="subjectField"
                        />
                      </div>
                    </div>
                    <div className="row g-0 my-2">
                      <div className="col-12">
                        <label htmlFor="">Body</label>
                      </div>
                      <div className="quill">
                        <ReactQuill
                          ref={quillRef}
                          value={editorContent}
                          onChange={handleEditorChange}
                          modules={EditMailNameKey.modules}
                          formats={EditMailNameKey.formats}
                        />
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="col-4 text-center column_second">
                    <div className="keyword-wrapper">
                      {keywords.map((keyword, index) => (
                        <div
                          key={index}
                          className="keyword border my-2 rounded"
                        >
                          <input
                            type="button"
                            value={keyword}
                            className="btn input_btns btn_yg"
                            onClick={() => insertTextAtCursor(keyword)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMailNameKey;
