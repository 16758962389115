import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Widgets/Loader/Loader";
import { postSendNodataForMailOfVerificationQualificationApplicationManager } from "../../../../../api";

const QualificationVerificationModal = ({
  show,
  onHide,
  stage,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
}) => {
  const [loading, setLoading] = useState(false);

  const sendMail = async () => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);

    try {
      setLoading(true);

      const response =
        await postSendNodataForMailOfVerificationQualificationApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        toast.success(
          response.data.response.success_msg || "Mail sended successfully."
        );
        getAllDataOfSubmittedApplicationsFunction(pointerID);
        onHide();
      } else {
        toast.error(
          response.data.response.error_msg || "Error during sending mail."
        );
        onHide();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during the process:", error);
      toast.error("Error during sending mail.");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={show}
        onHide={onHide}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            Confirm!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to send{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              Qualification Verification Mail
            </span>{" "}
            ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-danger ms-1"
            variant="primary"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onHide}
          >
            No
          </Button>
          <Button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={sendMail}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QualificationVerificationModal;
