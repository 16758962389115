import React, { useState, useEffect } from "react";
import Reminder_Date_Chooser from "./Reminder_Date_Chooser";

// Helper function to format Date object to "DD/MM/YYYY"
const formatDate = (dateString) => {
  if (!dateString || dateString === "dd/mm/yyyy") {
    return "dd/mm/yyyy";
  }

  let date;

  // Handle date formats explicitly
  if (dateString.includes('/')) {
    const [day, month, year] = dateString.split('/').map(Number);
    date = new Date(year, month - 1, day);
  } else if (dateString.includes('-')) {
    date = new Date(dateString);
  } else {
    date = new Date(dateString);
  }

  // Check for invalid date
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const Reminder_Date_Wrapper = ({ API_Data, pointerID, getAllDataOfViewUpdateFunction }) => {
  const [reminderRender, setReminderRender] = useState(false);
  const [reminderDate, setReminderDate] = useState("dd/mm/yyyy");

  useEffect(() => {
    if (API_Data?.reminder_date) {
      setReminderDate(API_Data.reminder_date || "dd/mm/yyyy"); // Fallback to placeholder if no reminder_date
    }
  }, [API_Data]);

  const handleToggleReminder = () => {
    setReminderRender(prev => !prev);
  };

  const handleReminderDateChange = (newDate) => {
    if (newDate === "") {
      setReminderDate("dd/mm/yyyy"); // Placeholder when cleared
    } else {
      setReminderDate(newDate);
    }
  };

  return (
    <div className="float-end reminderdate_wrapper">
      <div className="label_box" style={{ cursor: "default" }}>
        <b>Reminder Date :</b>&nbsp;
      </div>
      {reminderRender ? (
        <Reminder_Date_Chooser
          reminderDate={reminderDate === "dd/mm/yyyy" ? "" : reminderDate}
          setReminderDate={handleReminderDateChange}
          setHideShowReminder={handleToggleReminder}
          pointerID={pointerID}
          getAllDataOfViewUpdateFunction={getAllDataOfViewUpdateFunction}
        />
      ) : (
        <a
          className="reminder_date_chooser"
          onClick={handleToggleReminder}
          style={{ fontWeight: 'bold', color: 'red' }}
        >
          {formatDate(reminderDate)}
        </a>
      )}
    </div>
  );
};

export default Reminder_Date_Wrapper;
