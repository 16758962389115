import React, { useState } from "react";
import ReferralFees from "./Accounting/Referral Fees";
import OffshoresFees from "./Accounting/Offshores Fees";
const Accounting = () => {
  const [isReferral, setIsReferral] = useState(true);

  const handleReferral = () => {
    setIsReferral(true);
  };
  const handleOffshores = () => {
    setIsReferral(false);
  };

  return (
    <>
      <div className="">
        <div className="col-12 accup">
          <button
            onClick={handleReferral}
            className={`btn ${
              isReferral ? "btn_green_yellow" : "btn1_yellow_green"
            } mr-2`}
          >
            Referral Fees
          </button>

          <button
            onClick={handleOffshores}
            class={`btn  ${
              isReferral ? "btn1_yellow_green" : "btn_green_yellow"
            }  `}
          >
            Offshores Fees
          </button>
        </div>
      </div>

      <hr />
      {isReferral ? <ReferralFees /> : <OffshoresFees />}
    </>
  );
};

export default Accounting;
