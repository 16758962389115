import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import AQATO_header_image from "./AQATO_header_image.jpg";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";

const Header = ({ onLogout }) => {
  const [adminName, setAdminName] = useState(
    localStorage.getItem("AQATO_SuperAdminName")
  );
  const navigate = useNavigate();
  const location = useLocation();
  const toggleRef = useRef(null);

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  useEffect(() => {
    setAdminName(localStorage.getItem("AQATO_SuperAdminName"));
  }, [localStorage.getItem("AQATO_SuperAdminName")]);

  useEffect(() => {
    setAdminName(localStorage.getItem("AQATO_SuperAdminName"));
  }, []);

  useEffect(() => {
    if (toggleRef.current) {
      const toggleWidth = toggleRef.current.offsetWidth;
      document.documentElement.style.setProperty(
        "--dropdown-width",
        `${toggleWidth}px`
      );
    }
  }, [adminName]);

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.removeItem("isAQATO_adminLoggedIn");
    localStorage.removeItem("encryptedTokenForAQATO_Admin");
    localStorage.removeItem("AQATO_SuperAdminName");
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const showDropdown = ![
    "/",
    "/admin/forgot_password",
    "/admin/reset_password",
  ].includes(location.pathname);

  return (
    <div className="header-container">
      <div className={`logo-container ${!showDropdown ? "centered" : ""}`}>
        <img
          src={AQATO_header_image}
          alt="AQATO_image"
          className="header-image"
        />
      </div>

      {showDropdown && adminName !== "" && (
        <div className="dropdown-container">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="user-dropdown"
              className={`${
                adminName
                  ? "border-0 text-dark d-flex align-items-center dropdown-toggle"
                  : ""
              }`}
              ref={toggleRef}
            >
              <BsFillPersonFill className="me-2" />
              <span>Welcome, </span>&nbsp;
              <b>{adminName}</b>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>
                <i className="bi bi-power"></i> Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Exit"
          message="Are you sure you want to Log Out ?"
        />
      )}
    </div>
  );
};

export default Header;
