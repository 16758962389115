import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./NameKeywords.css";
import { addNameKeywordTemplate } from "../../../api"; // Make sure this function supports form-data

const Add = ({ showModal, handleClose }) => {
  const [formValues, setFormValues] = useState({
    name_keyword: "",
    name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Values:", formValues);
    
    // Create a FormData object
    const formData = new FormData();
    formData.append("name_keyword", formValues.name_keyword);
    formData.append("name", formValues.name);
    
    try {
      const response = await addNameKeywordTemplate(formData); // Ensure this function supports sending form-data
      console.log("Response", response);
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        console.log("Data added successfully: ", response.data.response.data);
      } else {
        console.error(
          "Error updating location data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      console.log("Error ", error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="model_mannual"
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Name Keywords</h4>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} className="add_data">
              <div className="row">
                <div className="col-6">
                  <label>
                    Keyword
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name_keyword"
                    onChange={handleInputChange}
                    className="form-control md-2"
                    required
                  />
                </div>
                <div className="col-6">
                  <label>
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    className="form-control md-2"
                    required
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="text-end">
                  <button type="submit" className="btn btn_green_yellow mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn_yellow_green"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add;
